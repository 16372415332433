import { ECondition, setVehicleCondition } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialVehicleCondition(condition: IParameter): void {
  if (condition === null) {
    return;
  }
  // Check condition is one of the enums
  const conditionEnum =
    ECondition[condition.toUpperCase() as keyof typeof ECondition];
  if (conditionEnum === undefined) {
    return;
  }

  const { condition: stateCondition } =
    store.getState().borrowerReducer.vehicle;

  if (stateCondition !== conditionEnum) {
    store.dispatch(setVehicleCondition(conditionEnum));
  }
}
