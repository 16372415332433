import { Input } from '@/components/Input';
import {
  selectEmploymentMonths,
  setEmploymentMonths,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'EmploymentMonths';

export function EmploymentMonths({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectEmploymentMonths);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    if (typeof value === 'boolean') return;
    if (value === currentState?.toString()) return;
    let intValue: number | undefined = parseInt(value);
    if (intValue >= 12) intValue = 12;
    if (isNaN(intValue)) intValue = undefined;
    dispatch(setEmploymentMonths(intValue));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Employment Months'
        placeholder='0'
        showErrors={showErrors && !currentState}
        onChange={handleChange}
        id={`${baseId}-input-employmentMonths`}
        value={currentState?.toLocaleString() ?? ''}
      />
    </div>
  );
}
