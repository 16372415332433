import { Input } from '@/components/Input';
import { selectEmploymentYears, setEmploymentYears } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'EmploymentYears';

export function EmploymentYears({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectEmploymentYears);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    if (typeof value === 'boolean') return;
    if (value === currentState?.toString()) return;
    let intValue: number | undefined = parseInt(value);
    if (intValue >= 99) intValue = 99;
    if (isNaN(intValue)) intValue = undefined;
    dispatch(setEmploymentYears(intValue));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Employment Years'
        placeholder='0'
        showErrors={showErrors && !currentState}
        onChange={handleChange}
        id={`${baseId}-input-employmentYears`}
        value={currentState?.toLocaleString() ?? ''}
      />
    </div>
  );
}
