export interface IApplication {
  id?: string | undefined;
  isDeclined?: boolean | undefined;
  declineReason?: string | undefined;
  isServicing?: boolean | undefined;
  isPreApproved?: boolean | undefined;
  isSubmitted?: boolean | undefined;
  skynetOpportunityId?: string | undefined;
  skynetPartnerId?: string | undefined;
  progressPercent?: number | undefined;
}

export const initialApplication: IApplication = {};
