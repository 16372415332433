import { ELoanUsage } from '@/context/borrower';

import { DEFAULT_PARTNER } from './defaultPartner';
import { EPartners, IPartnerParams } from './partnerTypes';

export const EASYCARS_PARTNER: IPartnerParams = {
  ...DEFAULT_PARTNER,
  id: EPartners.EASYCARS,
  loanUsage: ELoanUsage.PERSONAL,
  dealerName: 'Easycars',
};
