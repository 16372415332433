import { useState } from 'react';

import { Button, EButtonType } from '@/components/Button';
import {
  IVehicle,
  selectIsVehicleRegistrationKnown,
  selectVehicleRegistrationNumber,
  selectVehicleRegistrationState,
  setVehicleDescription,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getVehicleByPlate } from '@/services/AssetService';
import { Kilometers } from '@/UserInput/Kilometers';
import { RegistrationNumber } from '@/UserInput/RegistrationNumber';
import { RegistrationState } from '@/UserInput/RegistrationState';

// eslint-disable-next-line no-restricted-imports
import { DescriptionBox } from '../DisplayVehicleResults/DescriptionBox';
export const testId = 'VehicleDetailsRegoKnown';

interface IVehicleDetailsRegoKnownProps {
  showErrors: boolean;
}

export function VehicleDetailsRegoKnown({
  showErrors,
}: IVehicleDetailsRegoKnownProps): JSX.Element {
  const baseId = 'screen-VehicleDetailsRegoUnknown';
  const [confirmVehicle, setConfirmVehicle] = useState<null | IVehicle>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();

  // ***** CONSTANTS *****
  const handleOnClickFindVehicle = async (): Promise<void> => {
    setLoading(true);
    await getVehicleByPlate(vehicleRegistrationNumber, vehicleState)
      .then((response) => {
        setLoading(false);
        setError(false);
        setConfirmVehicle(response);
      })
      .catch(() => {
        setLoading(false);
        setConfirmVehicle(null);
        setError(true);
      });
  };

  const isRegistrationKnown = useAppSelector(selectIsVehicleRegistrationKnown);
  const vehicleRegistrationNumber = useAppSelector(
    selectVehicleRegistrationNumber
  );
  const vehicleState = useAppSelector(selectVehicleRegistrationState);

  const requiredFieldsPresentInState =
    isRegistrationKnown && vehicleRegistrationNumber && vehicleState
      ? true
      : false;

  const handleOnClick = (description: string | undefined): void => {
    if (description === undefined) return;
    dispatch(setVehicleDescription(description));
  };

  return (
    <div>
      <RegistrationNumber showErrors={showErrors} baseId={baseId} />
      <Kilometers showErrors={showErrors} baseId={baseId} />
      <RegistrationState showErrors={showErrors} baseId={baseId} />
      <div className='py-4 px-20'>
        {requiredFieldsPresentInState === true ? (
          <Button onClick={handleOnClickFindVehicle}>Find Vehicle</Button>
        ) : (
          <Button
            onClick={handleOnClickFindVehicle}
            type={EButtonType.DISABLED}
          >
            Find Vehicle
          </Button>
        )}
      </div>
      <div>
        {loading && <div className='text-center'>.....Loading</div>}

        {!loading && confirmVehicle && (
          <DescriptionBox
            key={undefined}
            content={confirmVehicle.description}
            onClick={(): void => handleOnClick(confirmVehicle.description)}
          />
        )}
        {!loading && error && (
          <div className='text-center p-3'>
            Sorry, we were unable to find that plate. Please check that the
            Registration Number and State are correct or select the `Not Yet`
            option.
          </div>
        )}
      </div>
    </div>
  );
}
