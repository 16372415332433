import PropTypes from 'prop-types';
export const testId = 'DescriptionBox';

interface Props {
  key: number | null | undefined;
  content: string | null | undefined;
  onClick?: () => void;
  selected?: boolean;
}

const DescriptionBox: React.FC<Props> = ({
  content,
  onClick,
  selected,
}): JSX.Element => {
  const className = selected
    ? 'border border-primary text-center p-2 m-2 cursor-pointer bg-primary text-white'
    : 'border border-primary text-center p-2 m-2 cursor-pointer';

  return (
    <div className={className} onClick={onClick}>
      {content}
    </div>
  );
};

DescriptionBox.propTypes = {
  content: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool.isRequired,
};

export  {DescriptionBox};
