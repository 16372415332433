// eslint-disable-next-line simple-import-sort/imports
import moment from 'moment';
import { ChangeEvent } from 'react';

import { trimClassname } from '@/utils/styleHelper';

export interface IDateInput {
  title: string;
  placeholder: string;
  onChange: (value: Date) => void;
  showErrors: boolean;
  id?: string;
  disabled?: boolean;
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: string;
}

export function DateInput({
  title,
  placeholder,
  id,
  value,
  showErrors,
  disabled = false,
  onChange,
  minDate,
  maxDate,
  dateFormat = 'YYYY-MM-DD',
}: IDateInput): JSX.Element {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    console.log('handleChange()');
    const { value } = event.target;
    console.log('value', value);
    const date = new Date(value);

    console.log('dateInput sending date: ', date);

    onChange(date);
  };

  function getDisplayValue(): string {
    if (!value) return '';
    return moment(value).format(dateFormat);
  }

  return (
    <div data-testid='input' className='py-2 mx-auto w-full max-w-md'>
      <h1 className='fieldHeading'>{title}</h1>
      <input
        className={trimClassname(
          `appearance-none border rounded-lg mx-auto w-full max-w-md p-2 text-textPrimary leading-tight inputButtonFocus placeholderStyle text-center ${
            showErrors ? errorClass : ''
          } ${disabled ? 'hover:none cursor-disabled' : 'hover:shadow '}`
        )}
        id={id}
        type='date'
        data-testid='inputField'
        placeholder={placeholder}
        value={getDisplayValue()}
        onChange={handleChange}
        disabled={disabled}
        min={minDate ? moment(minDate).format(dateFormat) : undefined}
        max={maxDate ? moment(maxDate).format(dateFormat) : undefined}
        pattern={dateFormat}
      />
    </div>
  );
}
export const errorClass = 'error';
