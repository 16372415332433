import { setSellerEmail } from '@/context/borrower';
import { store } from '@/context/store';

export function setInitialSellerEmail(
  parameterEmail: string | null,
  partnerEmail: string | null
): void {
  const sellerEmail = store.getState().borrowerReducer.seller.email;

  const emailToSave = parameterEmail ?? partnerEmail;

  if (emailToSave && sellerEmail !== emailToSave) {
    store.dispatch(setSellerEmail(sellerEmail));
  }
}
