import { useState } from 'react';

import { Button, EButtonType } from '@/components/Button';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import {
  selectAbn,
  selectIndustryType,
  selectIsVehicleKnown,
  selectNumberOfDirectors,
} from '@/context/borrower';
import {
  EDisplays,
  selectHideSellerScreen,
  selectHideVehicleScreen,
  setDisplay,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { Abn } from '@/UserInput/Abn';
import { AbnActiveFrom } from '@/UserInput/AbnActiveFrom';
import { Acn } from '@/UserInput/Acn';
import { BusinessIndustry } from '@/UserInput/BusinessIndustry';
import { BusinessLookup } from '@/UserInput/BusinessLookup';
import { GstFrom } from '@/UserInput/GstFrom';
import { NumberOfDirectors } from '@/UserInput/NumberOfDirectors';

export const testId = 'Business';

export function Business(): JSX.Element {
  // ***** CONSTANTS *****
  const baseId = 'screen-business';
  const [showErrors, setShowErrors] = useState<boolean>(false);

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const hideSeller = useAppSelector(selectHideSellerScreen);
  const hideVehicle = useAppSelector(selectHideVehicleScreen);
  const industryType = useAppSelector(selectIndustryType);
  const businessAbn = useAppSelector(selectAbn);
  const numberOfDirectors = useAppSelector(selectNumberOfDirectors);
  const isVehicleKnown = useAppSelector(selectIsVehicleKnown);

  // ***** Event Handlers *****
  function handleBackClick(): void {
    const backScreen = getBackScreen();
    dispatch(setDisplay(backScreen));
  }

  function handleNextClick(): void {
    setShowErrors(true);
    if (!isComplete()) return;
    const nextScreen = getNextScreen();
    dispatch(setDisplay(nextScreen));
  }

  // ***** Helpers *****
  function isComplete(): boolean {
    if (!industryType) return false;
    if (!businessAbn) return false;
    if (!numberOfDirectors) return false;

    return true;
  }

  function getNextScreen(): EDisplays {
    return EDisplays.BASIC;
  }

  function getBackScreen(): EDisplays {
    if (!isVehicleKnown) {
      return EDisplays.LOAN;
    }
    if (!hideSeller) {
      return EDisplays.SELLER;
    }
    if (!hideVehicle) {
      return EDisplays.MANUAL_VEHICLE;
    }
    return EDisplays.LOAN;
  }

  return (
    <div data-testid={testId}>
      <Header />
      <ProgressBar />
      <div className='flex'>
        <Button
          onClick={handleBackClick}
          type={EButtonType.SECONDARY_OUTLINE}
          square
        >
          Back
        </Button>
        <Button onClick={handleNextClick} square>
          Next
        </Button>
      </div>
      <div className='px-4'>
        <DisplayHeading
          title='Business Details'
          infoText='We need some details about your business'
        />
        <BusinessIndustry baseId={baseId} showErrors={showErrors} />
        <BusinessLookup baseId={baseId} showErrors={showErrors} />
        <div className='flex space-x-2 flex-center'>
          <Abn baseId={baseId} showErrors={showErrors} />
          <Acn baseId={baseId} showErrors={showErrors} />
        </div>
        <div className='flex space-x-2 flex-center'>
          <AbnActiveFrom baseId={baseId} showErrors={showErrors} />
          <GstFrom baseId={baseId} showErrors={showErrors} />
        </div>
        {/* Previous Comparable Credit */}
        <div className='flex space-x-2 flex-center'>
          <NumberOfDirectors baseId={baseId} showErrors={showErrors} />
          <div className='w-full' />
        </div>
      </div>
    </div>
  );
}
