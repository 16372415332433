import { getUrl } from '@/apiCalls/fetch';
import { setVehicle } from '@/context/borrower';
import { store } from '@/context/store';

import { IGetNvicReturn } from './lookupVinTypes';

export async function lookupVin(): Promise<void> {
  const state = store.getState();
  const stateVin = state.borrowerReducer.vehicle.vin;
  const stateVehicle = state.borrowerReducer.vehicle;

  if (!stateVin) return;

  const url = '/api/data/vehicle/vin/' + stateVin;

  const response = await getUrl<IGetNvicReturn>(url);

  const {
    make,
    model,
    vin,
    nvic,
    variant,
    buildYear,
    glassValuation,
    description,
  } = response.data;

  const isVehicleKnown = glassValuation ? true : false;

  store.dispatch(
    setVehicle({
      ...stateVehicle,
      make,
      model,
      nvic,
      vin,
      variant,
      buildYear,
      glassValuation,
      description,
      isVehicleKnown,
    })
  );

  return;
}
