import { ELoanUsage } from '@/context/borrower';

import { DEFAULT_PARTNER } from './defaultPartner';
import {
  DEFAULT_MATCHING_ENGINE_LENDERS,
  EMatchingEngineLenders,
} from './matchingEngineEnums';
import { EPartners, IPartnerParams } from './partnerTypes';

export const CHERY_SELLER_NAME = 'Chery';

export const CHERY_PARTNER: IPartnerParams = {
  ...DEFAULT_PARTNER,
  id: EPartners.CHERY,
  loanUsage: ELoanUsage.PERSONAL,
  dealerName: CHERY_SELLER_NAME,
  dealerEmail: 'example@chery.com',
  dealerPhone: '1300123456',
  dealerAbn: '55136521830',
  primaryColor: '#CA0100',
  secondaryColor: '#000000',
  termsAndConditionsLink: 'https://www.vroom.finance/privacycheryfinance',
  hideSellerScreen: true,
  hideSelectVehicleScreen: true,
  showTotalAmountPayable: true,
  whiteLabel: true,
  logo: 'chery.png',
  waterfallLenders: [
    [EMatchingEngineLenders.BRANDED_FINANCIAL_SERVICES],
    DEFAULT_MATCHING_ENGINE_LENDERS,
  ],
};
