import { getUrl } from '@/apiCalls/fetch';
import { setVehicle } from '@/context/borrower';
import { store } from '@/context/store';

import { IGetNvicReturn } from './lookupNvicTypes';

export async function lookupNvic(): Promise<void> {
  const state = store.getState();
  const stateNvic = state.borrowerReducer.vehicle.nvic;
  const stateVehicle = state.borrowerReducer.vehicle;

  if (!stateNvic) return;

  const url = '/api/data/vehicle/nvic/' + stateNvic;

  const response = await getUrl<IGetNvicReturn>(url);

  const {
    make,
    model,
    vin,
    nvic,
    variant,
    buildYear,
    glassValuation,
    description,
  } = response.data;

  const isVehicleKnown = glassValuation ? true : false;

  store.dispatch(
    setVehicle({
      ...stateVehicle,
      make,
      model,
      vin,
      nvic,
      variant,
      buildYear,
      glassValuation,
      description,
      isVehicleKnown,
    })
  );

  return;
}
