import { IPersonalLoan } from '@/context/borrower';

export function isPersonalLoanComplete(personalLoan: IPersonalLoan): boolean {
  if (
    !personalLoan.monthlyRepayment &&
    !personalLoan.lenderName &&
    !personalLoan.loanBalance
  ) {
    return true;
  }
  if (
    personalLoan.monthlyRepayment === undefined &&
    personalLoan.lenderName === undefined &&
    personalLoan.loanBalance === undefined
  ) {
    return true;
  }

  if (
    personalLoan.monthlyRepayment &&
    personalLoan.lenderName &&
    personalLoan.loanBalance
  ) {
    return true;
  }
  return false;
}
