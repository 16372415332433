import {
  EApplicationAssetType,
  ELoanType,
  ELoanUsage,
  EVehicleAssetType,
} from '@/context/borrower';
import {
  DEFAULT_COMMISSION,
  DEFAULT_COMMISSION_TYPE,
  DEFAULT_TERMS_AND_CONDITIONS_LINK,
} from '@/utils/constants';

import { DEFAULT_MATCHING_ENGINE_LENDERS } from './matchingEngineEnums';
import { EPartners, IPartnerParams } from './partnerTypes';

export const DEFAULT_PARTNER: IPartnerParams = {
  id: EPartners.UNKNOWN,
  url: null,
  dealerName: 'Vroom',
  dealerAccountNumber: null,
  vehicleStockNumber: null,
  price: null,
  nvic: null,
  vin: null,
  dealerAbn: null,
  dealerEmail: null,
  dealerPhone: null,
  leadEmail: null,
  vehicleKilometers: null,
  loanUsage: ELoanUsage.PERSONAL,
  welcomeScreen: false,
  assetType: EVehicleAssetType.CAR_VAN_OR_UTE,
  logo: null,
  whiteLabel: false,
  primaryColor: null,
  secondaryColor: null,
  tertiaryColor: null,
  quaternaryColor: null,
  termsAndConditionsLink: DEFAULT_TERMS_AND_CONDITIONS_LINK,
  hideSelectVehicleScreen: false,
  hideSellerScreen: false,
  showTotalAmountPayable: false,
  waterfallLenders: [DEFAULT_MATCHING_ENGINE_LENDERS],
  initialLoanType: ELoanType.CHATTEL_MORTGAGE,
  initialLoanAssetType: EApplicationAssetType.MOTOR_VEHICLE,
  financeTypeCommission: {
    defaultValue: DEFAULT_COMMISSION,
    defaultValueType: DEFAULT_COMMISSION_TYPE,
  },
};
