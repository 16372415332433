import { useState } from 'react';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { submitApplication } from '@/apiCalls/submitApplication';
import { Button, EButtonType } from '@/components/Button';
import { DisplayHeading } from '@/components/DisplayHeading';
import { DocumentUpload } from '@/components/DocumentUpload';
import { Header } from '@/components/Header';
import { Loading } from '@/components/Loading';
import { ProgressBar } from '@/components/ProgressBar';
import {
  ESupportingDocCodes,
  selectSelectedLender,
  selectSupportingDocs,
  setProgressPercent,
} from '@/context/borrower';
import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { UploadBankStatements } from '@/UserInput/UploadBankStatements';

export const baseId = 'screen-SupportingDocs';

export function SupportingDocs(): JSX.Element {
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const supportingDocs = useAppSelector(selectSupportingDocs);
  const selectedLender = useAppSelector(selectSelectedLender);
  const requiredDocs = selectedLender?.requiredDocuments;

  if (!requiredDocs) {
    return <div>Required Docs is null</div>;
  }

  // ***** Event Handlers *****
  function handleBackClick(): void {
    const backScreen = getBackScreen();
    dispatch(setDisplay(backScreen));
  }

  async function handleNextClick(): Promise<void> {
    setShowErrors(true);
    if (!isComplete()) return;
    // Keep before createOrUpdate
    dispatch(setProgressPercent(100));
    // Keep before submitApplication
    createOrUpdateBorrower();
    submitApplication();
    dispatch(setModal(EModals.APPLICATION_SUBMITTED));
  }

  // ***** Helpers ******
  function isComplete(): boolean {
    if (!requiredDocs) {
      return true;
    }

    for (const requiredDocCode of Object.keys(requiredDocs)) {
      if (
        (!(requiredDocCode in supportingDocs) ||
          supportingDocs[requiredDocCode]?.length === 0) &&
        requiredDocCode !== ESupportingDocCodes.PRIVACY // Privacy isn't implemented so can't be checked for
      ) {
        return false;
      }
    }

    return true;
  }

  function getBackScreen(): EDisplays {
    return EDisplays.ASSETS_AND_LIABILITIES;
  }

  // ***** Render *****

  return loading ? (
    <Loading />
  ) : (
    <>
      <div data-testid={baseId} className='px-4 '>
        <Header />
        <ProgressBar />

        <div className='flex '>
          <Button
            onClick={handleBackClick}
            type={EButtonType.SECONDARY_OUTLINE}
            square
          >
            Back
          </Button>
          <Button onClick={handleNextClick} square>
            Next
          </Button>
        </div>

        <div className='px-4'>
          <DisplayHeading title='Supporting Documents' infoText='These are all the documents that the lender requires in order to proceed with the loan. They are mandatory to complete a full application.' />
          <p className='uppercase text-center text-xs font-bold'></p>
          {Object.entries(requiredDocs).map(([key, value]) => {
            if (
              key === ESupportingDocCodes.BANK_STATEMENTS_12M ||
              key === ESupportingDocCodes.BANK_STATEMENTS_3M ||
              key === ESupportingDocCodes.BANK_STATEMENTS_6M
            ) {
              return <UploadBankStatements key={key} code={key} />;
            } else if (key === ESupportingDocCodes.PRIVACY) {
              return;
            }
            {
              return (
                <DocumentUpload
                  key={key}
                  baseId={baseId}
                  showErrors={showErrors && !supportingDocs[key]}
                  code={key}
                  document={supportingDocs[key]}
                  value={value}
                />
              );
            }
          })}
        </div>
      </div>
    </>
  );
}
