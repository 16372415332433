import {
  EApplicationAssetType,
  ELoanType,
  ELoanUsage,
  EMoneyType,
  EQuickQuoteFinanceType,
  ESellerType,
  EVehicleAssetType,
} from '@/context/borrower';

export enum EPartners {
  SHOPFORCARS = 'SHOPFORCARS',
  EASYCARS = 'EASYCARS',
  CARSALES = 'CARSALES',
  DEMO = 'DEMO',
  TRUCKSTOP = 'TRUCKSTOP',
  LDV = 'LDV',
  MYMOTO = 'MYMOTO',
  YCCARS = 'YCARS',
  NONE = 'NONE',
  ALLBIDS = 'ALLBIDS',
  SCOTTS_GROUP = 'SCOTTS_GROUP',
  OC_AUTOMOTIVE = 'OC_AUTOMOTIVE',
  JC_AUTOTRADERS_PTY_LTD = 'JC_AUTOTRADERS_PTY_LTD',
  DOZO_CAR_SALES = 'DOZO_CAR_SALES',
  CARIZMA_MOTORS = 'CARIZMA_MOTORS',
  AUTO_HOUSE = 'AUTO_HOUSE',
  BEEPZ_AUTO_SOLUTIONS = 'BEEPZ_AUTO_SOLUTIONS',
  CARNET_WESTERN_SYDNEY = 'CARNET_WESTERN_SYDNEY',
  TONY_LAHOOD = 'TONY_LAHOOD',
  UNKNOWN = 'UNKNOWN',
  CHERY = 'CHERY',
  SOLAR = 'SOLAR',
  // IMPORTANT!
  // If you want to do a new test, add a new test id. DO NOT re-use an existing one. Each one serves a specific purpose!
  TEST_1 = 'TEST_1',
  TEST_2 = 'TEST_2',
  TEST_3 = 'TEST_3',
  TEST_4 = 'TEST_4',
  TEST_5 = 'TEST_5', // Free to use, not currently required
}

export interface IPartnerParams {
  id: EPartners;
  url: string | null;
  dealerName: string | null;
  dealerAccountNumber: string | null;
  vehicleStockNumber: string | null;
  price: number | null;
  nvic: string | null;
  vin: string | null;
  dealerAbn: string | null;
  dealerEmail: string | null;
  dealerPhone: string | null;
  leadEmail: string | null;
  vehicleKilometers: string | null;
  loanUsage: ELoanUsage;
  welcomeScreen: boolean;
  termsAndConditionsLink: string;
  assetType: EVehicleAssetType;
  logo: string | null;
  whiteLabel: boolean;
  primaryColor: string | null;
  secondaryColor: string | null;
  tertiaryColor: string | null;
  quaternaryColor: string | null;
  type?: ESellerType;
  hideSellerScreen: boolean;
  hideSelectVehicleScreen: boolean;
  showTotalAmountPayable: boolean;
  waterfallLenders: string[][];
  initialLoanType: ELoanType;
  initialLoanAssetType: EApplicationAssetType;
  financeTypeCommission: {
    [key in EQuickQuoteFinanceType]?: {
      value: number;
      type: EMoneyType;
    };
  } & {
    defaultValue: number;
    defaultValueType: EMoneyType;
  };
}
