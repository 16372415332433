import { IPartnerParams } from '@/partners/partnerTypes';
import { EParamaters } from '@/utils/setParametersFromParams';

import { ISetInitialVehicle } from './ISetInitialVehicle';

export function getInitialVehicleData(
  partner: IPartnerParams,
  params: URLSearchParams
): ISetInitialVehicle {
  return {
    buildYear: params.get(EParamaters.BUILD_YEAR),
    condition: params.get(EParamaters.CONDITION),
    make: params.get(EParamaters.MAKE),
    model: params.get(EParamaters.MODEL),
    nvic: params.get(EParamaters.NVIC),
    sourceYardId: params.get(EParamaters.SOURCE_YARD_ID),
    stockNumber: params.get(EParamaters.STOCK_NUMBER),
    variant: params.get(EParamaters.VARIANT),
    vehicleKilometers: params.get(EParamaters.KILOMETERS),
    vehiclePrice: params.get(EParamaters.AMOUNT),
    vin: params.get(EParamaters.VIN),
    partnerId: partner.id,
  };
}
