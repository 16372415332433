import { Input } from '@/components/Input';
import { selectOtherLoans, setOtherLoans } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'OtherLoans';

export function OtherLoans({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectOtherLoans);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    // MAXIMUM
    const MAXIMUM = 99999;
    const intValue = getNumber(value, MAXIMUM);
    if (intValue === currentState) return;
    dispatch(setOtherLoans(intValue));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Other Loans'
        placeholder='Required'
        showErrors={
          showErrors && (currentState === undefined || currentState === null)
        }
        onChange={handleChange}
        id={`${baseId}-input-otherLoans`}
        value={currentState?.toLocaleString()}
        prefix='$'
      />
    </div>
  );
}
