import { useEffect, useState } from 'react';

import { Combobox, IComboboxOption } from '@/components/Combobox';
import { selectVehicleModel, setVehicleModel } from '@/context/borrower';
import { selectVehicleModelApiResults } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'VehicleModel';

export function VehicleModel({ showErrors, baseId }: IProps): JSX.Element {
  // ** Redux State **

  const currentState = useAppSelector(selectVehicleModel);
  const dispatch = useAppDispatch();
  const vehicleModelApiResults = useAppSelector(selectVehicleModelApiResults);
  // ** Local State **

  const [query, setQuery] = useState<undefined | string>(currentState ?? ''); // Keep track of search
  // *****Functions*****
  // Handle the onChange event for the input.
  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setQuery(value);
    if (typeof value === 'boolean') return;
    if (value === currentState) return;
    if (value?.length > 4) return;
  }

  // When the user selects an option
  const handleSelect = (option: IComboboxOption): void => {
    if (option.value === currentState) return;
    dispatch(setVehicleModel(option.value));
  };

  useEffect(() => {
    setQuery(currentState);
  }, [currentState]);
  return (
    <div data-testid={testId}>
      <Combobox
        options={vehicleModelApiResults ?? []}
        placeholder='Select model'
        heading='Vehicle Model Options'
        onChange={handleChange}
        query={query ?? ''}
        onSelect={handleSelect}
        showErrors={showErrors && !currentState}
      />
    </div>
  );
}
