import { useState } from 'react';

import { Button } from '@/components/Button';
import { ModalHeader } from '@/components/ModalHeader';
import {
  selectPrimaryBusinessPostCode,
  selectPrimaryBusinessStateName,
  selectPrimaryBusinessStreetName,
  selectPrimaryBusinessStreetNumber,
  selectPrimaryBusinessSuburbName,
} from '@/context/borrower';
import { setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { PrimaryBusinessAddressState } from '@/UserInput/PrimaryBusinessAddressState';
import { PrimaryBusinessPostCode } from '@/UserInput/PrimaryBusinessPostCode';
import { PrimaryBusinessStreetName } from '@/UserInput/PrimaryBusinessStreetName';
import { PrimaryBusinessStreetNumber } from '@/UserInput/PrimaryBusinessStreetNumber';
import { PrimaryBusinessSuburbName } from '@/UserInput/PrimaryBusinessSuburbName';
import { PrimaryBusinessUnitNumber } from '@/UserInput/PrimaryBusinessUnitNumber';

export const testId = 'LenderSpecificQuestionTwo';

export const LenderSpecificQuestionThree = (): JSX.Element => {
  // ***** Constants *****
  const baseId = 'modal-LenderSpecificQuestionTwo';
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState<boolean>(false);

  // ** Recdux **

  const dispatch = useAppDispatch();
  const primaryBusinessStreetNumber = useAppSelector(
    selectPrimaryBusinessStreetNumber
  );
  const primaryBusinessSuburbName = useAppSelector(
    selectPrimaryBusinessSuburbName
  );
  const primaryBusinessStreetName = useAppSelector(
    selectPrimaryBusinessStreetName
  );
  const primaryBusinessPostCode = useAppSelector(selectPrimaryBusinessPostCode);
  const primaryBusinessStateName = useAppSelector(
    selectPrimaryBusinessStateName
  );

  const handleClick = (): void => {
    setShowErrors(true);

    if (!isComplete()) {
      return;
    }
    dispatch(setModal(null));
  };

  const isComplete = (): boolean => {
    if (
      primaryBusinessStreetNumber === undefined ||
      primaryBusinessSuburbName === undefined ||
      primaryBusinessStreetName === undefined ||
      primaryBusinessSuburbName === undefined ||
      primaryBusinessPostCode === undefined ||
      primaryBusinessStateName === undefined
    )
      return false;
    if (
      primaryBusinessStreetNumber === null ||
      primaryBusinessSuburbName === null ||
      primaryBusinessStreetName === null ||
      primaryBusinessSuburbName === null ||
      primaryBusinessPostCode === null ||
      primaryBusinessStateName === null
    )
      return false;
    return true;
  };

  return (
    <div data-testid={testId} className='flex flex-col justify-between  py-2'>
      <ModalHeader title='Business Address' baseId={baseId} />
      <div className='flex-center space-x-2'>
        <PrimaryBusinessUnitNumber baseId={baseId} showErrors={false} />
        <PrimaryBusinessStreetNumber baseId={baseId} showErrors={showErrors} />
      </div>
      <div className='flex-center space-x-2'>
        <PrimaryBusinessStreetName baseId={baseId} showErrors={showErrors} />
        <PrimaryBusinessSuburbName baseId={baseId} showErrors={showErrors} />
      </div>
      <div className='flex-center space-x-2'>
        <PrimaryBusinessAddressState baseId={baseId} showErrors={showErrors} />
        <PrimaryBusinessPostCode baseId={baseId} showErrors={showErrors} />
      </div>
      <div className='py-2 mx-16'>
        <Button onClick={handleClick} id={`${baseId}-button-close`}>
          Save
        </Button>
      </div>
    </div>
  );
};
