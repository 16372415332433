import { useState } from 'react';
import { useSelector } from 'react-redux';

import { AssetsNavigationButtons } from '@/components/AssetsNavigationButtons';
import { Button } from '@/components/Button';
import { ModalHeader } from '@/components/ModalHeader';
import {
  deleteRealEstate,
  selectRealEstateActiveIndex,
  selectRealEstates,
  setRealEstateActiveIndex,
} from '@/context/borrower';
import { setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { RealEstateAssetValue } from '@/UserInput/RealEstateAssetValue';
import { isRealEstateAssetComplete } from '@/utils/checkAssetsAndLiabilities';

export const testId = 'RealEstateAsset';

export const RealEstateAsset = (): JSX.Element => {
  // ***** Constants *****
  const baseId = 'modal-RealEstates';
  const modaltitle = 'Real Estate';

  // ***** Redux *****

  const dispatch = useAppDispatch();
  const activeRealEstateIndex = useAppSelector(selectRealEstateActiveIndex);
  const titleNumber = activeRealEstateIndex + 1;
  const realEstates = useSelector(selectRealEstates);
  const hasPreviousRealEstateAsset =
    realEstates?.length > 0 && activeRealEstateIndex !== 0;
  const hasNextRealEstate = realEstates?.length - 1 > activeRealEstateIndex;

  // ***** Local State *****
  const [showErrors, setShowErrors] = useState<boolean>(false);

  // ***** Handlers *****

  function handleSaveClick(): void {
    dispatch(setModal(null));
  }

  function handleNextClick(): void {
    if (!isRealEstateAssetComplete(realEstates[activeRealEstateIndex])) return;
    dispatch(setRealEstateActiveIndex(activeRealEstateIndex + 1));
  }

  function handleDeleteClick(): void {
    // Delete Current Real Estate
    dispatch(deleteRealEstate());
    // Change Active Index
    if (
      realEstates?.length >= 1 &&
      realEstates?.length > activeRealEstateIndex - 1
    ) {
      dispatch(setRealEstateActiveIndex(activeRealEstateIndex - 1));
    }
  }

  function handlePreviousClick(): void {
    if (
      realEstates?.length >= 1 &&
      realEstates?.length > activeRealEstateIndex - 1
    ) {
      dispatch(setRealEstateActiveIndex(activeRealEstateIndex - 1));
    }
  }

  // ****** Render ******
  return (
    <div data-testid={testId} className='flex flex-col justify-between  py-2'>
      <ModalHeader title={modaltitle + '-' + titleNumber} baseId={baseId} />

      <AssetsNavigationButtons
        handleDeleteClick={handleDeleteClick}
        handlePreviousClick={handlePreviousClick}
        handleNextClick={handleNextClick}
        hasPrevious={hasPreviousRealEstateAsset}
        hasNext={hasNextRealEstate}
      />

      <RealEstateAssetValue
        baseId={baseId}
        showErrors={showErrors}
        setShowErrors={setShowErrors}
      />

      <div className='py-2 mx-16'>
        <Button onClick={handleSaveClick} id={`${baseId}-button-close`}>
          Save
        </Button>
      </div>
    </div>
  );
};
