import { Header } from '@/components/Header';
import { RemoteImage } from '@/components/RemoteImage';
import { EMAIL, PHONE } from '@/utils/constants';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid';

export const baseId = 'IndicativelyDeclined';

export function IndicativelyDeclined(): JSX.Element {
  return (
    <div data-testid={baseId}>
      <Header />
      <div className='flex-center pt-10 pb-4 relative'>
        <div className='bg-primary h-40 w-40 rounded-full' />
        <RemoteImage fileName='avatar.svg' className='absolute' />
      </div>
      <div className='text-center flex flex-col space-y-2'>
        <h3 className='uppercase text-xl text-primary font-bold '>
          Sorry about that
        </h3>
        <p className='text-secondary pb-4'>
          Unfortunately, we can&apos;t help you right now.
        </p>
        <p className='font-bold'>But, we&apos;re here to help</p>
        <p className='text-sm'>
          Although we can&apos;t help you right now, do feel <br />
          free to contact us for more information and <br />
          how we can help you in the future.
        </p>
      </div>

      <div className='flex-center pt-4'>
        <div className='border-lg border-2 text-sm bg-white py-2 rounded-xl px-10 text-gray-500 text-center'>
          <h3 className='font-bold pb-4'>
            Have a question? <br />
            Get in touch:
          </h3>
          <a href={`tel:${PHONE}`} className='flex-center space-x-2'>
            <PhoneIcon className='h-4 w-4' />
            <p>{PHONE}</p>
          </a>
          <a href={`mailto:${EMAIL}`} className='flex-center space-x-2'>
            <EnvelopeIcon className='h-4 w-4' />
            <p>{EMAIL}</p>
          </a>
        </div>
      </div>
    </div>
  );
}
