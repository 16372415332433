import { RadioGroup } from '@/components/RadioGroup';
import {
    ESellerType,
    selectSellerType,
    setSellerType
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

import { DealershipContactName } from '../DealershipContactName';
import { DealershipEmail } from '../DealershipEmail';
import { DealershipName } from '../DealershipName';
import { DealershipPhone } from '../DealershipPhone';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'SellerType';

export function SellerType({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectSellerType);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean | undefined): void {
    dispatch(setSellerType(value as ESellerType));
  }

  // ***** Render *****
  const options = [
    {
      label: 'Dealership',
      value: ESellerType.DEALERSHIP,
      id: `${baseId}-button-sellerType-dealership`,
    },

    {
      label: 'Private',
      value: ESellerType.PRIVATE,
      id: `${baseId}-button-sellerType-private`,
    },
    {
      label: 'Unknown',
      value: ESellerType.UNKNOWN,
      id: `${baseId}-button-sellerType-unknown`,
    },
  ];
  return (
    <div data-testid={testId}>
      <RadioGroup
        title='Where do you plan to buy the car from?'
        showErrors={showErrors}
        onChange={handleChange}
        options={options}
        value={currentState}
      />
      {(currentState === ESellerType.DEALERSHIP || currentState === ESellerType.PRIVATE) ? (
        <>
          <DealershipName baseId={baseId} showErrors={showErrors} />
          <DealershipEmail baseId={baseId} showErrors={showErrors} />
          <DealershipPhone baseId={baseId} showErrors={showErrors} />
        </>
      ) : null}
           {currentState === ESellerType.DEALERSHIP ? (
          <DealershipContactName baseId={baseId} showErrors={showErrors} />
      ) : null}
    </div>
  );
}
