import axios from 'axios';

import { IVehicle } from '@/context/borrower';
import { API_URL } from '@/utils/constants';

export async function getVehicleByPlate(
  plate: string | undefined,
  state: string | undefined
): Promise<IVehicle> {
  try {
    const response = await axios.get<IVehicle>(
      `http://localhost:3001/api/asset/select/plate/${state}/${plate}`
    );
    console.log(response);
    return response.data;
  } catch (error: any) {
    throw Error(error);
  }
}

export async function getVehicleYearOptions(): Promise<string[]> {
  const response = await axios.get<string[]>(
    `${API_URL}/api/asset/select/vehicle`
  );
  return response.data;
}

export async function getVehicleMakeOptions(
  year: number | undefined
): Promise<object[]> {
  const response = await axios.get<object[]>(
    `${API_URL}/api/asset/select/vehicle/${year}`
  );
  return response.data;
}

export async function getVehicleModelOptions(
  year: number | undefined,
  make: string | undefined
): Promise<object[]> {
  const response = await axios.get<object[]>(
    `${API_URL}/api/asset/select/vehicle/${year}/${make}`
  );
  return response.data;
}

export async function getVehicleVariantOptions(
  year: number | null | undefined,
  make: string | null | undefined,
  model: string | null | undefined
): Promise<string[]> {
  const response = await axios.get<string[]>(
    `${API_URL}/api/asset/select/vehicle/${year}/${make}/${model}`
  );
  console.log(response.data);
  return response.data;
}
export async function getVehicleGlassOptions(
  year: number | null | undefined,
  make: string | null | undefined,
  model: string | null | undefined,
  variant: string | null | undefined
): Promise<IVehicle[] | void> {
  if (year === null) return;
  if (make === null) return;
  if (model === null) return;
  if (variant === null) return;

  const response = await axios.get<IVehicle[]>(
    `${API_URL}/api/asset/select/vehicle/${year}/${make}/${model}/${variant}`
  );
  return response.data;
}
