import { useState } from 'react';

import { postDocument } from '@/apiCalls/documents';
import { ocrLookup } from '@/apiCalls/ocrLookup';
import {
  ESupportingDocCodes,
  ISupportingDoc,
  setSupportingDocs,
} from '@/context/borrower';
import { setShowOcrDisplay } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/solid';

import { CheckIconCircle } from '../CheckIconCircle';
import { PlusIconCircle } from '../PlusIconCircle';
import { RemoteImage } from '../RemoteImage';

export interface IProps {
  baseId: string;
  showErrors: boolean;
  document: ISupportingDoc[] | null;
  code: string;
  value: string;
}

export const testId = 'DocumentUpload';

export function DocumentUpload({
  baseId,
  showErrors,
  document,
  code,
  value,
}: IProps): JSX.Element {
  const firstDocument = document?.[0];

  // ***** Redux State *****
  const dispatch = useAppDispatch();

  // ***** Local State *****
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  // ***** Event Handlers *****
  function handleChange(value: React.ChangeEvent<HTMLInputElement>): void {
    const file: File | null = value.target?.files
      ? value.target.files[0]
      : null;

    if (file === null) return;

    setUploadError(false);

    uploadFile(file);
  }

  function changeExpanded(): void {
    setExpanded((expanded) => !expanded);
  }

  // ***** Helpers *****
  async function uploadFile(file: File): Promise<void> {
    setLoading(true);
    postDocument('code', [file])
      .then((response) => {
        dispatch(
          setSupportingDocs({
            code,
            document: response,
          })
        ); // Save Url to redux
        if (code === ESupportingDocCodes.DRIVERS_LICENSE_FRONT) {
          dispatch(setShowOcrDisplay(true)); // Show OCR Display
          ocrLookup(); // Call OCR API
        }
      })
      .catch((error) => {
        console.log(error);
        setUploadError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleDeleteClick(): void {
    setUploadError(false);
    if (!firstDocument?.id) return;
    dispatch(
      setSupportingDocs({
        code,
        document: [],
      })
    );
    if (code === ESupportingDocCodes.DRIVERS_LICENSE_FRONT) {
      dispatch(setShowOcrDisplay(false)); // Show OCR Display
    }
    // todo: delete from the backend
    // setLoading(true);

    // deleteDocument(firstDocument.id)
    //   .then(() => {
    //     dispatch(
    //       setSupportingDocs({
    //         code,
    //         document: [],
    //       })
    //     );
    //   })
    //   .catch((error: unknown) => {
    //     console.log(error);
    //     setUploadError(true);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  }

  function determineSymbol(): JSX.Element {
    if (firstDocument?.filename) {
      return <CheckIconCircle />;
    }

    if (expanded) {
      return (
        <div className='h-5 w-5 border rounded-full p-1 border-gray-500 transition-transform duration-500 ease-in-out'>
          <ChevronDownIcon />
        </div>
      );
    }

    return <PlusIconCircle />;
  }

  // ***** Render *****
  return (
    <div data-testid={testId} className='pt-4'>
      <div className='top-0 w-full flex bg-opacity-60 border border-gray-500 rounded-lg'>
        <div
          className={`p-4 bg-white w-full bg-whtie m-auto rounded-lg ${
            showErrors ? 'bg-error' : null
          }`}
        >
          <div
            className='flex justify-between items-center hover:cursor-pointer'
            onClick={changeExpanded}
          >
            <h3 className='capitalize text-sm'>{value}</h3>
            {determineSymbol()}
          </div>
          {expanded && firstDocument?.filename && (
            <div className='flex items-center justify-between space-x-2 w-full pt-3'>
              <p className='text-sm text-center text-secondary'>
                {firstDocument.filename}
              </p>
              <div
                className='border rounded-full p-1 hover:cursor-pointer hover:scale-110'
                onClick={handleDeleteClick}
              >
                <XMarkIcon className='h-3 w-3 ' />
              </div>
            </div>
          )}
          {!firstDocument?.filename && expanded && (
            <>
              <div className='flex flex-col w-max mx-auto text-center hover:cursor-pointer'>
                <label className='hover:cursor-pointer'>
                  <input
                    className='text-sm cursor-pointer w-36 hidden'
                    type='file'
                    onChange={handleChange}
                  />
                  <RemoteImage
                    fileName='feather_upload.svg'
                    className='w-20 h-20'
                  />
                  <p className='font-bold'>Select file</p>
                </label>
              </div>
              {uploadError && (
                <p className='text-sm text-center text-red-500'>
                  Error, please try again
                </p>
              )}
              {loading && <p className='text-sm text-center '>Uploading...</p>}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
