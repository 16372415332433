import { useEffect, useState } from 'react';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Header } from '@/components/Header';
import { RemoteImage } from '@/components/RemoteImage';
import { selectSellerName } from '@/context/borrower';
import {
  EDisplays,
  selectIsOcrLoading,
  selectIsWhiteLabel,
  setDisplay,
  setShowOcrDisplay,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { SOLAR_DEALER_NAME } from '@/partners/solar';

export const baseId = 'screen-expense';

export const testId = 'OcrAnimation';

const delayExecution = (mls: number): Promise<unknown> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve('ok'), mls);
  });
};

export function OcrAnimation(): JSX.Element {
  // ***** Redux State *****
  const isOcrLoading = useAppSelector(selectIsOcrLoading);
  const dispatch = useAppDispatch();
  const sellerName = useAppSelector(selectSellerName);
  const isWhiteLabel = useAppSelector(selectIsWhiteLabel);

  // ***** Local State *****
  const placeholderText = [
    'UPLOADING YOUR LICENCE...',
    'READING YOUR DATA...',
    'CHECKING YOUR DETAILS...',
    'PREFILLING THE FORM...',
    'AND...',
    "IT'S DONE!!",
    '...', // Last element is not shown
  ];
  const [state, setState] = useState('');

  const changePlaceholder = async (): Promise<void> => {
    for (let i = 0; i < placeholderText?.length; i++) {
      await delayExecution(2000);
      setState(placeholderText[i]);
    }
  };

  useEffect(() => {
    changePlaceholder();
  }, []);

  // If isOcrLoading is false, we assume that the external API has completed
  // We still only want to show the summary after all the text has been displayed
  if (state === placeholderText[placeholderText?.length - 1] && !isOcrLoading) {
    createOrUpdateBorrower();

    const nextDisplay = getNextDisplay();
    dispatch(setDisplay(nextDisplay));
    dispatch(setShowOcrDisplay(false));
  }

  function getNextDisplay(): EDisplays {
    if (sellerName === SOLAR_DEALER_NAME) {
      return EDisplays.SOLAR_OCR_SUMMARY;
    }

    return EDisplays.OCR_SUMMARY;
  }

  if (sellerName === SOLAR_DEALER_NAME) {
    return (
      <div data-testid={testId}>
        <Header />
        <div className={`h-screen flex-center relative flex-col`}>
          <RemoteImage fileName='solarPanels.svg' />
          <div className='text-black'>{state}</div>
        </div>
      </div>
    );
  }

  return (
    <div data-testid={testId}>
      <Header />
      <div
        className={`h-screen flex-center relative flex-col ${
          isWhiteLabel && 'flex-col'
        }`}
      >
        {isWhiteLabel ? (
          <div className='w-[250px] h-[250px] bg-primary rounded-full flex justify-center items-center mb-10'>
            <RemoteImage
              fileName='whiteMagnifyingGlass.gif'
              className='w-1/2'
            />
          </div>
        ) : (
          <video
            autoPlay
            loop
            muted
            playsInline
            src='https://driveiq-assets.s3.ap-southeast-2.amazonaws.com/vroom/magnifying_glass.webm'
            className=' greyscale'
          ></video>
        )}

        <div className='text-primary'>{state}</div>
      </div>
    </div>
  );
}
