import { DEFAULT_PARTNER } from '@/partners/defaultPartner';
import { DEFAULT_MATCHING_ENGINE_LENDERS } from '@/partners/matchingEngineEnums';
import { DEFAULT_TERMS_AND_CONDITIONS_LINK } from '@/utils/constants';

import { EDisplays, EModals, IDisplaySlice } from '../types';

import { initialGlassGuideLookUpVehicle } from './initialGlassGuideLookUpVehicle';

export const initialDisplaySlice: IDisplaySlice = {
  display: EDisplays.BASIC,
  modal: EModals.WELCOME,
  showWelcomeScreen: false,
  isLoaded: false,
  isWhiteLabel: false,
  logo: 'driveiq.png',
  initialLoad: false,
  showOcrDisplay: false,
  isOcrLoading: false,
  agreedToTermsAndConditions: false,
  glassGuideLookUpVehicleResults: initialGlassGuideLookUpVehicle,
  termsAndConditionsLink: DEFAULT_TERMS_AND_CONDITIONS_LINK,
  hideSellerScreen: false,
  hideVehicleScreen: false,
  showTotalAmountPayable: false,
  partnerName: null,
  partnerNumber: null,
  partnerEmail: null,
  waterfallLenders: [DEFAULT_MATCHING_ENGINE_LENDERS],
  isAssetKnownViaParams: false,
  partner: DEFAULT_PARTNER,
};
