import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, EButtonType } from '@/components/Button';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import {
  EBorrowerCitizenshipStatus,
  ELoanUsage,
  selectAddressByIndex,
  selectGender,
  selectHasChildren,
  selectIsVehicleKnown,
  selectLoanUsage,
  selectNumberOfChildren,
  selectResidencyStatus,
  selectVisaNumber,
} from '@/context/borrower';
import {
  EDisplays,
  selectHideSellerScreen,
  selectHideVehicleScreen,
  setDisplay,
} from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { Gender, LivingStatus } from '@/UserInput';
import { HaveKids } from '@/UserInput/HaveKids';
import { ResidencyStatus } from '@/UserInput/ResidencyStatus';

export function BasicDetails(): JSX.Element {
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState(false);

  // ***** CONSTANTS *****
  const baseId = 'screen-basic';

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const gender = useAppSelector(selectGender);
  const address = useSelector((state: RootState) =>
    selectAddressByIndex(state, 0)
  );
  const ownershipType = address?.ownershipType ?? undefined;
  const residencyStatus = useAppSelector(selectResidencyStatus);
  const visaNumber = useAppSelector(selectVisaNumber);
  const haveKids = useAppSelector(selectHasChildren);
  const numberOfKids = useAppSelector(selectNumberOfChildren);
  const hideSellerScreen = useAppSelector(selectHideSellerScreen);
  const hideVehicleScreen = useAppSelector(selectHideVehicleScreen);
  const isVehicleKnown = useAppSelector(selectIsVehicleKnown);
  const loanUsage = useAppSelector(selectLoanUsage);

  // ***** Event Handlers *****
  function handleBackClick(): void {
    const backScreen = getBackScreen();
    dispatch(setDisplay(backScreen));
  }

  function handleNextClick(): void {
    setShowErrors(true);
    if (!isComplete()) return;
    setShowErrors(false);
    const nextScreen = getNextScreen();
    dispatch(setDisplay(nextScreen));
  }

  // ***** Helpers *****
  function isComplete(): boolean {
    if (gender === undefined) return false;
    if (ownershipType === undefined) return false;
    if (residencyStatus === undefined) return false;
    if (
      residencyStatus === EBorrowerCitizenshipStatus.VISA_HOLDER &&
      visaNumber === undefined
    )
      return false;
    if (haveKids === undefined) return false;
    if (haveKids && numberOfKids === undefined) return false;
    return true;
  }

  function getNextScreen(): EDisplays {
    return EDisplays.INCOME;
  }

  function getBackScreen(): EDisplays {
    // Business Use
    if (loanUsage === ELoanUsage.BUSINESS) {
      return EDisplays.BUSINESS;
    }

    // Consumer Use
    if (isVehicleKnown) {
      if (!hideSellerScreen) {
        return EDisplays.SELLER;
      }
      if (!hideVehicleScreen) {
        return EDisplays.VEHICLE;
      }
    }

    return EDisplays.LOAN;
  }

  return (
    <div data-testid={baseId}>
      <Header />
      <ProgressBar />
      <div className='flex '>
        <Button
          onClick={handleBackClick}
          type={EButtonType.SECONDARY_OUTLINE}
          square
        >
          Back
        </Button>
        <Button onClick={handleNextClick} square>
          Next
        </Button>
      </div>
      <div className='px-4'>
        <DisplayHeading
          title='Basic Details'
          infoText='We just need to ask some basic details about you'
        />
        <Gender showErrors={showErrors} baseId='screen-basic' />
        <LivingStatus showErrors={showErrors} baseId='screen-basic' />
        <ResidencyStatus showErrors={showErrors} baseId='screen-basic' />
        <HaveKids showErrors={showErrors} baseId='screen-basic' />
      </div>
    </div>
  );
}
