import { createSlice } from '@reduxjs/toolkit';

import { initialDisplaySlice } from './initalState/initialDisplaySlice';
import { displayReducers } from './reducers';

export const displaySlice = createSlice({
  name: 'display',
  initialState: initialDisplaySlice,
  reducers: displayReducers,
});

const displayReducer = displaySlice.reducer;
export { displayReducer };
