import { useState } from 'react';

import { Button } from '@/components/Button';
import { ModalHeader } from '@/components/ModalHeader';
import { selectGender } from '@/context/borrower';
import { setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { Gender } from '@/UserInput';
import { BirthDate } from '@/UserInput/BirthDate';
import { LicenseExpiry } from '@/UserInput/LicenseExpiry';
import { LicenseNumber } from '@/UserInput/LicenseNumber';
import { LicenseState } from '@/UserInput/LicenseState';
import { ResidencyStatus } from '@/UserInput/ResidencyStatus';

export const testId = 'PersonalInformation';

export function PersonalInformation(): JSX.Element {
  // ***** Constants *****
  const baseId = 'modal-ContactDetails';
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState<boolean>(false);
  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const gender = useAppSelector(selectGender);

  // ***** Handlers *****
  function handleClick(): void {
    setShowErrors(true);
    if (!isComplete()) return;
    dispatch(setModal(null));
  }

  // ***** Helpers *****
  function isComplete(): boolean {
    if (gender === null) return false;

    return true;
  }

  // ***** Render *****
  return (
    <div data-testid={testId} className='flex flex-col justify-between  py-2'>
      <ModalHeader title='Personal Information' baseId={baseId} />
      <Gender baseId={baseId} showErrors={showErrors} />
      <ResidencyStatus baseId={baseId} showErrors={showErrors} />
      <LicenseNumber baseId={baseId} showErrors={showErrors} />
      <LicenseState baseId={baseId} showErrors={showErrors} />
      <LicenseExpiry baseId={baseId} showErrors={showErrors} />
      <BirthDate baseId={baseId} showErrors={showErrors} />
      <div className='py-2 mx-16'>
        <Button onClick={handleClick} id={`${baseId}-button-close`}>
          <p>Close</p>
        </Button>
      </div>
    </div>
  );
}
