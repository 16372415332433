import { IPartnerParams } from '@/partners/partnerTypes';
import { PayloadAction } from '@reduxjs/toolkit';

export const partnerReducers = {
  setShowWelcomeScreen: (state, action: PayloadAction<boolean>): void => {
    state.showWelcomeScreen = action.payload;
  },
  setIsWhiteLabel: (state, action: PayloadAction<boolean>): void => {
    state.isWhiteLabel = action.payload;
  },
  setLogo: (state, action: PayloadAction<string>): void => {
    state.logo = action.payload;
  },
  setInitialLoad: (state, action: PayloadAction<boolean>): void => {
    state.initialLoad = action.payload;
  },
  setShowOcrDisplay: (state, action: PayloadAction<boolean>): void => {
    state.showOcrDisplay = action.payload;
  },
  setIsOcrLoading: (state, action: PayloadAction<boolean>): void => {
    state.isOcrLoading = action.payload;
  },
  setAgreedToTermsAndConditions: (
    state,
    action: PayloadAction<boolean>
  ): void => {
    state.agreedToTermsAndConditions = action.payload;
  },
  setTermsAndConditionsLink: (state, action: PayloadAction<string>): void => {
    state.termsAndConditionsLink = action.payload;
  },
  setHideSellerScreen: (state, action: PayloadAction<boolean>): void => {
    state.hideSellerScreen = action.payload;
  },
  setHideVehicleScreen: (state, action: PayloadAction<boolean>): void => {
    state.hideVehicleScreen = action.payload;
  },
  setShowTotalAmountPayable: (state, action: PayloadAction<boolean>): void => {
    state.showTotalAmountPayable = action.payload;
  },
  setPartnerName: (state, action: PayloadAction<string | null>): void => {
    state.partnerName = action.payload;
  },
  setPartnerNumber: (state, action: PayloadAction<string | null>): void => {
    state.partnerNumber = action.payload;
  },
  setPartnerEmail: (state, action: PayloadAction<string | null>): void => {
    state.partnerEmail = action.payload;
  },
  setWaterfallLenders: (state, action: PayloadAction<string[][]>): void => {
    state.waterfallLenders = action.payload;
  },
  setIsAssetKnownViaParams: (state, action: PayloadAction<boolean>): void => {
    state.isAssetKnownViaParams = action.payload;
  },
  setPartner: (state, action: PayloadAction<IPartnerParams>): void => {
    state.partner = action.payload;
  },
};
