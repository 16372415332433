import { ELoanUsage } from '@/context/borrower';

import { DEFAULT_PARTNER } from './defaultPartner';
import { ALL_MATCHING_ENGINE_LENDERS } from './matchingEngineEnums';
import { EPartners, IPartnerParams } from './partnerTypes';

export const TRUCKSTOP_PARTNER: IPartnerParams = {
  ...DEFAULT_PARTNER,
  id: EPartners.CHERY,
  loanUsage: ELoanUsage.BUSINESS,
  dealerName: 'Truckstop',
  dealerEmail: 'hello@truckstop.com',
  dealerPhone: '1300 000 000',
  dealerAbn: '000000000',
  primaryColor: '#CA0100',
  secondaryColor: '#000000',
  showTotalAmountPayable: true,
  whiteLabel: true,
  waterfallLenders: [ALL_MATCHING_ENGINE_LENDERS],
};
