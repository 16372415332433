import { useSelector } from 'react-redux';

import { Input } from '@/components/Input';
import {
  IPersonalLoan,
  selectPersonalLoanActiveIndex,
  selectPersonalLoanByIndex,
  setPersonalLoanByIndex,
} from '@/context/borrower';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
  setShowErrors: (value: boolean) => void;
}

export const testId = 'PersonalLoanLenderName';

export function PersonalLoanLenderName({
  baseId,
  showErrors,
  setShowErrors,
}: IProps): JSX.Element {
  // ***** Redux *****
  const activeCardIndex = useAppSelector(selectPersonalLoanActiveIndex);
  const personalLoan = useSelector((state: RootState) =>
    selectPersonalLoanByIndex(state, activeCardIndex)
  );
  const lendername = personalLoan?.lenderName;
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    setShowErrors(false);
    if (typeof value !== 'string') return;
    if (value === '') {
      const newPersonalLoan: IPersonalLoan = {
        ...personalLoan,
        lenderName: undefined,
      };

      dispatch(
        setPersonalLoanByIndex({
          index: activeCardIndex,
          personalLoan: newPersonalLoan,
        })
      );
      return;
    }
    const newPersonalLoan: IPersonalLoan = {
      ...personalLoan,
      lenderName: value,
    };

    dispatch(
      setPersonalLoanByIndex({
        index: activeCardIndex,
        personalLoan: newPersonalLoan,
      })
    );
  }
  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Lender name'
        placeholder='ANZ'
        showErrors={showErrors && !lendername}
        onChange={handleChange}
        id={`${baseId}-input-personalLoanLenderName`}
        value={lendername?.toLocaleString() ?? ''}
      />
    </div>
  );
}
