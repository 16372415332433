import { Button } from '../Button';

export interface IProps {
  onClick: () => void;
  heading: string;
  id: string;
  text: string;
  showErrors: boolean;
}

export const testId = 'SummarySection';

export function SummarySection({
  onClick,
  heading,
  id,
  text,
  showErrors,
}: IProps): JSX.Element {
  return (
    <div
      data-testid={testId}
      className={`border shadow-lg bg-white p-4 rounded-lg my-4 ${
        showErrors && 'bg-error'
      }`}
    >
      <div className='flex justify-between items-center'>
        <h3 className='w-full font-bold text-lg'>{heading}</h3>
        <div>
          <Button onClick={onClick} id={id}>
            Edit
          </Button>
        </div>
      </div>
      <p className='pt-2'>{text}</p>
    </div>
  );
}
