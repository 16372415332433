import { RadioGroup } from '@/components/RadioGroup';
import {
  EVehicleAssetType,
  selectVehicleAssetType,
  setVehicleAssetType,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'VehicleAssetType';

export function VehicleAssetType({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectVehicleAssetType);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean | undefined): void {
    dispatch(setVehicleAssetType(value as EVehicleAssetType));
  }

  // ***** Render *****
  const options = [
    {
      label: 'Car, Van or Ute',
      value: EVehicleAssetType.CAR_VAN_OR_UTE,
      id: `${baseId}-button-vehicleAssetType-carVanOrUte`,
    },
    {
      label: 'Prime Mover',
      value: EVehicleAssetType.PRIME_MOVER,
      id: `${baseId}-button-vehicleAssetType-primeMover`,
    },
    {
      label: 'Truck',
      value: EVehicleAssetType.TRUCK,
      id: `${baseId}-button-vehicleAssetType-truck`,
    },
  ];
  return (
    <div data-testid={testId}>
      <RadioGroup
        title='Vehicle Type'
        showErrors={showErrors}
        onChange={handleChange}
        options={options}
        value={currentState}
      />
    </div>
  );
}
