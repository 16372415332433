import { PayloadAction } from '@reduxjs/toolkit';

import {
  EBorrowerCitizenshipStatus,
  EGender,
  EState,
  IAddress,
  IPersonalDetails,
} from '../types';

export const personalDetailsReducers = {
  setMobile: (state, action: PayloadAction<string | undefined>): void => {
    state.personalDetails.mobilePhone = action.payload;
  },
  setEmail: (state, action: PayloadAction<string | undefined>): void => {
    state.personalDetails.email = action.payload;
  },
  setGender: (state, action: PayloadAction<EGender>): void => {
    state.personalDetails.gender = action.payload;
  },
  setAddressByIndex: (
    state,
    action: PayloadAction<{ index: number; address: IAddress }>
  ): void => {
    state.address.address[action.payload.index] = action.payload.address;
  },
  setResidencyStatus: (
    state,
    action: PayloadAction<EBorrowerCitizenshipStatus | undefined>
  ): void => {
    state.personalDetails.citizenshipStatus = action.payload;
  },
  setVisaNumber: (state, action: PayloadAction<number | undefined>): void => {
    state.personalDetails.visaNumber = action.payload;
  },

  setHasChildren: (state, action: PayloadAction<boolean>): void => {
    state.personalDetails.hasChildren = action.payload;
  },
  setNumberOfChildren: (
    state,
    action: PayloadAction<number | undefined>
  ): void => {
    state.personalDetails.numberOfChildren = action.payload;
  },
  setPersonalDetails: (
    state,
    action: PayloadAction<IPersonalDetails>
  ): void => {
    state.personalDetails = action.payload;
  },
  setBirthdate: (state, action: PayloadAction<Date | undefined>): void => {
    state.personalDetails.birthdate = action.payload;
  },
  setLicenseNumber: (
    state,
    action: PayloadAction<string | undefined>
  ): void => {
    state.personalDetails.licenseNumber = action.payload;
  },
  setLicenseState: (state, action: PayloadAction<EState | undefined>): void => {
    state.personalDetails.licenseState = action.payload;
  },
  setLicenseExpiry: (state, action: PayloadAction<Date | undefined>): void => {
    state.personalDetails.licenseExpiry = action.payload;
  },
  setFirstName: (state, action: PayloadAction<string | undefined>): void => {
    state.personalDetails.firstName = action.payload;
  },
  setMiddleName: (state, action: PayloadAction<string | undefined>): void => {
    state.personalDetails.middleName = action.payload;
  },
  setLastName: (state, action: PayloadAction<string | undefined>): void => {
    state.personalDetails.lastName = action.payload;
  },
};
