import { useState } from 'react';

import { Button } from '@/components/Button';
import { ModalHeader } from '@/components/ModalHeader';
import { setModal } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';
import { HomeandContents } from '@/UserInput/HomeandContents';
import { OtherAssets } from '@/UserInput/OtherAssets';
import { PlantandEquipment } from '@/UserInput/PlantandEquipment';
import { Savings } from '@/UserInput/Savings';
import { SuperandShares } from '@/UserInput/SuperandShares';

export const testId = 'OtherAssetsSavingsDetails';

export function OtherAssetsSavingsDetails(): JSX.Element {
  // ***** Constants *****
  const baseId = 'modal-OtherAssetsSavingsDetails';
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState<boolean>(false);
  // ***** Redux State *****
  const dispatch = useAppDispatch();

  function handleClick(): void {
    dispatch(setModal(null));
  }

  // ***** Render *****
  return (
    <div data-testid={testId} className='flex flex-col justify-between  py-2'>
      <ModalHeader title='Other Assets' baseId={baseId} />
      <PlantandEquipment baseId={baseId} showErrors={showErrors} />
      <HomeandContents baseId={baseId} showErrors={showErrors} />
      <Savings baseId={baseId} showErrors={showErrors} />
      <SuperandShares baseId={baseId} showErrors={showErrors} />
      <OtherAssets baseId={baseId} showErrors={showErrors} />
      <div className='py-2 mx-16'>
        <Button onClick={handleClick} id={`${baseId}-button-close`}>
          Save
        </Button>
      </div>
    </div>
  );
}
