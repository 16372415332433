import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getLoginViaCode } from '@/apiCalls/getLoginViaCode';
import { Loading } from '@/components/Loading';

export function LoginViaCode(): JSX.Element {
  const code: string | undefined = useParams<{ code: string }>().code;
  const navigate = useNavigate();
  console.log('loginViaCode');

  useEffect(() => {
    getLoginViaCode(code).then((loginData) => {
      console.log('sdklfadskjl');
      navigate(loginData.location);
    });
  }, []);

  return <Loading />;
}
