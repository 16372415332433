import { selectLoanTerm, setLoanTerm } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { MAX_LOAN_TERM, MIN_LOAN_TERM } from '@/utils/constants';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'LoanTerm';

export function LoanTerm({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectLoanTerm);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value;
    const intValue = getNumber(value, MAX_LOAN_TERM);
    if (intValue === currentState) return;
    dispatch(setLoanTerm(intValue));
  }

  // ***** Render *****
  return (
    <div className='py-2'>
      <h1 className='fieldHeading'>Preferred Loan Term (YRS)</h1>
      <div
        data-testid={testId}
        className={`flex-center space-x-2 text-primary font-bold p-2 ${
          showErrors && ''
        }}`} // TODO: Error class?
      >
        <span>{MIN_LOAN_TERM}</span>
        <div className='w-80 relative flex-center'>
          <input
            id={`${baseId}-input-loanTerm`}
            type='range'
            min={MIN_LOAN_TERM}
            max={MAX_LOAN_TERM}
            value={currentState}
            className=' w-full h-1 m-0 bg-primary rounded-full appearance-none cursor-pointer range-sm accent-primary'
            step='1'
            onChange={handleChange}
          />
          <br />
          {currentState === MIN_LOAN_TERM || currentState === MAX_LOAN_TERM ? (
            <div></div>
          ) : (
            <label
              className={`absolute left-${currentState}-7 -bottom-3 text-sm`}
              // Note: This dynamic class name is achieved by adding custom styles to globals.css and adding them to the purge whitelist in tailwind.config.js
            >
              {currentState}
            </label>
          )}
        </div>
        <span>{MAX_LOAN_TERM}</span>
      </div>
    </div>
  );
}
