import { getUrl } from '@/apiCalls/fetch';
import { setBorrower } from '@/context/borrower';
import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { store } from '@/context/store';
import { SOLAR_DEALER_NAME } from '@/partners/solar';
import {
  ELocalStorageVariables,
  getLocalStorageItem,
  setLocalStorageItem,
} from '@/utils/localStorage';

import { IGetBorrowerReturn } from './getBorrowerTypes';

export async function getBorrower(): Promise<void> {
  // ***** Redux *****
  const state = store.getState();
  const display = state.displayReducer.display;
  const token = getLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);
  const sellerName = state.borrowerReducer.seller.name;
  const vehicle = state.borrowerReducer.vehicle;
  const seller = state.borrowerReducer.seller;

  if (!token) {
    console.log('No token found in local storage');
    return;
  }

  // ***** Request *****
  const url = '/api/borrower/get';

  const response = await getUrl<IGetBorrowerReturn>(url);

  // ***** Update Redux *****
  const returnedBorrower = response.data.borrower;

  // BE does not save these values
  returnedBorrower.address.addressIndex = 0;
  returnedBorrower.employments.currentEmploymentIndex = 0;
  returnedBorrower.personalLoans.personalLoanActiveIndex = 0;
  returnedBorrower.creditCards.creditCardActiveIndex = 0;
  returnedBorrower.motorVehicles.motorVehicleActiveIndex = 0;
  returnedBorrower.realEstates.realEstateActiveIndex = 0;

  // BE Would otherwise overwrite FE vehicle / seller set by FE params which is incorrect
  returnedBorrower.seller = seller;
  returnedBorrower.vehicle = vehicle;

  store.dispatch(setBorrower(returnedBorrower));
  const returnedFirstName = response.data.borrower.personalDetails.firstName;
  if (returnedFirstName) {
    setLocalStorageItem(ELocalStorageVariables.FIRST_NAME, returnedFirstName);
  }
  // ***** Update Display *****
  if (display === EDisplays.SERVICING_SUCCESS) return;

  if (returnedBorrower.application.isSubmitted) {
    store.dispatch(setModal(EModals.APPLICATION_IN_PROGRESS));
    return;
  } else if (
    returnedBorrower.application.isPreApproved &&
    sellerName !== SOLAR_DEALER_NAME
  ) {
    store.dispatch(setDisplay(EDisplays.OCR_SUMMARY));
  } else {
    if (sellerName !== SOLAR_DEALER_NAME) {
      store.dispatch(setDisplay(EDisplays.BASIC));
    }
  }
  return;
}
