import { useState } from 'react';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { fetchServicing } from '@/apiCalls/fetchServicing';
import { Button, EButtonType } from '@/components/Button';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { Loading } from '@/components/Loading';
import { ProgressBar } from '@/components/ProgressBar';
import {
  selectBillsPaidFrequency,
  selectEducation,
  selectExpensesShared,
  selectGeneral,
  selectHealthcare,
  selectMortgageRent,
  selectOtherLoans,
  selectTransportVehicle,
} from '@/context/borrower';
import { EDisplays, setDisplay } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { Education } from '@/UserInput/Education';
import { ForseeableChangesToCircumstances } from '@/UserInput/ForseeableChangesToCircumstances';
import { GeneralLiving } from '@/UserInput/GeneralLiving';
import { Healthcare } from '@/UserInput/Healthcare';
import { MortgageRent } from '@/UserInput/MortgageRent';
import { OtherLoans } from '@/UserInput/OtherLoans';
import { PayBillsFrequency } from '@/UserInput/PayBillsFrequency';
import { ShareExpenses } from '@/UserInput/ShareExpenses';
import { TransportVehicle } from '@/UserInput/TransportVehicle';

export const baseId = 'screen-expense';

function Expense(): JSX.Element {
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const shareExpenses = useAppSelector(selectExpensesShared);
  const billsFrequency = useAppSelector(selectBillsPaidFrequency);
  const generalLiving = useAppSelector(selectGeneral);
  const mortgageRent = useAppSelector(selectMortgageRent);
  const transportVehicle = useAppSelector(selectTransportVehicle);
  const education = useAppSelector(selectEducation);
  const healthcare = useAppSelector(selectHealthcare);
  const otherLoans = useAppSelector(selectOtherLoans);

  // ***** Event Handlers *****
  function handleBackClick(): void {
    const backScreen = getBackScreen();
    dispatch(setDisplay(backScreen));
  }

  async function handleNextClick(): Promise<void> {
    setShowErrors(true);
    if (!isComplete()) return;
    createOrUpdateBorrower();
    const nextScreen = await getNextScreen();
    dispatch(setDisplay(nextScreen));
  }

  // ***** Helpers ******
  function isComplete(): boolean {
    if (shareExpenses === undefined) return false;
    if (billsFrequency === undefined) return false;
    if (generalLiving === undefined) return false;
    if (mortgageRent === undefined) return false;
    if (transportVehicle === undefined) return false;
    if (education === undefined) return false;
    if (healthcare === undefined) return false;
    if (otherLoans === undefined) return false;
    return true;
  }

  async function getNextScreen(): Promise<EDisplays> {
    setLoading(true);
    const servicingMet = await fetchServicing();
    if (servicingMet) {
      return EDisplays.SERVICING_SUCCESS;
    }
    return EDisplays.SERVICING_FAILURE;
  }

  function getBackScreen(): EDisplays {
    return EDisplays.INCOME;
  }

  // ***** Render *****

  return loading ? (
    <Loading />
  ) : (
    <div data-testid='Expense'>
      <Header />
      <ProgressBar />
      <div className='flex '>
        <Button
          onClick={handleBackClick}
          type={EButtonType.SECONDARY_OUTLINE}
          square
        >
          Back
        </Button>
        <Button onClick={handleNextClick} square>
          Next
        </Button>
      </div>
      <div className='px-4'>
        <DisplayHeading
          title='Expense'
          infoText='This section looks at your monthly spending to determine the best deal for you'
        />
        <ShareExpenses showErrors={showErrors} baseId={baseId} />
        <PayBillsFrequency showErrors={showErrors} baseId={baseId} />
        <div className='flex space-x-5'>
          <GeneralLiving showErrors={showErrors} baseId={baseId} />
          <MortgageRent showErrors={showErrors} baseId={baseId} />
        </div>
        <div className='flex space-x-5'>
          <TransportVehicle showErrors={showErrors} baseId={baseId} />
          <Healthcare showErrors={showErrors} baseId={baseId} />
        </div>
        <div className='flex space-x-5'>
          <Education showErrors={showErrors} baseId={baseId} />
          <OtherLoans showErrors={showErrors} baseId={baseId} />
        </div>
        <div>
          <ForseeableChangesToCircumstances
            showErrors={showErrors}
            baseId={baseId}
          />
        </div>
      </div>
    </div>
  );
}

export { Expense };
