import { useState } from 'react';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { SolarNavigationButtons } from '@/components/SolarNavigationButtons';
import { SolarProgressBar } from '@/components/SolarProgressBar';
import {
  selectAnnualRevenue,
  selectIndustryType,
  selectPrimaryBusinessPostCode,
  selectPrimaryBusinessStateName,
  selectPrimaryBusinessStreetName,
  selectPrimaryBusinessStreetNumber,
  selectPrimaryBusinessSuburbName,
  selectSelectedLender,
} from '@/context/borrower';
import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
// eslint-disable-next-line no-restricted-imports
import { CheckCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid';

import { SolarLenderCard } from '../SolarLenderCard';

export function SolarLenderSpecificQuestions(): JSX.Element {
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState(false);

  // ***** CONSTANTS *****
  const baseId = 'screen-basic';

  // ***** Redux State *****
  const dispatch = useAppDispatch();

  const annualRevenue = useAppSelector(selectAnnualRevenue);
  const primaryIndustry = useAppSelector(selectIndustryType);
  const selectedLender = useAppSelector(selectSelectedLender);

  const primaryBusinessStreetNumber = useAppSelector(
    selectPrimaryBusinessStreetNumber
  );
  const primaryBusinessStreetName = useAppSelector(
    selectPrimaryBusinessStreetName
  );
  const primaryBusinessSuburbName = useAppSelector(
    selectPrimaryBusinessSuburbName
  );
  const primaryBusinessPostCode = useAppSelector(selectPrimaryBusinessPostCode);
  const primaryBusinessStateName = useAppSelector(
    selectPrimaryBusinessStateName
  );

  // tickIcon condition

  const tickQuestionOne = annualRevenue ? true : false;
  const tickQuestionTwo = primaryIndustry ? true : false;
  const tickQuestionThree =
    primaryBusinessStreetNumber &&
    primaryBusinessStreetName &&
    primaryBusinessSuburbName &&
    primaryBusinessPostCode &&
    primaryBusinessStateName
      ? true
      : false;
  // ***** Event Handlers *****
  function handleBackClick(): void {
    dispatch(setDisplay(EDisplays.SOLAR_ASSETS_AND_LIABILITIES));
  }

  function handleNextClick(): void {
    setShowErrors(true);
    if (!isComplete()) return;
    createOrUpdateBorrower();
    const nextScreen = getNextScreen();
    dispatch(setDisplay(nextScreen));
  }

  function getNextScreen(): EDisplays {
    return EDisplays.SOLAR_SUPPORTING_DOCS;
  }
  function isComplete(): boolean {
    return tickQuestionOne && tickQuestionTwo && tickQuestionThree;
  }

  // ***** Helpers *****

  const handleQuestionOne = (): void => {
    dispatch(setModal(EModals.LENDER_SPECIFIC_QUESTION_ONE));
  };

  const handleQuestionTwo = (): void => {
    dispatch(setModal(EModals.LENDER_SPECIFIC_QUESTION_TWO));
  };
  const handleQuestionThree = (): void => {
    dispatch(setModal(EModals.LENDER_SPECIFIC_QUESTION_THREE));
  };

  const LENDER_SPECIFIC_QUESTIONS = [
    {
      name: 'Business Annual Revenue',
      onClick: handleQuestionOne,
      tickIcon: tickQuestionOne,
    },
    {
      name: 'Industry Type',
      onClick: handleQuestionTwo,
      tickIcon: tickQuestionTwo,
    },
    {
      name: 'Business Address',
      onClick: handleQuestionThree,
      tickIcon: tickQuestionThree,
    },
  ];

  if (!selectedLender) return <div></div>;
  return (
    <>
      <div data-testid={baseId} className='px-4 h-screen-92'>
        <Header />
        <SolarProgressBar />

        <div className=''>
          <div className='my-2'>
            <SolarLenderCard lender={selectedLender} />
          </div>

          <DisplayHeading
            title='Questions from your Lender'
            infoText='Please answer the following questions'
          />

                  <div className={` border rounded-lg m-5 bg-white hover:cursor-pointer`}>
            {LENDER_SPECIFIC_QUESTIONS.map((item, index) => (
              <div
                className={`flex justify-between align-center  p-4 border ${showErrors && !item.tickIcon && 'bg-error'} `}
                onClick={item.onClick}
                key={index}
              >
                <div className=''>{item.name}</div>
                <div className='flex-center'>
                  {item.tickIcon ? (
                    <CheckCircleIcon className='h-5 w-5 text-success' />
                  ) : (
                    <PlusCircleIcon className='h-5 w-5 text-primary' />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <SolarNavigationButtons
        handleBackClick={handleBackClick}
        handleNextClick={handleNextClick}
      />
    </>
  );
}
