import { getPartnerFromUrl } from '@/partners/getPartnerFromUrl';

import {
  getInitialDisplayData,
  getInitialLoanData,
  getInitialSellerData,
  getInitialVehicleData,
  getParameters,
  setInitialDisplay,
  setInitialLoan,
  setInitialSeller,
  setInitialUserState,
  setInitialVehicle,
} from './helpers';

export async function setParametersFromParams(): Promise<void> {
  const partner = getPartnerFromUrl();
  const params = getParameters();

  // Inital Setup - From Cookies
  setInitialUserState();

  // Setup data
  const sellerData = getInitialSellerData(partner, params);
  const vehicleData = getInitialVehicleData(partner, params);
  const loanData = getInitialLoanData(partner, params);
  const displayData = getInitialDisplayData(partner, params);

  // Set data
  await Promise.all([
    setInitialSeller(sellerData),
    setInitialVehicle(vehicleData),
    setInitialLoan(loanData),
    setInitialDisplay(displayData),
  ]);
}
