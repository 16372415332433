import { useState } from 'react';
import { useSelector } from 'react-redux';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Header } from '@/components/Header';
import { SolarNavigationButtons } from '@/components/SolarNavigationButtons';
import {
  EEmploymentStatus,
  selectEmploymentStatusByIndex,
  selectOtherIncome,
  selectOtherIncomeSource,
  selectPaidAmount,
  selectPaidFrequency,
  selectSelectedLender,
} from '@/context/borrower';
import { EDisplays, setDisplay } from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { DealershipEmail } from '@/UserInput/DealershipEmail';
import { DealershipName } from '@/UserInput/DealershipName';
import { DealershipPhone } from '@/UserInput/DealershipPhone';
import { SolarProductDetails } from '@/UserInput/SolarProductDetails';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

import { SolarLenderCard } from '../SolarLenderCard';

// import { LenderRepayments } from './LenderRepayments';

export const baseId = 'screen-preApprovalApproved';

export function SolarIndicativelyApproved(): JSX.Element {
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState(false);

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const employment = useSelector((state: RootState) =>
    selectEmploymentStatusByIndex(state, 0)
  );
  const employmentStatus = employment?.status;
  const paidFrequency = useAppSelector(selectPaidFrequency);
  const amountPaid = useAppSelector(selectPaidAmount);
  const otherIncome = useAppSelector(selectOtherIncome);
  const sourceOfOtherIncome = useAppSelector(selectOtherIncomeSource);
const selectedLender = useAppSelector(selectSelectedLender);

  // ***** Event Handlers *****
  function handleBackClick(): void {
    const backScreen = getBackScreen();
    dispatch(setDisplay(backScreen));
  }

  function handleNextClick(): void {
    setShowErrors(true);
    if (!isComplete()) return;
    const nextScreen = getNextScreen();
    createOrUpdateBorrower();
    dispatch(setDisplay(nextScreen));
  }

  // ***** Helpers ******
  function isComplete(): boolean {
    if (employmentStatus === null) return false;
    if (
      employmentStatus === EEmploymentStatus.CASUAL &&
      employment.years === undefined &&
      employment.months === undefined
    )
      return false;
    if (paidFrequency === null) return false;
    if (amountPaid === null) return false;
    if (
      otherIncome !== null &&
      otherIncome !== 0 &&
      sourceOfOtherIncome === null
    )
      return false;
    return true;
  }

  function getNextScreen(): EDisplays {
    return EDisplays.SOLAR_ASSETS_AND_LIABILITIES;
  }

  function getBackScreen(): EDisplays {
    return EDisplays.SOLAR_OCR_SUMMARY;
  }

  function toggleCongrats(): void {
    setCongratsExpanded((congratsExpanded) => !congratsExpanded);
  }

  const [congratsExpanded, setCongratsExpanded] = useState(false);

  if (!selectedLender) {
    return <div />;
  }

  return (
    <>
      <div data-testid={baseId} className='px-4 '>
        <Header />
        {/* Congratulations */}
        <div className=''>
          <div className='flex flex-col text-center shadow-md bg-white rounded-2xl pt-4 pb-2'>
            <h3 className='text-primary font-bold text-2xl'>Congratulations</h3>
            <p className='text-secondary text-sm'>
              Your application has been indicatively approved
            </p>
            {congratsExpanded && (
              <p className='text-xs px-4 pt-2'>
                An indicative approval is the first level of approval from your
                loan provider. It means that your application has successfully
                gone through the first screening process where your credit
                history, personal debt and your income has been reviewed.
                <br />
                <br /> As your application moves on to the next phase, the
                lender may require more information before granting formal, or
                &apos;unconditional&apos; approval.
              </p>
            )}
            <div
              className='w-full justify-end flex px-2 items-center group cursor-pointer pt-2'
              onClick={toggleCongrats}
            >
              <p className='group-hover:font-bold text-sm transform delay-75 ease-in-out'>
                {congratsExpanded ? 'Less' : 'More'} details
              </p>
              <div className='h-5 w-5 group-hover:scale-125 transform delay-75 ease-in-out'>
                {congratsExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </div>
            </div>
          </div>
        </div>
        {/* Loan Purpose Details */}
        <p className='fieldHeading text-sm text-center m-2'>
          LOAN PURPOSE DETAILS
        </p>
        <div className=''>
          <SolarLenderCard lender={selectedLender} />
        </div>
        {/* Product Details */}
        <p className='fieldHeading text-sm text-center m-2'>PRODUCT DETAILS</p>

        <div className='shadow-md bg-white rounded-2xl p-2'>
          <SolarProductDetails baseId={baseId} showErrors={showErrors} />
        </div>
        {/* Seller Details */}
        <p className='fieldHeading text-sm text-center m-2'>
          SELLER&apos;S DETAILS
        </p>

        <div className='shadow-md bg-white rounded-2xl p-2'>
          <DealershipName baseId={baseId} showErrors={showErrors} />
          <DealershipEmail baseId={baseId} showErrors={showErrors} />
          <DealershipPhone baseId={baseId} showErrors={showErrors} />
        </div>
      </div>{' '}
      <SolarNavigationButtons
        handleBackClick={handleBackClick}
        handleNextClick={handleNextClick}
      />
    </>
  );
}
