import { useState } from 'react';

import {
  selectVehicleBuildYear,
  selectVehicleMake,
  selectVehicleModel,
  selectVehicleVariant,
  setVehicleDescription,
} from '@/context/borrower';
import { selectGlassOptionsForVehicle } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

import { DescriptionBox } from '../DescriptionBox';

export const testId = 'DisplayGlassOptions';

interface IPropsGlassOptions {
  loadingGlassOptions?: boolean;
}

export function DisplayGlassOptions({
  loadingGlassOptions,
}: IPropsGlassOptions): JSX.Element {
  // * Local State
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  // * Redux State
  const currentYear = useAppSelector(selectVehicleBuildYear);
  const currentMake = useAppSelector(selectVehicleMake);
  const currentModel = useAppSelector(selectVehicleModel);
  const currentVariant = useAppSelector(selectVehicleVariant);
  const glassOptionsForVehicle = useAppSelector(selectGlassOptionsForVehicle);
  const dispatch = useAppDispatch();

  if (!currentYear || !currentMake || !currentVariant || !currentModel) {
    return <div />;
  }
  const handleOnClick = (
    description: string | undefined,
    index: number | null
  ): void => {
    if (description === undefined) return;
    dispatch(setVehicleDescription(description));
    setSelectedIndex(index);
  };

  return (
    <div className='pt-10'>
      <h1 className='fieldHeading text-center'>select vehicle match</h1>
      <div className='bg-white shadow-lg p-3 mb-5 bg-body rounded'>
        {loadingGlassOptions ? (
          <p className='text-center'>...Loading</p>
        ) : (
          glassOptionsForVehicle?.map((value, index) => (
            <DescriptionBox
              key={index}
              content={value.description}
              onClick={(): void => handleOnClick(value.description, index)}
              selected={index === selectedIndex}
            />
          ))
        )}
      </div>
    </div>
  );
}
