import { useEffect, useState } from 'react';

import { Combobox, IComboboxOption } from '@/components/Combobox';
import {
  selectSellerName,
  selectVehicleVariant,
  setVehicleVariant,
} from '@/context/borrower';
import { selectVehicleVariantApiResults } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { CHERY_SELLER_NAME } from '@/partners/chery';

export interface IProps {
  showErrors: boolean;
  baseId: string;
}

export const testId = 'VehicleVariant';

export function VehicleVariant({ showErrors, baseId }: IProps): JSX.Element {
  // * Redux State
  const currentState = useAppSelector(selectVehicleVariant);
  const dispatch = useAppDispatch();
  let vehicleVariantApiResults = useAppSelector(selectVehicleVariantApiResults);
  const sellerName = useAppSelector(selectSellerName);

  // * Local State
  const [query, setQuery] = useState<undefined | string>(currentState ?? ''); // Keep track of search
  // *****Functions*****
  // Handle the onChange event for the input.
  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setQuery(value);
    console.log(query);
    if (typeof value === 'boolean') return;
    if (value === currentState) return;
    if (value?.length > 4) return;
  }

  // When the user selects an option
  const handleSelect = (option: IComboboxOption): void => {
    if (option.value === currentState) return;
    if (!option.value) {
      setQuery('---');
      dispatch(setVehicleVariant('---'));
    }
    dispatch(setVehicleVariant(option.value));
  };
  useEffect(() => {
    setQuery(currentState);
  }, [currentState]);

  if (sellerName === CHERY_SELLER_NAME) {
    vehicleVariantApiResults = [
      { id: 1, value: 'Bold' },
      { id: 2, value: 'Distinct' },
    ];
  }

  return (
    <div data-testid={testId}>
      <Combobox
        options={vehicleVariantApiResults ?? []}
        placeholder='Select variant'
        heading='Vehicle Variant Options'
        onChange={handleChange}
        query={query ?? ''}
        onSelect={handleSelect}
        showErrors={showErrors && !currentState}
      />
    </div>
  );
}
