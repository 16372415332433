import { useSelector } from 'react-redux';

import { CheckBox } from '@/components/CheckBox';
import { Input } from '@/components/Input';
import {
  IMotorVehicle,
  selectMotorVehicleActiveIndex,
  selectMotorVehiclebyIndex,
  setMotorVehicleByIndex,
} from '@/context/borrower';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

import { MotorVehicleLoanBalance } from '../MotorVehicleLoanBalance';
import { MotorVehicleLoanLenderName } from '../MotorVehicleLoanLenderName';
import { MotorVehicleMonthlyRepayment } from '../MotorVehicleMonthlyRepayment';

export interface IProps {
  baseId: string;
  showErrors: boolean;
  setShowErrors: (value: boolean) => void;
}

export const testId = 'MotorVehicleAssetValue';

export function MotorVehicleAssetValue({
  baseId,
  showErrors,
  setShowErrors,
}: IProps): JSX.Element {
  // ***** Redux *****
  const dispatch = useAppDispatch();
  const activeMotorVehicelIndex = useAppSelector(selectMotorVehicleActiveIndex);
  const motorVehicle = useSelector((state: RootState) =>
    selectMotorVehiclebyIndex(state, activeMotorVehicelIndex)
  );
  const motorVehicleAssetValue = motorVehicle?.assetValue;
  const agreeLoan = motorVehicle?.isLoanPresent ?? false;
  const agreeLoanPay = motorVehicle?.isWishToPayLoan ?? false;

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    setShowErrors(false);
    if (typeof value !== 'string') return;
    const MAXIMUM = 999999;
    let intValue: number | undefined = getNumber(value, MAXIMUM);
    if (intValue === 0) intValue = undefined;
    if (intValue && intValue > 9999999) return;
    if (motorVehicle === null) return;

    if (intValue === undefined && motorVehicle.isLoanPresent === true) {
      const newMotorVehicle: IMotorVehicle = {
        ...motorVehicle,
        assetValue: intValue,
        isLoanPresent: undefined,
        lenderName: undefined,
        monthlyRepayment: undefined,
        loanBalance: undefined,
        isWishToPayLoan: undefined,
      };
      dispatch(
        setMotorVehicleByIndex({
          index: activeMotorVehicelIndex,
          motorVehicle: newMotorVehicle,
        })
      );
      return;
    }

    const newMotorVehicle: IMotorVehicle = {
      ...motorVehicle,
      assetValue: intValue,
    };

    dispatch(
      setMotorVehicleByIndex({
        index: activeMotorVehicelIndex,
        motorVehicle: newMotorVehicle,
      })
    );
  }

  function handleLoanCheckBoxClick(): void {
    if (motorVehicleAssetValue === undefined) {
      setShowErrors(true);
      return;
    }
    const newMotorVehicle: IMotorVehicle = {
      ...motorVehicle,
      isLoanPresent: !agreeLoan,
    };
    if (newMotorVehicle.isLoanPresent === false) {
      newMotorVehicle.lenderName = undefined;
      newMotorVehicle.monthlyRepayment = undefined;
      newMotorVehicle.loanBalance = undefined;
      newMotorVehicle.isWishToPayLoan = undefined;
    }

    dispatch(
      setMotorVehicleByIndex({
        index: activeMotorVehicelIndex,
        motorVehicle: newMotorVehicle,
      })
    );
  }

  const handleLoanPaymentWish = (): void => {
    const newMotorVehicle: IMotorVehicle = {
      ...motorVehicle,
      isWishToPayLoan: !agreeLoanPay,
    };
    dispatch(
      setMotorVehicleByIndex({
        index: activeMotorVehicelIndex,
        motorVehicle: newMotorVehicle,
      })
    );
  };

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Asset value'
        placeholder='$2000'
        showErrors={showErrors && !motorVehicleAssetValue}
        onChange={handleChange}
        id={`${baseId}-input-motorVehicleAssetValue`}
        value={motorVehicleAssetValue?.toLocaleString() ?? ''}
        prefix='$'
      />
      <div className='flex space-x-2 py-2'>
        <CheckBox onClick={handleLoanCheckBoxClick} checked={agreeLoan} />
        <p className='uppercase text-xs'>I have a loan for this </p>
      </div>

      {agreeLoan ? (
        <>
          {' '}
          <div className='flex space-x-2 py-2'>
            <CheckBox onClick={handleLoanPaymentWish} checked={agreeLoanPay} />
            <p className='uppercase text-xs'>I wish to pay the loan </p>
          </div>
          <MotorVehicleLoanLenderName
            baseId={baseId}
            showErrors={showErrors}
            setShowErrors={setShowErrors}
          />
          <MotorVehicleMonthlyRepayment
            baseId={baseId}
            showErrors={showErrors}
            setShowErrors={setShowErrors}
          />
          <MotorVehicleLoanBalance
            baseId={baseId}
            showErrors={showErrors}
            setShowErrors={setShowErrors}
          />{' '}
        </>
      ) : (
        <div />
      )}
    </div>
  );
}
