import {
  setInitialLoanAssetType,
  setInitialLoanType,
  setInitialLoanUsage,
} from './helpers';
import { ISetInitialLoan } from './ISetInitialLoan';

export function setInitialLoan({
  partnerLoanUsage,
  parameterLoanUsage,
  partnerLoanType,
  partnerLoanAssetType,
}: ISetInitialLoan): void {
  setInitialLoanUsage(partnerLoanUsage, parameterLoanUsage);
  setInitialLoanType(partnerLoanType);
  setInitialLoanAssetType(partnerLoanAssetType);
}
