import { EMoneyType } from '@/context/borrower';
import { store } from '@/context/store';
import { getFinanceType } from '@/utils/getFinanceType';

export function getCommissionAmount(): number {
  const { borrowerReducer, displayReducer } = store.getState();
  const { purchasePrice } = borrowerReducer.loan;
  const financeType = getFinanceType();
  const { financeTypeCommission } = displayReducer.partner;

    console.log('financeTypeCommission', financeTypeCommission);

  const commissionObj = financeTypeCommission[financeType];
  const commissionAmount =
    commissionObj?.value ?? financeTypeCommission.defaultValue;
  const commissionType =
    commissionObj?.type ?? financeTypeCommission.defaultValueType;

  if (commissionType === EMoneyType.DOLLAR) {
    return commissionAmount;
  }

  return commissionAmount * (purchasePrice ?? 0);
}
