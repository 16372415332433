export enum EModals {
  WELCOME = 'WELCOME',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  CONTACT_DETAILS = 'CONTACT_DETAILS',
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  ADDRESS_INFORMATION = 'ADDRESS_INFORMATION',
  WHAT_NEXT = 'WHAT_NEXT',
  SAVINGS_OR_MORE = 'SAVINGS_OR_MORE',
  CREDIT_CARDS = 'CREDIT_CARDS',
  MOTOR_VEHICLES_ASSETS = 'MOTOR_VEHICLES_ASSETS',
  WORK_HISTORY = 'WORK_HISTORY',
  APPLICATION_SUBMITTED = 'CONGRATULATIONS',
  APPLICATION_IN_PROGRESS = 'APPLICATION_IN_PROGRESS',
  REAL_ESTATES = 'REAL_ESTATES',
  PERSONAL_LOANS = 'PERSONAL_LOANS',
  APPLICATION_SUBMITTED_SOLAR = 'APPLICATION_SUBMITTED_SOLAR',
  RETURN_USER = 'RETURN_USER',
  LENDER_SPECIFIC_QUESTION_ONE = 'LENDER_SPECIFIC_QUESTION_ONE',
  LENDER_SPECIFIC_QUESTION_TWO = 'LENDER_SPECIFIC_QUESTION_TWO',
  LENDER_SPECIFIC_QUESTION_THREE = 'LENDER_SPECIFIC_QUESTION_THREE',
  DEALERSHIP_WELCOME = 'DEALERSHIP_WELCOME',
  SOLAR_RETURN_USER = 'SOLAR_RETURN_USER',
}
