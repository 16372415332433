import { ELoanUsage } from '@/context/borrower';

import { DEFAULT_PARTNER } from './defaultPartner';
import { EPartners, IPartnerParams } from './partnerTypes';

export const ALLBIDS_PARTNER: IPartnerParams = {
  ...DEFAULT_PARTNER,
  id: EPartners.ALLBIDS,
  loanUsage: ELoanUsage.PERSONAL,
  logo: 'carbids1.svg',
  whiteLabel: true,
  hideSelectVehicleScreen: true,
  hideSellerScreen: true,
};
