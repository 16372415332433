import { Input } from '@/components/Input';
import { selectEmployerName, setEmployerName } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'EmployerName';

export function EmployerName({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectEmployerName);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    if (typeof value === 'boolean') return;
    if (value === currentState) return;
    if (value?.length > 12) return;
    dispatch(setEmployerName(value));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Employer Name'
        placeholder='Name of your employer'
        showErrors={showErrors && !currentState}
        onChange={handleChange}
        id={`${baseId}-input-employerName`}
        value={currentState?.toLocaleString() ?? ''}
      />
    </div>
  );
}
