import { useEffect, useState } from 'react';

import { Button, EButtonType } from '@/components/Button';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { selectSelectedLender, setSelectedLender } from '@/context/borrower';
import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { selectLenders } from '@/context/lender';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

import { LenderRepayments } from './LenderRepayments';

export const baseId = 'screen-preApprovalApproved';

export function IndicativelyApproved(): JSX.Element {
  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const selectedLender = useAppSelector(selectSelectedLender);
  const lenders = useAppSelector(selectLenders);

  // ***** Set selectedLender *****
  useEffect(() => {
    if (selectedLender) return;
    if (!lenders) return;

    dispatch(setSelectedLender(lenders[0]));
  }, []);

  // ***** Event Handlers *****
  function handleBackClick(): void {
    const backScreen = getBackScreen();
    dispatch(setDisplay(backScreen));
  }

  function handleNextClick(): void {
    const nextScreen = getNextScreen();
    dispatch(setDisplay(nextScreen));
    if (selectedLender === undefined && lenders) {
      dispatch(setSelectedLender(lenders[0]));
    }
    if (nextScreen === EDisplays.EMPLOYMENT) {
      dispatch(setModal(EModals.WORK_HISTORY));
    }
  }

  function getNextScreen(): EDisplays {
    return EDisplays.EMPLOYMENT;
  }

  function getBackScreen(): EDisplays {
    return EDisplays.OCR_SUMMARY;
    // return EDisplays.VEHICLE;
  }

  function toggleCongrats(): void {
    setCongratsExpanded((congratsExpanded) => !congratsExpanded);
  }

  const [congratsExpanded, setCongratsExpanded] = useState(false);
  return (
    <div data-testid={baseId}>
      <Header />
      <div className='flex '>
        <Button
          onClick={handleBackClick}
          type={EButtonType.SECONDARY_OUTLINE}
          square
        >
          Back
        </Button>
        <Button onClick={handleNextClick} square>
          Next
        </Button>
      </div>
      {/* Congratulations */}
      <div className='py-4 px-4'>
        {/* Congratulations */}
        <div className='flex flex-col text-center shadow-2xl bg-white rounded-2xl pt-4 pb-2'>
          <h3 className='text-primary font-bold text-2xl'>Congratulations</h3>
          <p className='text-secondary text-sm'>
            Your application has been indicatively approved
          </p>
          {congratsExpanded && (
            <p className='text-xs px-4 pt-2'>
              An indicative approval is the first level of approval from your
              loan provider. It means that your application has successfully
              gone through the first screening process where your credit
              history, personal debt and your income has been reviewed.
              <br />
              <br /> As your application moves on to the next phase, the lender
              may require more information before granting formal, or
              &apos;unconditional&apos; approval.
            </p>
          )}
          <div
            className='w-full justify-end flex px-2 items-center group cursor-pointer pt-2'
            onClick={toggleCongrats}
          >
            <p className='group-hover:font-bold text-sm transform delay-75 ease-in-out'>
              {congratsExpanded ? 'Less' : 'More'} details
            </p>
            <div className='h-5 w-5 group-hover:scale-125 transform delay-75 ease-in-out'>
              {congratsExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </div>
          </div>
        </div>
      </div>
      <DisplayHeading
        title='Loan Options'
        infoText='Based on the information you have provided us, here is a list of lenders that you have qualified with.'
      />
      {/* Lender Repayments */}
      <LenderRepayments />
    </div>
  );
}
