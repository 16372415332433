import { Input } from '@/components/Input';
import { selectSavings, setSavings } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'Savings';

export function Savings({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectSavings);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    // MAXIMUM
    const MAXIMUM = 999999999;
    let intValue: number | undefined = getNumber(value, MAXIMUM);
    if (intValue === 0) intValue = undefined;
    if (intValue && intValue > MAXIMUM) return;
    if (intValue === currentState) return;
    dispatch(setSavings(intValue));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Savings'
        placeholder='$1234'
        showErrors={showErrors}
        onChange={handleChange}
        id={`${baseId}-input-purchasePrice`}
        value={currentState?.toLocaleString() ?? ''}
        prefix='$'
      />
    </div>
  );
}
