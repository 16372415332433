import { RadioGroup } from '@/components/RadioGroup';
import { ELoanUsage, selectLoanUsage, setLoanUsage } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

import { VehicleAssetType } from '../VehicleAssetType';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'LoanUsage';

export function LoanUsage({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectLoanUsage);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean | undefined): void {
    dispatch(setLoanUsage(value as ELoanUsage));
  }

  // ***** Render *****
  const options = [
    {
      label: 'Personal Use',
      value: ELoanUsage.PERSONAL,
      id: `${baseId}-button-loanUsage-personal`,
    },
    {
      label: 'Business Use',
      value: ELoanUsage.BUSINESS,
      id: `${baseId}-button-loanUsage-business`,
    },
  ];
  return (
    <div data-testid={testId}>
      <RadioGroup
        title='Personal Or Business Use'
        showErrors={showErrors}
        onChange={handleChange}
        options={options}
        value={currentState}
      />
      {currentState === ELoanUsage.BUSINESS && (
        <VehicleAssetType baseId={baseId} showErrors={showErrors} />
      )}
    </div>
  );
}
