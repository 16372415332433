import { selectLenders } from '@/context/lender';
import { useAppSelector } from '@/context/storeHooks';
import { LenderSortBySelect } from '@/UserInput/LenderSortBySelect';
import { RepaymentSelect } from '@/UserInput/RepaymentSelect';

import { LenderCard } from '../LenderCard';

export const testId = 'LenderRepayments';

export function LenderRepayments(): JSX.Element {
  // ***** Redux *****
  const lenders = useAppSelector(selectLenders);

  function getLowest(): string {
    if (!lenders) return '';

    let lowestLender = '';
    let lowestRepayment: number | null = null;

    lenders.map((lender) => {
      if (!lender.monthlyRepaymentAmount) return;
      if (!lender.lenderName) return;
      if (lowestRepayment === null) {
        lowestRepayment = lender.monthlyRepaymentAmount;
        lowestLender = lender.lenderName;

        return;
      }

      if (lender.monthlyRepaymentAmount < lowestRepayment) {
        lowestLender = lender.lenderName;
        lowestRepayment = lender.monthlyRepaymentAmount;
      }
    });
    return lowestLender;
  }

  // ***** Render *****
  if (lenders === null) return <div />;

  return (
    <div data-testid={testId} className='px-4 py-2'>
      {/* Heading / Toggle */}
      {lenders?.length > 1 && (
        <div className='flex space-x-10 justify-between items-center w-full pb-2'>
          <div className='w-full'>
            <h4 className='font-semibold'>Repayment:</h4>
            <RepaymentSelect />
          </div>
          <div className='w-full'>
            <h4 className='font-semibold'>Sort By:</h4>
            <LenderSortBySelect />
          </div>
        </div>
      )}
      {/* Lender Cards */}
      <div className='flex flex-col space-y-4'>
        {lenders.map((lender) => (
          <LenderCard
            key={lender.lenderName}
            lender={lender}
            lowest={getLowest() === lender.lenderName}
          />
        ))}
      </div>
    </div>
  );
}
