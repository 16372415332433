import { selectPartnerEmail, selectPartnerNumber } from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';
import { EMAIL, PHONE } from '@/utils/constants';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid';

export function ContactBox(): JSX.Element {
  const phoneNumber = useAppSelector(selectPartnerNumber) || PHONE;
  const email = useAppSelector(selectPartnerEmail) || EMAIL;
  return (
    <div className='flex-center pt-4'>
      <div className='border-lg border-2 text-sm bg-white py-2 rounded-xl px-10 text-gray-500 text-center'>
        <h3 className='font-bold pb-4'>
          Have a question? <br />
          Get in touch:
        </h3>
        <a href={`tel:${phoneNumber}`} className='flex-center space-x-2'>
          <PhoneIcon className='h-4 w-4' />
          <p>{phoneNumber}</p>
        </a>
        <a href={`mailto:${email}`} className='flex-center space-x-2'>
          <EnvelopeIcon className='h-4 w-4' />
          <p>{email}</p>
        </a>
      </div>
    </div>
  );
}
