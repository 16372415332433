import { setVehicleUrl } from '@/context/borrower';
import { store } from '@/context/store';

export function setVehicleUrlFromSite(): void {
  const { url: stateUrl } = store.getState().borrowerReducer.vehicle;
  const fullUrl = window.location.href;

  if (fullUrl && stateUrl !== fullUrl) {
    store.dispatch(setVehicleUrl(fullUrl));
  }
}
