import { Input } from '@/components/Input';
import { selectVehicleModel, setVehicleModel } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'ModelManual';

export function ModelManual({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectVehicleModel);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    // MAXIMUM
    const MAXIMUM = 30;
    if (value.toString()?.length > MAXIMUM) return;
    dispatch(setVehicleModel(value.toString()));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Vehicle Model'
        placeholder='Vehicle Model'
        showErrors={showErrors}
        onChange={handleChange}
        id={`${baseId}-input-vehicleModel`}
        value={currentState?.toLocaleString() ?? ''}
      />
    </div>
  );
}
