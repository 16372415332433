import { postUrl } from '@/apiCalls/fetch';
import {
  EAddressOwnershipType,
  EBorrowerCitizenshipStatus,
  EEmploymentStatus,
  EQuickQuoteFinanceType,
} from '@/context/borrower';
import { ILender, setLenders } from '@/context/lender';
import { store } from '@/context/store';
import { EPartners } from '@/partners/partnerTypes';
import { getCommissionAmount } from '@/utils/getCommissionAmount';

import { EQuickQuoteAssetType, IQuickQuoteRequest } from './getQuickQuoteTypes';

export async function getQuickQuote(): Promise<void> {
  console.log('getQuickQuote()');
  const url = '/api/quickQuote2Routes/getQuickQuote';

  // ***** Redux State *****
  const state = store.getState();
  // Store
  const amount = state.borrowerReducer.loan.purchasePrice;
  console.log('amount', amount);
  if (!amount) {
    console.log('amount is required for ME');
    // !Amount is required for ME
    store.dispatch(setLenders([]));
    return;
  }

  console.log('getQuickQuote() continuing after amount check');
  const sellerName = state.borrowerReducer.seller.name as EPartners;
  const residencyStatus =
    state.borrowerReducer.personalDetails?.citizenshipStatus ??
    EBorrowerCitizenshipStatus.AUSTRALIAN_CITIZEN;
  const employmentStatus =
    state.borrowerReducer.employments?.employments[0]?.status ??
    EEmploymentStatus.PERMANENT;
  // const vedaEquifaxScore
  // const comprehensiveEquifaxScore
  // const brokerFee
  //   const assetType = state.borrowerReducer.vehicle.assetType;
  const assetType = EQuickQuoteAssetType.MOTOR_VEHICLES_SEC;
  //   const financeType = state.borrowerReducer.loan.loanUsage;
  const financeType = EQuickQuoteFinanceType.CONSUMER_SECURED;
  const fixedTerm = state.borrowerReducer.loan.loanTerm;
  const glassGuideValuation =
    state.borrowerReducer.vehicle.glassValuation ?? amount;
  const deposit = state.borrowerReducer.loan.deposit ?? 0;
  const buildYear = state.borrowerReducer.vehicle.buildYear;
  const assetBalloon = state.borrowerReducer.loan.balloon ?? 0;
  // const repaymentsPeriod = state.borrowerReducer.loan.;
  const ownershipType =
    state.borrowerReducer.address.address[0].ownershipType ??
    EAddressOwnershipType.OWNER;
  // const creditHistory = state.borrowerReducer.;
  // const saleType = state.borrowerReducer.;
  // const financeFees = state.borrowerReducer.;
  // const commissionAmount = state.borrowerReducer.;
  // const tradeInQuote = state.borrowerReducer.;
  const amountPaidInBankAccountMonthly =
    state.borrowerReducer.income.paidAmount ?? 4000;
  const waterfallLenders = state.displayReducer.waterfallLenders;

  const commissionAmount = getCommissionAmount();

  const body: IQuickQuoteRequest = {
    amount,
    partner: sellerName,
    residencyStatus,
    employmentStatus,
    assetType,
    financeType,
    fixedTerm,
    deposit,
    buildYear,
    assetBalloon,
    ownershipType,
    glassGuideValuation,
    amountPaidInBankAccountMonthly,
    waterfallLenders,
    commissionAmount,
  };
  console.log('getQuickQuote sending ');
  const response = await postUrl<ILender[]>(url, body);

  store.dispatch(setLenders(response));

  console.log('getQuickQuote() end');

  return;
}
