import { ELoanUsage } from '@/context/borrower';

import { DEFAULT_PARTNER } from './defaultPartner';
import { EPartners, IPartnerParams } from './partnerTypes';

export const CARSALES_PARTNER: IPartnerParams = {
  ...DEFAULT_PARTNER,
  id: EPartners.CARSALES,
  loanUsage: ELoanUsage.PERSONAL,
  dealerName: 'Carsales',
};
