import { ELoanUsage } from '@/context/borrower';

import { DEFAULT_PARTNER } from './defaultPartner';
import { EPartners, IPartnerParams } from './partnerTypes';

export const BEEPZ_AUTO_SOLUTIONS_PARTNER: IPartnerParams = {
  ...DEFAULT_PARTNER,
  id: EPartners.BEEPZ_AUTO_SOLUTIONS,
  loanUsage: ELoanUsage.PERSONAL,
  dealerName: 'Beepz Auto Solutions',
};
