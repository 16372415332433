import { getBorrower } from '@/apiCalls/borrower';
import { Button, EButtonType } from '@/components/Button';
import { selectFirstName } from '@/context/borrower';
import { setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export const testId = 'SolarReturnUser';

export function SolarReturnUser(): JSX.Element {
  // ***** Constants *****
  const baseId = 'modal-SolarReturnUser';

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const firstName = useAppSelector(selectFirstName);

  // ***** Handlers *****
  function handleCloseClick(): void {
    getBorrower();
    dispatch(setModal(null));
  }

  // ***** Render *****
  return (
    <div data-testid={testId} className='flex flex-col justify-between  py-2'>
      <div className='flex-center w-full'>
        <img
          src='https://driveiq-assets.s3.ap-southeast-2.amazonaws.com/vroom/finger-print-circle.gif'
          className='h-40 '
        />
      </div>
      <p className='font-bold text-2xl text-center pb-2 text-primary'>
        Welcome, {firstName}
      </p>
      <p className='font-bold text-center px-14'>
        We&apos;ve saved the details you shared last time
      </p>

      <div className='flex-center mx-16 mt-4'>
        <Button onClick={handleCloseClick} type={EButtonType.SECONDARY_OUTLINE}>
          Continue
        </Button>
      </div>
    </div>
  );
}
