import { useState } from 'react';

import { Button } from '@/components/Button';
import { ModalHeader } from '@/components/ModalHeader';
import {
  selectEmail,
  selectFirstName,
  selectLastName,
} from '@/context/borrower';
import { setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { Email } from '@/UserInput/Email';
import { FirstName } from '@/UserInput/FirstName';
import { LastName } from '@/UserInput/LastName';
import { isEmail } from '@/utils/isEmail';

export const testId = 'ContactDetails';

export function ContactDetails(): JSX.Element {
  // ***** Constants *****
  const baseId = 'modal-ContactDetails';
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState<boolean>(false);
  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const firstName = useAppSelector(selectFirstName);
  const lastName = useAppSelector(selectLastName);
  const email = useAppSelector(selectEmail);

  // ***** Handlers *****
  function handleClick(): void {
    setShowErrors(true);
    if (!isComplete()) return;
    dispatch(setModal(null));
  }

  // ***** Helpers *****
  function isComplete(): boolean {
    if (firstName === null || firstName === undefined) return false;
    if (lastName === null || lastName === undefined) return false;

    if (!isEmail(email)) return false;
    return true;
  }

  // ***** Render *****
  return (
    <div data-testid={testId} className='flex flex-col justify-between  py-2'>
      <ModalHeader title='Contact Details' baseId={baseId} />
      <FirstName baseId={baseId} showErrors={showErrors} />
      <LastName baseId={baseId} showErrors={showErrors} />
      {/* <MobileNumber baseId={baseId} showErrors={showErrors} /> */}
      <Email baseId={baseId} showErrors={showErrors} />
      <div className='py-2 mx-16'>
        <Button onClick={handleClick} id={`${baseId}-button-close`}>
          Close
        </Button>
      </div>
    </div>
  );
}
