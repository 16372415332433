import { PayloadAction } from '@reduxjs/toolkit';

import { EApplicationAssetType, ELoanUsage } from '../types';

export const loanReducers = {
  setLoanUsage: (state, action: PayloadAction<ELoanUsage>): void => {
    state.loan.loanUsage = action.payload;
  },
  setPurchasePrice: (
    state,
    action: PayloadAction<number | undefined>
  ): void => {
    state.loan.purchasePrice = action.payload;
  },
  setTradeIn: (state, action: PayloadAction<number | undefined>): void => {
    state.loan.tradeIn = action.payload;
  },
  setDeposit: (state, action: PayloadAction<number | undefined>): void => {
    state.loan.deposit = action.payload;
  },
  setBalloon: (state, action: PayloadAction<number | undefined>): void => {
    state.loan.balloon = action.payload;
  },
  setLoanTerm: (state, action: PayloadAction<number>): void => {
    state.loan.loanTerm = action.payload;
  },
  setLoanAssetType: (
    state,
    action: PayloadAction<EApplicationAssetType>
  ): void => {
    state.loan.assetType = action.payload;
  },
};
