import { useSelector } from 'react-redux';

import { Input } from '@/components/Input';
import {
  ICreditCard,
  selectCreditCardActiveIndex,
  selectCreditCardByIndex,
  setCreditCardByIndex,
} from '@/context/borrower';
// eslint-disable-next-line no-restricted-imports
// eslint-disable-next-line no-restricted-imports
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
  setShowErrors: (value: boolean) => void;
}

export const testId = 'CreditCardLenderName';

export function CreditCardLenderName({
  baseId,
  showErrors,
  setShowErrors,
}: IProps): JSX.Element {
  // ***** Redux *****
  const activeCardIndex = useAppSelector(selectCreditCardActiveIndex);
  const creditCard = useSelector((state: RootState) =>
    selectCreditCardByIndex(state, activeCardIndex)
  );
  const lendername = creditCard?.lenderName;
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    setShowErrors(false);
    if (typeof value !== 'string') return;
    if (value === '') {
      const newCreditCard: ICreditCard = {
        ...creditCard,
        lenderName: undefined,
      };

      dispatch(
        setCreditCardByIndex({
          index: activeCardIndex,
          creditCard: newCreditCard,
        })
      );
      return;
    }
    const newCreditCard: ICreditCard = {
      ...creditCard,
      lenderName: value,
    };

    dispatch(
      setCreditCardByIndex({
        index: activeCardIndex,
        creditCard: newCreditCard,
      })
    );
  }

  // ** helpers **

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Lender name'
        placeholder='ANZ'
        showErrors={showErrors && !lendername}
        onChange={handleChange}
        id={`${baseId}-input-creditCardLenderName`}
        value={lendername?.toLocaleString() ?? ''}
      />
    </div>
  );
}
