import { setVehicleNvic } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialVehicleNvic(nvic: IParameter): void {
  if (!nvic) {
    return;
  }
  const stateNvic = store.getState().borrowerReducer.vehicle.nvic;
  if (stateNvic !== nvic) {
    store.dispatch(setVehicleNvic(nvic));
  }
}
