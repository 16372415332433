import { useSelector } from 'react-redux';

import { Input } from '@/components/Input';
import {
  IMotorVehicle,
  selectMotorVehicleActiveIndex,
  selectMotorVehiclebyIndex,
  setMotorVehicleByIndex,
} from '@/context/borrower';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
  setShowErrors: (value: boolean) => void;
}

export const testId = 'MotorVehicleLoanLenderName';

export function MotorVehicleLoanLenderName({
  baseId,
  showErrors,
  setShowErrors,
}: IProps): JSX.Element {
  // ***** Redux *****
  const activeMotorVehicelIndex = useAppSelector(selectMotorVehicleActiveIndex);
  const motorVehicle = useSelector((state: RootState) =>
    selectMotorVehiclebyIndex(state, activeMotorVehicelIndex)
  );
  const lendername = motorVehicle?.lenderName;
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    setShowErrors(false);
    if (typeof value !== 'string') return;
    const newMotorVehicle: IMotorVehicle = {
      ...motorVehicle,
      lenderName: value,
    };
    dispatch(
      setMotorVehicleByIndex({
        index: activeMotorVehicelIndex,
        motorVehicle: newMotorVehicle,
      })
    );
  }
  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Lender name'
        placeholder='NAB'
        showErrors={showErrors && !lendername}
        onChange={handleChange}
        id={`${baseId}-input-motorVehicleLenderName`}
        value={lendername?.toLocaleString() ?? ''}
      />
    </div>
  );
}
