import { useState } from 'react';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { DisplayHeading } from '@/components/DisplayHeading';
import { DocumentUpload } from '@/components/DocumentUpload';
import { Header } from '@/components/Header';
import { Loading } from '@/components/Loading';
import { SolarNavigationButtons } from '@/components/SolarNavigationButtons';
import { SolarProgressBar } from '@/components/SolarProgressBar';
import {
  ESupportingDocCodes,
  selectSelectedLender,
  selectSupportingDocs,
  setProgressPercent,
} from '@/context/borrower';
import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { UploadBankStatements } from '@/UserInput/UploadBankStatements';

import { SolarLenderCard } from '../SolarLenderCard';

export const baseId = 'screen-SolarSupportingDocs';

export function SolarSupportingDocs(): JSX.Element {
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const supportingDocs = useAppSelector(selectSupportingDocs);
  const selectedLender = useAppSelector(selectSelectedLender);
  const requiredDocs = selectedLender?.requiredDocuments;

  if (!requiredDocs) {
    return <div>Required Docs is null</div>;
  }

  // ***** Event Handlers *****

  // ***** Helpers ******
  function isComplete(): boolean {
    if (!requiredDocs) {
      return true;
    }

    for (const requiredDocCode of Object.keys(requiredDocs)) {
      if (
        !(requiredDocCode in supportingDocs) ||
        supportingDocs[requiredDocCode]?.length === 0
      ) {
        return false;
      }
    }

    return true;
  }

  function handleBackClick(): void {
    dispatch(setDisplay(EDisplays.SOLAR_LENDER_SPECIFIC_QUESTIONS));
  }

  function handleNextClick(): void {
    setShowErrors(true);
    if (!isComplete()) return;
    dispatch(setProgressPercent(100));
    createOrUpdateBorrower();
    dispatch(setModal(EModals.APPLICATION_SUBMITTED_SOLAR));
  }

  // ***** Render *****

  return loading ? (
    <Loading />
  ) : (
    <>
      <div data-testid={baseId} className='px-4 h-screen-92'>
        <Header />
        <SolarProgressBar />

        <div className='m-2'>
          <SolarLenderCard lender={selectedLender} />
        </div>

        <div className='px-4'>
          <DisplayHeading
            title='Supporting Documents'
            infoText='These are all the documents that the lender requires in order to proceed with the loan. They are mandatory to complete a full application.'
          />
          <p className='uppercase text-center text-xs font-bold'></p>
          {Object.entries(requiredDocs).map(([key, value]) => {
            if (
              key === ESupportingDocCodes.BANK_STATEMENTS_12M ||
              key === ESupportingDocCodes.BANK_STATEMENTS_3M ||
              key === ESupportingDocCodes.BANK_STATEMENTS_6M
            ) {
              return <UploadBankStatements key={key} code={key} />;
            } else {
              return (
                <DocumentUpload
                  key={key}
                  baseId={baseId}
                  showErrors={showErrors && !supportingDocs[key]}
                  code={key}
                  document={supportingDocs[key]}
                  value={value}
                />
              );
            }
          })}
        </div>
      </div>

      <SolarNavigationButtons
        handleBackClick={handleBackClick}
        handleNextClick={handleNextClick}
        hasPrevious={false}
      />
    </>
  );
}
