import { PayloadAction } from '@reduxjs/toolkit';

import { ISupportingDoc } from '../types';

export const supportingDocumentsReducers = {
  setSupportingDocs: (
    state,
    action: PayloadAction<{ code: string; document: ISupportingDoc[] }>
  ): void => {
    // !FIXME
    state.supportingDocs[action.payload.code] = action.payload.document;
  },
};
