import { EModals, selectModal } from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';

import { LenderSpecificQuestionOne } from './Modals/LenderSpecificQuestionOne';
import { LenderSpecificQuestionThree } from './Modals/LenderSpecificQuestionThree';
import { LenderSpecificQuestionTwo } from './Modals/LenderSpecificQuestionTwo';
import { SolarReturnUser } from './Modals/SolarReturnUser';
import {
  AddressInformation,
  ApplicationInProgress,
  ApplicationSubmitted,
  ContactDetails,
  CreditCards,
  DealershipWelcome,
  Login,
  MotorVehiclesAsset,
  OtherAssetsSavingsDetails,
  PersonalInformation,
  PersonalLoans,
  RealEstateAsset,
  Register,
  ReturnUser,
  SolarApplicationSubmitted,
  Welcome,
  WhatNext,
  WorkHistory,
} from './Modals';

export function ModalScreen(): JSX.Element {
  const modal = useAppSelector(selectModal);

  switch (modal) {
    case EModals.WELCOME:
      return <Welcome />;
    case EModals.LOGIN:
      return <Login />;
    case EModals.REGISTER:
      return <Register />;
    case EModals.CONTACT_DETAILS:
      return <ContactDetails />;
    case EModals.PERSONAL_INFORMATION:
      return <PersonalInformation />;
    case EModals.ADDRESS_INFORMATION:
      return <AddressInformation />;
    case EModals.WHAT_NEXT:
      return <WhatNext />;
    case EModals.SAVINGS_OR_MORE:
      return <OtherAssetsSavingsDetails />;
    case EModals.CREDIT_CARDS:
      return <CreditCards />;
    case EModals.PERSONAL_LOANS:
      return <PersonalLoans />;
    case EModals.MOTOR_VEHICLES_ASSETS:
      return <MotorVehiclesAsset />;
    case EModals.WORK_HISTORY:
      return <WorkHistory />;
    case EModals.APPLICATION_SUBMITTED:
      return <ApplicationSubmitted />;
    case EModals.APPLICATION_IN_PROGRESS:
      return <ApplicationInProgress />;
    case EModals.REAL_ESTATES:
      return <RealEstateAsset />;
    case EModals.APPLICATION_SUBMITTED_SOLAR:
      return <SolarApplicationSubmitted />;
    case EModals.RETURN_USER:
      return <ReturnUser />;
    case EModals.SOLAR_RETURN_USER:
      return <SolarReturnUser />;
    case EModals.LENDER_SPECIFIC_QUESTION_ONE:
      return <LenderSpecificQuestionOne />;
    case EModals.LENDER_SPECIFIC_QUESTION_TWO:
      return <LenderSpecificQuestionTwo />;
    case EModals.LENDER_SPECIFIC_QUESTION_THREE:
      return <LenderSpecificQuestionThree />;
    case EModals.DEALERSHIP_WELCOME:
      return <DealershipWelcome />;

    case null:
    default:
      return <div>Modal</div>;
  }
}
