import { DateInput } from '@/components/DateInput';
import { selectLicenseExpiry, setLicenseExpiry } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'LicenseExpiry';

export const LicenseExpiry = ({ showErrors, baseId }: IProps): JSX.Element => {
  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const licenseExpiry = useAppSelector(selectLicenseExpiry);

  // *****Functions*****

  const handlOnChange = (date: Date): void => {
    dispatch(setLicenseExpiry(date));
  };

  // *****Render*****
  return (
    <DateInput
      title='License Expiry Date'
      placeholder='License Expiry Date'
      onChange={handlOnChange}
      value={licenseExpiry}
      showErrors={showErrors}
    />
  );
};
