import { store } from '@/context/store';

import { lookupNvic } from '../lookupNvic';
import { lookupVin } from '../lookupVin';

export async function lookupVehicle(): Promise<void> {
  const { vin, nvic } = store.getState().borrowerReducer.vehicle;

  if (!vin && !nvic) return;

  // We prefer to use NVIC over VIN
  if (nvic) {
    await lookupNvic();
    const { glassValuation } = store.getState().borrowerReducer.vehicle;

    // If glassValuation is set, then we have a valid vehicle and VIN is not required
    if (glassValuation) {
      return;
    }
  }

  await lookupVin();
  return;
}
