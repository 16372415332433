import moment from 'moment';

export function getStringFromState(
  text: string | null | undefined | number | Date
): string {
  if (text === null || text === undefined) {
    return '___';
  }

  if (text instanceof Date) {
    const date = moment(text);
    return date.format('D/M/YYYY');
  }

  return text.toString();
}
