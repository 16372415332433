import { Button, EButtonType } from '@/components/Button';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import { RemoteImage } from '@/components/RemoteImage';
import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { selectIsLoggedIn } from '@/context/user';

export const baseId = 'ServicingSuccess';

export function ServicingSuccess(): JSX.Element {
  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  // ***** Event Handlers *****
  function handleBackClick(): void {
    const backScreen = getBackScreen();
    dispatch(setDisplay(backScreen));
  }

  function handleNextClick(): void {
    const nextModal = getNextModal();
    const nextDisplay = getNextDisplay();

    if (nextModal) {
      dispatch(setModal(nextModal));
    }
    if (nextDisplay) {
      dispatch(setDisplay(nextDisplay));
    }
  }

  // ***** Helpers ******
  function getBackScreen(): EDisplays {
    return EDisplays.EXPENSE;
  }

  function getNextModal(): EModals | void {
    if (isLoggedIn) return;
    return EModals.REGISTER;
  }

  function getNextDisplay(): EDisplays | void {
    if (isLoggedIn) return EDisplays.IDENTIFICATION;
    return;
  }

  return (
    <div data-testid={baseId}>
      <Header />
      <ProgressBar />
      <div className='flex '>
        <Button
          onClick={handleBackClick}
          type={EButtonType.SECONDARY_OUTLINE}
          square
        >
          Back
        </Button>
        <Button onClick={handleNextClick} square>
          Next
        </Button>
      </div>
      <div className='flex-center pt-20 pb-10 relative'>
        <div className='bg-primary h-40 w-40 rounded-full' />
        <RemoteImage fileName='applause.svg' className='absolute' />
      </div>
      <div className='text-center flex flex-col space-y-2'>
        <h3 className='uppercase text-xl text-primary font-bold '>
          Great News!
          <br />
          What Next?
        </h3>
        <p className='text-secondary '>
          We&apos;ve found some suitable <br />
          lenders for your loan
        </p>
        <p className='text-sm'>
          Keep going to see your rates, choose
          <br />
          your lender and even complete a full <br /> application.
        </p>
      </div>
    </div>
  );
}
