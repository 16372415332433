import { IScores, setScores } from '@/context/borrower';
import { store } from '@/context/store';

import { getUrl } from '../fetch';

import {
  EPreApprovalVerdicts,
  IPreapprovalResponse,
} from './fetchPreapprovalVerdict.types';

export async function fetchPreapprovalVerdict(): Promise<EPreApprovalVerdicts> {
  // ***** Send the Request *****
  const url = '/api/submit/preApproval';

  const response = await getUrl<IPreapprovalResponse>(url);

  // ***** Dispatch response to state *****
  const state = store.getState();
  const stateScores = state.borrowerReducer.scores;

  // response returns number | null but FE saves number | undefined
  const scores: IScores = {
    onePointOneScore: response.data.onePointOneScore
      ? response.data.onePointOneScore
      : undefined,
    comprehensiveScore: response.data.comprehensiveScore
      ? response.data.comprehensiveScore
      : undefined,
    creditScore: response.data.creditScore
      ? response.data.creditScore
      : undefined,
    creditScorePercentage: response.data.creditScorePercentage,
    eligibilityScorePercentage: response.data.eligibilityScorePercentage,
    accuracyScorePercentage: response.data.accuracyScorePercentage,
  };

  store.dispatch(
    setScores({
      ...stateScores, // other scores that aren't returned by this API
      ...scores,
    })
  );

  return response.data.verdict;
}
