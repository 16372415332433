import { getBorrower } from '@/apiCalls/borrower';
import { Button, EButtonType } from '@/components/Button';
import { selectFirstName, selectSellerName } from '@/context/borrower';
import {
  selectAgreedToTermsAndConditions,
  selectLogo,
  setModal,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { TermsAndConditions } from '@/UserInput/TermsAndConditions';

export const testId = 'dealershipWelcome';

export function DealershipWelcome(): JSX.Element {
  // ***** Constants *****
  const baseId = 'modal-dealershipWelcome';

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const firstName = useAppSelector(selectFirstName);
  const sellerName = useAppSelector(selectSellerName);
  const agreedToTermsAndConditions = useAppSelector(
    selectAgreedToTermsAndConditions
  );
  const logo = useAppSelector(selectLogo);

  // ***** Handlers *****
  function handleCloseClick(): void {
    getBorrower();
    dispatch(setModal(null));
  }

  // ***** Render *****
  return (
    <div data-testid={testId} className='flex flex-col justify-between  py-2'>
      <div className='flex-center w-full'>
        <img
          src={
            logo
              ? logo
              : 'https://driveiq-assets.s3.ap-southeast-2.amazonaws.com/vroom/finger-print-circle.gif'
          }
          className='h-40 '
        />
      </div>
      <p className='font-bold text-2xl text-center pb-2 text-primary'>
        Hey, {firstName}
      </p>
      <p className='text-center text-xl'>Welcome to your finance journey!</p>
      <div className='text-center px-14 py-4'>
        <p className='text-xl'>You&apos;ve been sent here by</p>
        <p className='text-xl font-bold'>{sellerName}</p>
      </div>
      <div className='px-14 flex-center w-full pb-4'>
        <TermsAndConditions />
      </div>
      <div className='flex-center mx-16'>
        <Button
          onClick={handleCloseClick}
          type={
            agreedToTermsAndConditions
              ? EButtonType.SECONDARY_OUTLINE
              : EButtonType.DISABLED
          }
        >
          Continue
        </Button>
      </div>
    </div>
  );
}
