import { useSelector } from 'react-redux';

import { Input } from '@/components/Input';
import {
  IMotorVehicle,
  selectMotorVehicleActiveIndex,
  selectMotorVehiclebyIndex,
  setMotorVehicleByIndex,
} from '@/context/borrower';
// eslint-disable-next-line no-restricted-imports
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
  baseId: string;
  showErrors: boolean;
  setShowErrors: (value: boolean) => void;
}

export const testId = 'MotorVehicleMonthlyRepayment';

export function MotorVehicleMonthlyRepayment({
  baseId,
  showErrors,
  setShowErrors,
}: IProps): JSX.Element {
  // ***** Redux *****
  const activeMotorVehicelIndex = useAppSelector(selectMotorVehicleActiveIndex);
  const motorVehicle = useSelector((state: RootState) =>
    selectMotorVehiclebyIndex(state, activeMotorVehicelIndex)
  );
  const monthlyRepayment = motorVehicle?.monthlyRepayment;
  const dispatch = useAppDispatch();
  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    setShowErrors(false);
    if (motorVehicle === null) return;
    if (typeof value !== 'string') return;
    const MAXIMUM = 999999;
    let intValue: number | undefined = getNumber(value, MAXIMUM);
    if (intValue === 0) intValue = undefined;
    if (intValue && intValue > 9999999) return;
    if (motorVehicle === null) return;

    const newMotorVehicle: IMotorVehicle = {
      ...motorVehicle,
      monthlyRepayment: intValue,
    };
    if (intValue && isNaN(intValue)) {
      newMotorVehicle.monthlyRepayment = undefined;
    }
    dispatch(
      setMotorVehicleByIndex({
        index: activeMotorVehicelIndex,
        motorVehicle: newMotorVehicle,
      })
    );
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Monthly Repayment'
        placeholder='$2000'
        showErrors={showErrors && !monthlyRepayment}
        onChange={handleChange}
        id={`${baseId}-input-monthlyRepayment`}
        value={monthlyRepayment?.toLocaleString() ?? ''}
        prefix='$'
      />
    </div>
  );
}
