import { useState } from 'react';
import { useSelector } from 'react-redux';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button, EButtonType } from '@/components/Button';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import {
  EEmploymentStatus,
  selectEmploymentStatusByIndex,
  selectOtherIncome,
  selectOtherIncomeSource,
  selectPaidAmount,
  selectPaidFrequency,
} from '@/context/borrower';
import { EDisplays, setDisplay } from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { AmountPaid } from '@/UserInput/AmountPaid';
import { EmploymentStatus } from '@/UserInput/EmploymentStatus';
import { OtherIncome } from '@/UserInput/OtherIncome';
import { PaidFrequency } from '@/UserInput/PaidFrequency';

export const baseId = 'screen-income';

function Income(): JSX.Element {
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState(false);

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const employment = useSelector((state: RootState) =>
    selectEmploymentStatusByIndex(state, 0)
  );
  const employmentStatus = employment?.status;
  const paidFrequency = useAppSelector(selectPaidFrequency);
  const amountPaid = useAppSelector(selectPaidAmount);
  const otherIncome = useAppSelector(selectOtherIncome);
  const sourceOfOtherIncome = useAppSelector(selectOtherIncomeSource);

  // ***** Event Handlers *****
  function handleBackClick(): void {
    const backScreen = getBackScreen();
    dispatch(setDisplay(backScreen));
  }

  function handleNextClick(): void {
    setShowErrors(true);
    if (!isComplete()) return;
    createOrUpdateBorrower();
    const nextScreen = getNextScreen();
    dispatch(setDisplay(nextScreen));
  }

  // ***** Helpers ******
  function isComplete(): boolean {
    if (!employmentStatus) return false;
    if (
      employmentStatus === EEmploymentStatus.CASUAL &&
      employment.years === undefined &&
      employment.months === undefined
    )
      return false;
    if (paidFrequency === undefined) return false;
    if (amountPaid === undefined) return false;
    if (
      otherIncome !== undefined &&
      otherIncome !== 0 &&
      sourceOfOtherIncome === undefined
    )
      return false;
    return true;
  }

  function getNextScreen(): EDisplays {
    return EDisplays.EXPENSE;
  }

  function getBackScreen(): EDisplays {
    return EDisplays.BASIC;
  }
  return (
    <div data-testid={baseId}>
      <Header />
      <ProgressBar />
      <div className='flex '>
        <Button
          onClick={handleBackClick}
          type={EButtonType.SECONDARY_OUTLINE}
          square
        >
          Back
        </Button>
        <Button onClick={handleNextClick} square>
          Next
        </Button>
      </div>
      <div className='px-4'>
        <DisplayHeading
          title='Income'
          infoText='This section looks at your source of income and employment'
        />
        <EmploymentStatus showErrors={showErrors} baseId='screen-income' />
        <PaidFrequency showErrors={showErrors} baseId='screen-income' />
        <AmountPaid showErrors={showErrors} baseId='screen-income' />
        <OtherIncome showErrors={showErrors} baseId='screen-income' />
      </div>
    </div>
  );
}

export { Income };
