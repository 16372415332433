import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@/components/Button';
import { ModalHeader } from '@/components/ModalHeader';
import { selectAddressByIndex } from '@/context/borrower';
import { setModal } from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch } from '@/context/storeHooks';
import { LivingStatus } from '@/UserInput';
import { AddressState } from '@/UserInput/AddressState';
import { PostCode } from '@/UserInput/PostCode';
import { StreetName } from '@/UserInput/StreetName';
import { StreetNumber } from '@/UserInput/StreetNumber';
import { SuburbName } from '@/UserInput/SuburbName';
import { UnitNumber } from '@/UserInput/UnitNumber';

export const testId = 'AddressInformation';

export function AddressInformation(): JSX.Element {
  // ***** Constants *****
  const baseId = 'modal-ContactDetails';
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState<boolean>(false);
  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const address = useSelector((state: RootState) =>
    selectAddressByIndex(state, 0)
  );
  const { ownershipType, streetNumber, streetName, suburb, state, postcode } =
    address;

  // ***** Handlers *****
  function handleClick(): void {
    console.log('click');
    setShowErrors(true);
    if (!isComplete()) return;
    console.log('complete');
    dispatch(setModal(null));
  }

  // ***** Helpers *****
  function isComplete(): boolean {
    if (ownershipType === null) return false;
    if (ownershipType === undefined) return false;

    if (streetNumber === null) return false;
    if (streetNumber === undefined) return false;

    if (streetName === null) return false;
    if (streetName === undefined) return false;

    if (suburb === null) return false;
    if (suburb === undefined) return false;

    if (state === null) return false;
    if (state === undefined) return false;

    if (postcode === null) return false;
    if (postcode === undefined) return false;
    if (postcode.toString()?.length !== 4) return false;

    return true;
  }

  // ***** Render *****
  return (
    <div
      data-testid={testId}
      className='flex flex-col justify-between py-2'
    >
      <ModalHeader title='Address Details' baseId={baseId} />
      <LivingStatus baseId={baseId} showErrors={showErrors} />
      <div className='flex-center space-x-2'>
        <UnitNumber baseId={baseId} showErrors={false} />
        <StreetNumber baseId={baseId} showErrors={showErrors} />
      </div>
      <div className='flex-center space-x-2'>
        <StreetName baseId={baseId} showErrors={showErrors} />
        <SuburbName baseId={baseId} showErrors={showErrors} />
      </div>
      <div className='flex-center space-x-2'>
        <AddressState baseId={baseId} showErrors={showErrors} />
        <PostCode baseId={baseId} showErrors={showErrors} />
      </div>
      <div className='py-2 mx-16'>
        <Button onClick={handleClick} id={`${baseId}-button-close`}>
          Close
        </Button>
      </div>
    </div>
  );
}
