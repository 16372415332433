import { setWaterfallLenders } from '@/context/display';
import { store } from '@/context/store';

export function setInitialWaterfallLenders(
  partnerWaterfallLenders: string[][]
): void {
  const currentWaterfallLenders =
    store.getState().displayReducer.waterfallLenders;
  if (partnerWaterfallLenders !== currentWaterfallLenders) {
    store.dispatch(setWaterfallLenders(partnerWaterfallLenders));
  }
}
