import { useState } from 'react';

import { Button } from '@/components/Button';
import { ModalHeader } from '@/components/ModalHeader';
import { selectAnnualRevenue } from '@/context/borrower';
import { setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { AnnualRevenue } from '@/UserInput/AnnualRevenue';

export const testId = 'LenderSpecificQuestionOne';

export const LenderSpecificQuestionOne = (): JSX.Element => {
  // *** Local State ***
  const [showErrors, setShowErrors] = useState(false);

  // ***** Constants *****
  const baseId = 'modal-LenderSpecificQuestionOne';

  // ***** Redux *****

  const dispatch = useAppDispatch();
  const annualRevenue = useAppSelector(selectAnnualRevenue);
  // *** helpers ***

  const handleClick = (): void => {
    setShowErrors(true);

    if (!isComplete()) {
      return;
    }
    dispatch(setModal(null));
  };

  const isComplete = (): boolean => {
    if (annualRevenue === undefined) return false;
    if (annualRevenue === null) return false;
    return true;
  };

  return (
    <div data-testid={testId} className='flex flex-col justify-between  py-2'>
      <ModalHeader title='Business Annual Revenue' baseId={baseId} />
      <AnnualRevenue showErrors={showErrors} baseId={baseId} />
      <div className='py-2 mx-16'>
        <Button onClick={handleClick} id={`${baseId}-button-close`}>
          Save
        </Button>
      </div>
    </div>
  );
};
