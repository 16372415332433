import { RootState } from '@/context/store';

import { EDisplays, EModals } from '../types';

export const selectDisplay = (state: RootState): EDisplays =>
  state.displayReducer.display;
export const selectModal = (state: RootState): EModals | null =>
  state.displayReducer.modal;
export const selectIsLoaded = (state: RootState): boolean =>
  state.displayReducer.isLoaded;
