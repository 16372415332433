import { ELoanUsage } from '@/context/borrower';

import { DEFAULT_PARTNER } from './defaultPartner';
import { EPartners, IPartnerParams } from './partnerTypes';

export const MYMOTO_PARTNER: IPartnerParams = {
  ...DEFAULT_PARTNER,
  id: EPartners.MYMOTO,
  loanUsage: ELoanUsage.PERSONAL,
  dealerName: 'MYMOTO',
  primaryColor: '#008077',
  secondaryColor: '#1376ff',
  tertiaryColor: '#ebf3ff',
  quaternaryColor: 'f1f3f4',
};
