import { useEffect } from 'react';

import { Loading } from '@/components/Loading';
import { selectSellerName } from '@/context/borrower';
import {
  EDisplays,
  selectDisplay,
  selectInitialLoad,
  selectIsLoaded,
  selectModal,
} from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';
import { SOLAR_DEALER_NAME } from '@/partners/solar';
import { setParametersFromParams } from '@/utils/setParametersFromParams';

import { DisplayScreen } from '../DisplayScreen';
import { ModalScreen } from '../ModalScreen';

function Vroom(): JSX.Element {
  // ***** Redux *****
  const isLoaded = useAppSelector(selectIsLoaded) ?? false;
  const initialLoad = useAppSelector(selectInitialLoad) ?? false;

  // ***** Helper Functions *****
  if (!initialLoad) {
    setParametersFromParams();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [isLoaded]);

  // ***** Render *****
  if (!isLoaded) return <Loading />;
  return <RealApp />;
}

export { Vroom };

function RealApp(): JSX.Element {
  const modal = useAppSelector(selectModal);
  const display = useAppSelector(selectDisplay);
  const sellerName = useAppSelector(selectSellerName);
  return (
    <div data-testid='' className='w-full bg-white h-fit relative'>
      <div
        className={`mx-auto w-full max-w-md min-h-md bg-gray-100  pb-4 ${
          sellerName === SOLAR_DEALER_NAME &&
          (display === EDisplays.OCR_ANIMATION ||
            display === EDisplays.SOLAR_HOLDING_SCREEN) &&
          'bg-yellow-500'
        }  min-h-screen`}
      >
        <DisplayScreen />
      </div>
      {modal !== null && (
        <div className='w-full z-50 absolute -top-20 left-0 flex-center h-full'>
          <div className='mx-auto max-w-md w-full max-h-[90%]'>
            <div className='mx-auto w-[90%] bg-white p-4  rounded-xl shadow-2xl'>
              <ModalScreen />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
