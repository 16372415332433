export interface IPreapprovalResponse extends Response {
  data: {
    verdict: EPreApprovalVerdicts; // Determine the verdict screen
    creditScore: number | null; // Not saved
    creditScorePercentage: number; // save to FE -> for YOUR_SCORES screen
    eligibilityScorePercentage: number; // save to FE -> for YOUR_SCORES screen
    accuracyScorePercentage: number; // save to FE -> for YOUR_SCORES screen
    onePointOneScore: number | null; // save to FE -> For ME calls
    comprehensiveScore: number | null; // save to FE -> For ME calls
    oneScore: number | null; // Not saved
  };
  verdict: EPreApprovalVerdicts;
}

export enum EPreApprovalVerdicts {
  APPROVED = 'APPROVED',
  REFERRED = 'REFERRED',
  DECLINED = 'DECLINED',
}
