import { Input } from '@/components/Input';
import {
  selectVehicleKilometres,
  setVehicleKilometres,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'Kilometers';

export function Kilometers({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectVehicleKilometres);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    // MAXIMUM
    const MAXIMUM = 999999;
    const intValue = getNumber(value, MAXIMUM);
    if (intValue === currentState) return;
    dispatch(setVehicleKilometres(intValue));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Vehicle Kilometers'
        placeholder='Enter the number of Kilometers'
        showErrors={showErrors && currentState === undefined}
        onChange={handleChange}
        id={`${baseId}-input-vehicleKilometres`}
        value={currentState?.toLocaleString() ?? ''}
      />
    </div>
  );
}
