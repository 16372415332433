import { useEffect, useState } from 'react';

import { Combobox, IComboboxOption } from '@/components/Combobox';
import {
  selectVehicleBuildYear,
  setVehicleBuildYear,
  setVehicleMake,
} from '@/context/borrower';
import {
  selectVehicleBuildYearApiResults,
  setVehicleBuildYearApiResults,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getVehicleYearOptions } from '@/services/AssetService';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'BuildYear';

export const BuildYear = ({ showErrors }: IProps): JSX.Element => {
  // ** Redux **
  const vehicleBuildYearApiResults = useAppSelector(
    selectVehicleBuildYearApiResults
  );
  const currentState = useAppSelector(selectVehicleBuildYear);
  const dispatch = useAppDispatch();

  // ** Local State ***
  const [query, setQuery] = useState<string>(
    currentState !== undefined ? currentState.toString() : ''
  );
  const [loading, setLoading] = useState(false);

  // *****Functions*****
  // Handle the onChange event for the input.
  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;

    if (typeof value === 'boolean') return;
    const intValue = parseInt(value);
    if (intValue === currentState) return;

    if (intValue === 0 || isNaN(intValue)) return;
    if (value?.length > 4) return;

    setQuery(value);
  }

  // When the user selects an option
  const handleSelect = (option: IComboboxOption): void => {
    const intValue = parseInt(option.value);
    if (intValue === currentState) return;
    dispatch(setVehicleBuildYear(intValue));
    dispatch(setVehicleMake(undefined));
  };

  const callApi = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await getVehicleYearOptions();
      const castedOptions = response.map((value, index) => ({
        id: index + 1,
        value: value,
      }));
      dispatch(setVehicleBuildYearApiResults(castedOptions));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    callApi();
  }, []);

  return (
    <div data-testid={testId}>
      <Combobox
        options={vehicleBuildYearApiResults ?? []}
        placeholder='Select year'
        heading='Build Year'
        onChange={handleChange}
        query={query}
        onSelect={handleSelect}
        showErrors={showErrors && !currentState}
        loading={loading}
      />
    </div>
  );
};
