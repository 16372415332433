import { RootState } from '@/context/store';

export const selectShowWelcomeScreen = (state: RootState): boolean =>
  state.displayReducer.showWelcomeScreen;
export const selectIsWhiteLabel = (state: RootState): boolean =>
  state.displayReducer.isWhiteLabel;
export const selectLogo = (state: RootState): string =>
  state.displayReducer.logo;
export const selectInitialLoad = (state: RootState): boolean =>
  state.displayReducer.initialLoad;
export const selectShowOcrDisplay = (state: RootState): boolean =>
  state.displayReducer.showOcrDisplay;
export const selectIsOcrLoading = (state: RootState): boolean =>
  state.displayReducer.isOcrLoading;
export const selectAgreedToTermsAndConditions = (state: RootState): boolean =>
  state.displayReducer.agreedToTermsAndConditions;
export const selectTermsAndConditionsLink = (state: RootState): string =>
  state.displayReducer.termsAndConditionsLink;
export const selectHideSellerScreen = (state: RootState): boolean =>
  state.displayReducer.hideSellerScreen;
export const selectHideVehicleScreen = (state: RootState): boolean =>
  state.displayReducer.hideVehicleScreen;
export const selectShowTotalAmountPayable = (state: RootState): boolean =>
  state.displayReducer.showTotalAmountPayable;
export const selectPartnerName = (state: RootState): string | null =>
  state.displayReducer.partnerName;
export const selectPartnerEmail = (state: RootState): string | null =>
  state.displayReducer.partnerEmail;
export const selectPartnerNumber = (state: RootState): string | null =>
  state.displayReducer.partnerNumber;
export const selectWaterfallLenders = (state: RootState): string[][] =>
  state.displayReducer.waterfallLenders;
export const selectIsAssetKnownViaParams = (state: RootState): boolean =>
  state.displayReducer.isAssetKnownViaParams;
