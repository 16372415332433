import { ILender } from './LenderTypes';

export const mockLender: ILender = {
  lenderName: 'Pepper',
  matchingEngineLenderId: 'PEPPER',
  advertisedRateInPercent: 1.0,
  balloonValueInPercent: 2.0,
  comparisonRateInPercent: 3.0,
  turnaroundTimeInDays: 10,
  upfrontFeeAmount: 300,
  monthlyOngoingFeeAmount: 10,
  weeklyRepaymentAmount: 100,
  fortnightlyRepaymentAmount: 200,
  monthlyRepaymentAmount: 400,
  requiredDocuments: {
    'Document 1': 'Document 1',
  },
};

export const mockLender2: ILender = {
  lenderName: 'Wisr',
  matchingEngineLenderId: 'WISR',
  advertisedRateInPercent: 2.0,
  balloonValueInPercent: 3.0,
  comparisonRateInPercent: 4.0,
  turnaroundTimeInDays: 11,
  upfrontFeeAmount: 310,
  monthlyOngoingFeeAmount: 400,
  weeklyRepaymentAmount: 150,
  fortnightlyRepaymentAmount: 300,
  monthlyRepaymentAmount: 600,
  requiredDocuments: {
    'Document 1': 'Document 1',
  },
};
export const mockLender3: ILender = {
  lenderName: 'Solar',
  matchingEngineLenderId: 'SOLAR',
  advertisedRateInPercent: 3.0,
  balloonValueInPercent: 4.0,
  comparisonRateInPercent: 5.0,
  turnaroundTimeInDays: 12,
  upfrontFeeAmount: 200,
  monthlyOngoingFeeAmount: 30,
  weeklyRepaymentAmount: 200,
  fortnightlyRepaymentAmount: 400,
  monthlyRepaymentAmount: 80,
  requiredDocuments: {
    'Document 1': 'Document',
  },
};
