import { setPurchasePrice } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialVehiclePrice(price: IParameter): void {
  if (!price) {
    return;
  }

  const intPrice = parseInt(price, 10);
  if (isNaN(intPrice)) {
    return;
  }

  const { purchasePrice: statePrice } = store.getState().borrowerReducer.loan;

  if (statePrice !== intPrice) {
    store.dispatch(setPurchasePrice(intPrice));
  }
}
