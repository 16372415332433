import { useParams } from 'react-router-dom';

import { ALLBIDS_PARTNER } from '@/partners/allbids';
import { AUTOHOUSE_PARTNER } from '@/partners/autoHouse';
import { BEEPZ_AUTO_SOLUTIONS_PARTNER } from '@/partners/beepzAutoSolutions';
import { CARIZMA_MOTORS_PARTNER } from '@/partners/carizmaMotors';
import { CARNET_WESTERN_SYDNEY_PARTNER } from '@/partners/carnetWesternSydney';
import { CARSALES_PARTNER } from '@/partners/carsales';
import { CHERY_PARTNER } from '@/partners/chery';
import { DEFAULT_PARTNER } from '@/partners/defaultPartner';
import { DOZO_CAR_SALES_PARTNER } from '@/partners/dozoCarSales';
import { EASYCARS_PARTNER } from '@/partners/easycars';
import { JC_AUTOTRADERS_PTY_LTD_PARTNER } from '@/partners/jcAutotradersPtyLtd';
import { LDV_PARTNER } from '@/partners/ldv';
import { MYMOTO_PARTNER } from '@/partners/myMoto';
import { OC_AUTOMOTIVE_PARTNER } from '@/partners/ocAutomotive';
import { IPartnerParams } from '@/partners/partnerTypes';
import { SCOTTS_GROUP_PARTNER } from '@/partners/scottsGroup';
import { SHOP_FOR_CARS_PARTNER } from '@/partners/shopForCars';
import { SOLAR_PARTNER } from '@/partners/solar';
import { TONY_LAHOOD_PARTNER } from '@/partners/tonyLahood';
import { YC_CARS_PARTNER } from '@/partners/ycCars';

import { TEST_1_PARTNER } from '../test1';
import { TEST_2_PARTNER } from '../test2';
import { TEST_3_PARTNER } from '../test3';
import { TEST_4_PARTNER } from '../test4';
import { TRUCKSTOP_PARTNER } from '../truckstop';

export function getPartnerFromUrl(): IPartnerParams {
  const { id } = useParams(); // eg, url/<id>
  let partnerName: string | undefined;

  // code snippet logic
  try {
    // Global const from outside of vroom on the host website

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const csParams = VroomCodeSnippetQueryStringParams;

    partnerName = new URLSearchParams(csParams).get('partner') || id;
  } catch {
    partnerName = id;
  }

  console.log('test: partnerName', partnerName); // TODO; remove

  let partner: IPartnerParams = DEFAULT_PARTNER;
  switch (partnerName) {
    case 'truckstop':
      partner = TRUCKSTOP_PARTNER;
      break;
    case 'allbids':
      partner = ALLBIDS_PARTNER;
      break;
    case 'autohouse':
      partner = AUTOHOUSE_PARTNER;
      break;
    case 'beepzautosolutions':
      partner = BEEPZ_AUTO_SOLUTIONS_PARTNER;
      break;
    case 'carizma':
      partner = CARIZMA_MOTORS_PARTNER;
      break;
    case 'carnetwesternsydney':
      partner = CARNET_WESTERN_SYDNEY_PARTNER;
      break;
    case 'carsales':
      partner = CARSALES_PARTNER;
      break;
    case 'chery':
      partner = CHERY_PARTNER;
      break;
    case 'dozocarsales':
      partner = DOZO_CAR_SALES_PARTNER;
      break;
    case 'easycars':
      partner = EASYCARS_PARTNER;
      break;
    case 'jcautotraders':
      partner = JC_AUTOTRADERS_PTY_LTD_PARTNER;
      break;
    case 'ldv':
      partner = LDV_PARTNER;
      break;
    case 'mymoto':
      partner = MYMOTO_PARTNER;
      break;
    case 'ocautomotive':
      partner = OC_AUTOMOTIVE_PARTNER;
      break;
    case 'scottsgroup':
      partner = SCOTTS_GROUP_PARTNER;
      break;
    case 'shopforcars':
      partner = SHOP_FOR_CARS_PARTNER;
      break;
    case 'tonylahood':
      partner = TONY_LAHOOD_PARTNER;
      break;
    case 'yccars':
      partner = YC_CARS_PARTNER;
      break;
    case 'solar':
      partner = SOLAR_PARTNER;
      break;
    case 'test1':
      partner = TEST_1_PARTNER;
      break;
    case 'test2':
      partner = TEST_2_PARTNER;
      break;
    case 'test3':
      partner = TEST_3_PARTNER;
      break;
    case 'test4':
      partner = TEST_4_PARTNER;
      break;

    default:
      partner = DEFAULT_PARTNER;
      break;
  }

  return partner;
}
