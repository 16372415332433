import { ELoanUsage } from '@/context/borrower';

import { DEFAULT_PARTNER } from './defaultPartner';
import { EPartners, IPartnerParams } from './partnerTypes';

// Show both seller screen and vehicle screen
export const TEST_3_PARTNER: IPartnerParams = {
  ...DEFAULT_PARTNER,
  id: EPartners.TEST_1,
  loanUsage: ELoanUsage.PERSONAL,
  dealerName: 'TEST 3 PARTNER',
  hideSelectVehicleScreen: true,
  hideSellerScreen: true,
};
