import { IPartnerParams } from '@/partners/partnerTypes';
import { EParamaters } from '@/utils/setParametersFromParams';

import { ISetInitialDisplay } from './ISetInitialDisplay';

export function getInitialDisplayData(
  partner: IPartnerParams,
  params: URLSearchParams
): ISetInitialDisplay {
  return {
    showWelcomeScreen: {
      parameter: params.get(EParamaters.SHOW_WELCOME_SCREEN),
      partner: partner.welcomeScreen,
    },
    isWhiteLabel: partner.whiteLabel,
    logo: partner.logo,
    type: partner.type,
    termsAndConditionsLink: partner.termsAndConditionsLink,
    hideVehicleScreen: partner.hideSelectVehicleScreen,
    hideSellerScreen: partner.hideSellerScreen,
    waterfallLenders: partner.waterfallLenders,
    showTotalAmountPayable: partner.showTotalAmountPayable,
    name: {
      parameter: params.get(EParamaters.DEALER_NAME),
      partner: partner.dealerName,
    },
    email: {
      parameter: params.get(EParamaters.EMAIL),
      partner: partner.dealerEmail,
    },
    phone: {
      parameter: params.get(EParamaters.PHONE),
      partner: partner.dealerPhone,
    },
    colors: {
      parameterPrimaryColor: params.get(EParamaters.PRIMARY_COLOR),
      parameterSecondaryColor: params.get(EParamaters.SECONDARY_COLOR),
      parameterTertiaryColor: params.get(EParamaters.TERTIARY_COLOR),
      parameterQuaternaryColor: params.get(EParamaters.QUATERNARY_COLOR),

      partnerPrimaryColor: partner.primaryColor,
      partnerSecondaryColor: partner.secondaryColor,
      partnerTertiaryColor: partner.tertiaryColor,
      partnerQuaternaryColor: partner.quaternaryColor,
    },
    partnerId: partner.id,
    partner: partner,
  };
}
