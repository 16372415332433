import { Input } from '@/components/Input';
import {
  ESellerType,
  selectSellerPhone,
  selectSellerType,
  setSellerPhone,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'DealershipPhone';

export function DealershipPhone({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectSellerPhone);
  const sellerType = useAppSelector(selectSellerType);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    if (typeof value === 'boolean') return;
    const protectedValue = value.replace(/[^0-9]/g, '');
    if (protectedValue?.length > 10) return;
    dispatch(setSellerPhone(protectedValue));
  }

  function getTitleName(): string {
    const string = ' Phone Number';
    let title = sellerType === ESellerType.DEALERSHIP ? 'Dealership' : 'Seller';
    if (sellerType === ESellerType.SOLAR) title = 'Vendor';

    return title + string;
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title={getTitleName()}
        placeholder='Optional'
        showErrors={showErrors}
        onChange={handleChange}
        id={`${baseId}-input-dealershipPhoneNumber`}
        value={currentState?.toLocaleString() ?? ''}
        disabled={sellerType === ESellerType.SOLAR ? true : false}
      />
    </div>
  );
}
