import { useSelector } from 'react-redux';

import { Input } from '@/components/Input';
import {
  IRealEstate,
  selectMortgageRent,
  selectRealEstatebyIndex,
  setMortgageRent,
  setRealEstateByIndex,
} from '@/context/borrower';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'MortgageRent';

export function MortgageRent({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const mortgageRent = useAppSelector(selectMortgageRent);
  const dispatch = useAppDispatch();

  const realEstate = useSelector((state: RootState) =>
    selectRealEstatebyIndex(state, 0)
  );

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    // MAXIMUM
    const MAXIMUM = 99999;
    const intValue = getNumber(value, MAXIMUM);
    if (intValue === mortgageRent) return;
    dispatch(setMortgageRent(intValue));

    const newRealEstate: IRealEstate = {
      ...realEstate,
      monthlyRepayment: intValue,
      isLoanPresent: true,
    };
    if (intValue && isNaN(intValue)) {
      return;
    }
    dispatch(
      setRealEstateByIndex({
        index: 0,
        realEstate: newRealEstate,
      })
    );
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Mortgage / Rent'
        placeholder='Required'
        showErrors={
          showErrors && (mortgageRent === undefined || mortgageRent === null)
        }
        onChange={handleChange}
        id={`${baseId}-input-mortgageRent`}
        value={mortgageRent?.toLocaleString() ?? ''}
        prefix='$'
      />
    </div>
  );
}
