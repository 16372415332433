import { setLogo } from '@/context/display';
import { store } from '@/context/store';

export function setInitialSellerLogo(partnerLogo: string | null): void {
  if (!partnerLogo) {
    return;
  }

  const { logo } = store.getState().displayReducer;
  if (logo !== partnerLogo) {
    store.dispatch(setLogo(partnerLogo));
  }
}
