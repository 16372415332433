import { IComboboxOption } from '@/components/Combobox';
import { IVehicle } from '@/context/borrower';
import { PayloadAction } from '@reduxjs/toolkit';

export const glassGuideReducers = {
  setVehicleBuildYearApiResults: (
    state,
    action: PayloadAction<IComboboxOption[]>
  ): void => {
    state.glassGuideLookUpVehicleResults.vehicleBuildYearApiResults =
      action.payload;
  },
  setVehicleMakeApiResults: (
    state,
    action: PayloadAction<IComboboxOption[]>
  ): void => {
    state.glassGuideLookUpVehicleResults.vehicleMakeApiResults = action.payload;
  },
  setVehicleModelApiResults: (
    state,
    action: PayloadAction<IComboboxOption[]>
  ): void => {
    state.glassGuideLookUpVehicleResults.vehicleModelApiResults =
      action.payload;
  },
  setVehicleVariantApiResults: (
    state,
    action: PayloadAction<IComboboxOption[]>
  ): void => {
    state.glassGuideLookUpVehicleResults.vehicleVariantApiResults =
      action.payload;
  },
  setGlassOptionsForVehicle: (
    state,
    action: PayloadAction<IVehicle[]>
  ): void => {
    state.glassGuideLookUpVehicleResults.glassOptionsForVehicle =
      action.payload;
  },
};
