import { useState } from 'react';
import { useSelector } from 'react-redux';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import {
  EPreApprovalVerdicts,
  fetchPreapprovalVerdict,
} from '@/apiCalls/fetchPreapprovalVerdict';
import { getQuickQuote } from '@/apiCalls/matchingEngine';
import { Button, EButtonType } from '@/components/Button';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import { RemoteImage } from '@/components/RemoteImage';
import {
  selectAddressByIndex,
  selectBirthdate,
  selectEmail,
  selectFirstName,
  selectGender,
  selectIsVehicleKnown,
  selectLicenseExpiry,
  selectLicenseNumber,
  selectLicenseState,
  selectMobile,
  selectResidencyStatus,
} from '@/context/borrower';
import { EDisplays, setDisplay } from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { isEmail } from '@/utils/isEmail';
import { isMobile } from '@/utils/isMobile';

import { AddressInformation } from './AddressInformation';
import { ContactDetails } from './ContactDetails';
import { PersonalInformation } from './PersonalInformation';

export const testId = 'OcrSummary';

export function OcrSummary(): JSX.Element {
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  // ***** CONSTANTS *****
  const baseId = 'screen-OcrSummary';

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  // Contact Details
  const firstName = useAppSelector(selectFirstName);
  const email = useAppSelector(selectEmail);
  const phone = useAppSelector(selectMobile);
  // Personal Information
  const gender = useAppSelector(selectGender);
  const birthdate = useAppSelector(selectBirthdate);
  const licenseNumber = useAppSelector(selectLicenseNumber);
  const licenseState = useAppSelector(selectLicenseState);
  const licenseExpiry = useAppSelector(selectLicenseExpiry);
  const residencyStatus = useAppSelector(selectResidencyStatus);
  const isVehicleKnown = useAppSelector(selectIsVehicleKnown);

  // Address Information
  const address = useSelector((state: RootState) =>
    selectAddressByIndex(state, 0)
  );
  const { ownershipType, streetNumber, streetName, suburb, state, postcode } =
    address;

  // ***** Helpers *****
  function isComplete(): boolean {
    // Contact Details
    if (firstName === null || firstName === undefined) return false;
    if (!isEmail(email)) return false;
    if (!isMobile(phone)) return false;
    // Personal Information
    if (gender === null || gender === undefined) return false;
    if (birthdate === null || birthdate === undefined) return false;
    if (licenseNumber === null || licenseNumber === undefined) return false;
    if (licenseState === null || licenseState === undefined) return false;
    if (licenseExpiry === null || licenseExpiry === undefined) return false;
    if (residencyStatus === null || residencyStatus === undefined) return false;
    // Address Information
    if (residencyStatus === null || residencyStatus === undefined) return false;
    if (ownershipType === null || ownershipType === undefined) return false;
    if (streetNumber === null || streetNumber === undefined) return false;
    if (streetName === null || streetName === undefined) return false;
    if (suburb === null || suburb === undefined) return false;
    if (state === null || state === undefined) return false;
    if (postcode === null || postcode === undefined) return false;

    return true;
  }

  function getNextScreen(verdict: EPreApprovalVerdicts): EDisplays {
    switch (verdict) {
      case EPreApprovalVerdicts.APPROVED:
        return EDisplays.INDICATIVELY_APPROVED;
      case EPreApprovalVerdicts.DECLINED:
        return EDisplays.INDICATIVELY_DECLINED;
      default:
      case EPreApprovalVerdicts.REFERRED:
        return EDisplays.INDICATIVELY_REFERRED;
    }
  }

  // ***** Event Handlers *****
  function handleBackClick(): void {
    dispatch(setDisplay(EDisplays.IDENTIFICATION));
  }

  async function handleNextClick(): Promise<void> {
    setShowErrors(true);
    if (!isComplete()) return;
    setLoading(true);
    await getQuickQuote();
    // If vehicle is not known, can't get pre approval, so go back to loan screen
    if (!isVehicleKnown) {
      dispatch(setDisplay(EDisplays.LOAN));
      return;
    }
    const verdict = await fetchPreapprovalVerdict();
    const nextScreen = await getNextScreen(verdict);
    
    createOrUpdateBorrower();
    setLoading(false);
    dispatch(setDisplay(nextScreen));
  }

  // ***** Render *****
  if (loading)
    return (
      <div className='w-full h-screen flex justify-center items-center'>
        <div className='w-[250px] h-[250px] bg-primary rounded-full flex justify-center items-center mb-10'>
          <RemoteImage fileName='whiteFingerprint.gif' className='w-3/4' />
        </div>
      </div>
    );

  return (
    <div data-testid={testId}>
      <Header />
      <ProgressBar />
      <div className='flex '>
        <Button
          onClick={handleBackClick}
          type={EButtonType.SECONDARY_OUTLINE}
          square
        >
          Back
        </Button>
        <Button onClick={handleNextClick} square>
          Next
        </Button>
      </div>
      <div className='px-4'>
        <DisplayHeading
          title='Check Your Details Below'
          infoText="Here's what we've got so far. Something missing or not quite right? Click on box or the 'edit' button to fix it up."
        />
        <ContactDetails baseId={baseId} showErrors={showErrors} />
        <AddressInformation baseId={baseId} showErrors={showErrors} />
        <PersonalInformation baseId={baseId} showErrors={showErrors} />
      </div>
    </div>
  );
}
