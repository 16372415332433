import { getQuickQuote } from '@/apiCalls/matchingEngine';
import { setInitialLoad, setIsLoaded } from '@/context/display';
import { store } from '@/context/store';
import { EPartners } from '@/partners/partnerTypes';

import * as helpers from './helpers';
import { ISetInitialDisplay } from './ISetInitialDisplay';

export function setInitialDisplay(settings: ISetInitialDisplay): void {
  helpers.setInitialShowWelcomeScreen(
    settings.showWelcomeScreen.parameter,
    settings.showWelcomeScreen.partner
  );
  helpers.setInitialIsWhiteLabel(settings.isWhiteLabel);
  helpers.setInitialSellerLogo(settings.logo);
  helpers.setInitialSellerType(settings.type);
  helpers.setInitialSellerTermsLink(settings.termsAndConditionsLink);
  helpers.setInitialHideVehicleScreen(settings.hideVehicleScreen);
  helpers.setInitialHideSellerScreen(settings.hideSellerScreen);
  helpers.setInitialWaterfallLenders(settings.waterfallLenders);
  helpers.setInitialShowTotalAmountPayable(settings.showTotalAmountPayable);
  helpers.setInitialDisplaySellerName(
    settings.name.parameter,
    settings.name.partner
  );
  helpers.setInitialDisplaySellerEmail(
    settings.email.parameter,
    settings.email.partner
  );
  helpers.setInitialDisplaySellerPhone(
    settings.phone.parameter,
    settings.phone.partner
  );
  helpers.setInitialColors(settings.colors);
  helpers.setInitialModal(settings.partnerId);
  helpers.setInitialDisplayScreen(settings.partnerId);
  helpers.setInitialPartner(settings.partner);

  // !Note: Leave as final dispatch - relies on earlier ones
  if (settings.partnerId !== EPartners.SOLAR) {
    getQuickQuote();
  }

  // Vroom is Loaded
  store.dispatch(setInitialLoad(true));
  store.dispatch(setIsLoaded(true));
}
