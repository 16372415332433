import { setDisplay } from '@/context/display';
import { store } from '@/context/store';
import { EPartners } from '@/partners/partnerTypes';

import { getInitialDisplayScreen } from '../getInitialDisplayScreen';

export function setInitialDisplayScreen(partnerId: EPartners): void {
  const initialDisplay = getInitialDisplayScreen(partnerId);

  const currentDisplay = store.getState().displayReducer.display;

  if (initialDisplay === currentDisplay) return;
  store.dispatch(setDisplay(initialDisplay));
}
