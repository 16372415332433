import { RadioGroup } from '@/components/RadioGroup';
import {
  selectNoForseeableChangesToCircumstances,
  setForseeableChangesExplanation,
  setNoForseeableChangesToCircumstances,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getBoolean } from '@/utils/valueFormat';

import { ForseeableChangesExplanation } from '../ForseeableChangesExplanation';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'ForseeableChangesToCircumstances';

export function ForseeableChangesToCircumstances({
  baseId,
  showErrors,
}: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectNoForseeableChangesToCircumstances);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean | undefined): void {
    const booleanValue = getBoolean(value);
    dispatch(setNoForseeableChangesToCircumstances(booleanValue));
    if (!booleanValue)
      dispatch(setNoForseeableChangesToCircumstances(undefined));
    if (booleanValue === false) {
      dispatch(setForseeableChangesExplanation(undefined));
    }
  }

  // ***** Render *****
  const options = [
    {
      label: 'Yes',
      value: true,
      id: `${baseId}-button-noForseeableChangesToCircumstances-yes`,
    },
    {
      label: 'No',
      value: false,
      id: `${baseId}-button-noForseeableChangesToCircumstances-no`,
    },
  ];
  return (
    <div data-testid={testId}>
      <RadioGroup
        title='Are there any forseeable changes to your circumstances?'
        showErrors={showErrors && currentState === undefined}
        onChange={handleChange}
        options={options}
        value={currentState}
      />
      {currentState && (
        <ForseeableChangesExplanation baseId={baseId} showErrors={showErrors} />
      )}
    </div>
  );
}
