import { Input } from '@/components/Input';
import { selectAbn } from '@/context/borrower';
import { useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'Abn';

export function Abn({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectAbn);

  // ***** Event Handlers *****
  function handleChange(): void {
    return;
  }

  // ***** Render *****
  return (
    <div data-testid={testId} className='w-full'>
      <Input
        title='ABN'
        placeholder={currentState || 'Required'}
        showErrors={showErrors && !currentState}
        onChange={handleChange}
        id={`${baseId}-input-abn`}
        value={currentState ?? ''}
        disabled
      />
    </div>
  );
}
