import { useState } from 'react';
import { useSelector } from 'react-redux';

import { AssetsNavigationButtons } from '@/components/AssetsNavigationButtons';
import { Button } from '@/components/Button';
import { ModalHeader } from '@/components/ModalHeader';
import {
  deletePersonalLoan,
  selectPersonalLoanActiveIndex,
  selectPersonalLoanByIndex,
  selectPersonalLoans,
  setPersonalLoanActiveIndex,
} from '@/context/borrower';
// eslint-disable-next-line no-restricted-imports
// eslint-disable-next-line no-restricted-imports
import { setModal } from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { PersonalLoanBalance } from '@/UserInput/PersonalLoanBalance';
import { PersonalLoanLenderName } from '@/UserInput/PersonalLoanLenderName';
import { PersonalLoanMonthlyRepayment } from '@/UserInput/PersonalLoanMonthlyRepayment';
import { isPersonalLoanComplete } from '@/utils/checkAssetsAndLiabilities';

export const testId = 'PersonalLoans';

export function PersonalLoans(): JSX.Element {
  // ***** Constants *****
  const baseId = 'modal-PersonalLoans';
  const modaltitle = 'Personal Loan';
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState<boolean>(false);
  // ***** Redux State *****
  const activeCardIndex = useAppSelector(selectPersonalLoanActiveIndex);
  const dispatch = useAppDispatch();
  const titleNumber = activeCardIndex + 1;

  const personalLoan = useSelector((state: RootState) =>
    selectPersonalLoanByIndex(state, activeCardIndex)
  );
  const personalLoans = useAppSelector(selectPersonalLoans);
  const hasPreviousPersonalLoan =
    personalLoans?.length > 0 && activeCardIndex !== 0;
  const hasNextPersonalLoan = personalLoans?.length - 1 > activeCardIndex;

  // ***** Handlers *****
  function handleSaveClick(): void {
    dispatch(setModal(null));
  }

  function handleNextClick(): void {
    if (!isPersonalLoanComplete(personalLoan)) return;
    dispatch(setPersonalLoanActiveIndex(activeCardIndex + 1));
  }

  function handleDeleteClick(): void {
    // Delete Current Real Estate
    dispatch(deletePersonalLoan());
    // Change Active Index
    if (
      personalLoans?.length >= 1 &&
      personalLoans?.length > activeCardIndex - 1
    ) {
      dispatch(setPersonalLoanActiveIndex(activeCardIndex - 1));
    }
  }

  function handlePreviousClick(): void {
    if (
      personalLoans?.length >= 1 &&
      personalLoans?.length > activeCardIndex - 1
    ) {
      dispatch(setPersonalLoanActiveIndex(activeCardIndex - 1));
    }
  }

  // ***** Render *****
  return (
    <div data-testid={testId} className='flex flex-col justify-between  py-2'>
      <ModalHeader title={modaltitle + '-' + titleNumber} baseId={baseId} />

      <AssetsNavigationButtons
        handleDeleteClick={handleDeleteClick}
        handlePreviousClick={handlePreviousClick}
        handleNextClick={handleNextClick}
        hasPrevious={hasPreviousPersonalLoan}
        hasNext={hasNextPersonalLoan}
      />

      <PersonalLoanLenderName
        baseId={baseId}
        showErrors={showErrors}
        setShowErrors={setShowErrors}
      />
      <PersonalLoanMonthlyRepayment
        baseId={baseId}
        showErrors={showErrors}
        setShowErrors={setShowErrors}
      />
      <PersonalLoanBalance
        baseId={baseId}
        showErrors={showErrors}
        setShowErrors={setShowErrors}
      />
      <div className='py-2 mx-16'>
        <Button onClick={handleSaveClick} id={`${baseId}-button-close`}>
          Save
        </Button>
      </div>
    </div>
  );
}
