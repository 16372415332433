import { useState } from 'react';
import { useSelector } from 'react-redux';

import { AssetsNavigationButtons } from '@/components/AssetsNavigationButtons';
import { Button } from '@/components/Button';
import { ModalHeader } from '@/components/ModalHeader';
import {
  deleteCreditCard,
  selectCreditCardActiveIndex,
  selectCreditCardByIndex,
  selectCreditCards,
  setCreditCardActiveIndex,
} from '@/context/borrower';
import { setModal } from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { CreditCardBalance } from '@/UserInput/CreditCardBalance';
import { CreditCardLenderName } from '@/UserInput/CreditCardLenderName';
import { CreditCardLimit } from '@/UserInput/CreditCardLimit';
import { isCreditCardComplete } from '@/utils/checkAssetsAndLiabilities';

export const testId = 'CreditCards';

export function CreditCards(): JSX.Element {
  // ***** Constants *****
  const baseId = 'modal-CreditCards';
  const modaltitle = 'Credit Card';
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState<boolean>(false);
  // ***** Redux State *****
  const activeCardIndex = useAppSelector(selectCreditCardActiveIndex);
  const dispatch = useAppDispatch();
  const titleNumber = activeCardIndex + 1;
  console.log('cc modal rendered');
  const creditCard = useSelector((state: RootState) =>
    selectCreditCardByIndex(state, activeCardIndex)
  );
  console.log(creditCard, 'credit card clicked');
  const creditCards = useSelector(selectCreditCards);
  const hasPreviousCreditCard =
    creditCards?.length > 0 && activeCardIndex !== 0;
  const hasNextCreditCard = creditCards?.length - 1 > activeCardIndex;

  // ***** Handlers *****
  function handleSaveClick(): void {
    dispatch(setModal(null));
  }

  function handleNextClick(): void {
    if (!isCreditCardComplete(creditCard)) return;
    dispatch(setCreditCardActiveIndex(activeCardIndex + 1));
  }

  function handleDeleteClick(): void {
    // Delete Current Credit Card
    dispatch(deleteCreditCard());
    // Change Active Index
    if (creditCards?.length >= 1 && creditCards?.length > activeCardIndex - 1) {
      dispatch(setCreditCardActiveIndex(activeCardIndex - 1));
    }
  }

  function handlePreviousClick(): void {
    if (creditCards?.length >= 1 && creditCards?.length > activeCardIndex - 1) {
      dispatch(setCreditCardActiveIndex(activeCardIndex - 1));
    }
  }
  // ***** Render *****
  return (
    <div data-testid={testId} className='flex flex-col justify-between  py-2'>
      <ModalHeader title={modaltitle + '-' + titleNumber} baseId={baseId} />

      <AssetsNavigationButtons
        handleDeleteClick={handleDeleteClick}
        handlePreviousClick={handlePreviousClick}
        handleNextClick={handleNextClick}
        hasPrevious={hasPreviousCreditCard}
        hasNext={hasNextCreditCard}
      />

      <CreditCardLenderName
        baseId={baseId}
        showErrors={showErrors}
        setShowErrors={setShowErrors}
      />

      <CreditCardLimit
        baseId={baseId}
        showErrors={showErrors}
        setShowErrors={setShowErrors}
      />
      <CreditCardBalance
        baseId={baseId}
        showErrors={showErrors}
        setShowErrors={setShowErrors}
      />
      <div className='py-2 mx-16'>
        <Button onClick={handleSaveClick} id={`${baseId}-button-close`}>
          Save
        </Button>
      </div>
    </div>
  );
}
