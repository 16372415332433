import { initialBorrowerSlice, setBorrower } from '@/context/borrower';
import {
  EDisplays,
  EModals,
  selectDisplay,
  selectLogo,
  selectPartnerName,
  setDisplay,
  setModal,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { selectIsLoggedIn, setIsLoggedIn } from '@/context/user';
import { SOLAR_DEALER_NAME } from '@/partners/solar';
import { IMAGE_URL } from '@/utils/constants';
import {
  ELocalStorageVariables,
  removeLocalStorageItem,
} from '@/utils/localStorage';

import { Button, EButtonType } from '../Button';

export function Header(): JSX.Element {
  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  let logo = useAppSelector(selectLogo);
  const partnerNameDisplay = useAppSelector(selectPartnerName);
  const display = useAppSelector(selectDisplay);
  const solar = partnerNameDisplay === SOLAR_DEALER_NAME;
  const solarOcrAnimation =
    solar &&
    (display === EDisplays.OCR_ANIMATION ||
      display === EDisplays.SOLAR_HOLDING_SCREEN);
  if (solarOcrAnimation) {
    logo = 'solarBlack.svg';
  }

  // ***** Event Handlers *****
  function handleClick(): void {
    if (isLoggedIn) {
      const nextDisplay = getNextDisplay();
      const nextModal = getNextModal();
      dispatch(setBorrower(initialBorrowerSlice));
      dispatch(setIsLoggedIn(false));
      if (nextModal !== null) {
        dispatch(setModal(nextModal));
      }
      if (nextDisplay !== null) {
        dispatch(setDisplay(nextDisplay));
      }
      removeLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);
      removeLocalStorageItem(ELocalStorageVariables.FIRST_NAME);

      return;
    }
    dispatch(setModal(EModals.LOGIN));
  }

  function getNextDisplay(): EDisplays | null {
    if (!isLoggedIn) return null;
    if (solar) return EDisplays.SOLAR_LOAN_DETAILS;
    return EDisplays.BASIC;
  }

  function getNextModal(): EModals | null {
    if (isLoggedIn) return null;
    if (solar) return EModals.LOGIN;
    return EModals.WELCOME;
    }

    function getText(): string {
        if (isLoggedIn) return 'Log Out';

        if (solar) return 'Have a quote already?';
      return 'Log In';
    }
  // ***** Render *****
  return (
    <div data-testid='header' className='flex justify-between pb-2 pr-4 pt-2'>
      <div className='h-10 p-2 flex justify-center items-center pl-4'>
        <img height={30} width={90} src={`${IMAGE_URL}/${logo}`} alt='logo' />
      </div>

      <div className={solar ? 'w-48': 'w-24'}>
        {!solarOcrAnimation && (
          <Button onClick={handleClick} type={EButtonType.OUTLINE}>
            {getText()}
          </Button>
        )}
      </div>
    </div>
  );
}
