import { Input } from '@/components/Input';
import { selectHealthcare, setHealthcare } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'Healthcare';

export function Healthcare({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectHealthcare);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    // MAXIMUM
    const MAXIMUM = 99999;
    const intValue = getNumber(value, MAXIMUM);
    if (intValue === currentState) return;
    dispatch(setHealthcare(intValue));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Healthcare'
        placeholder='Required'
        showErrors={showErrors && (currentState === undefined || currentState === null)}
        onChange={handleChange}
        id={`${baseId}-input-healthcare`}
              value={currentState?.toLocaleString() ?? ''}
        prefix='$'
      />
    </div>
  );
}
