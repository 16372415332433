import { useState } from 'react';

import { Button, EButtonType } from '@/components/Button';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import { selectEmployments, setEmploymentIndex } from '@/context/borrower';
import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { MIN_EMPLOYMENT_YEAR } from '@/utils/constants';
import { getTotalEmploymentYears } from '@/utils/getTotalEmploymentYears';
import { isEmploymentComplete } from '@/utils/isEmploymentComplete';

export const baseId = 'screen-employment';

export function Employment(): JSX.Element {
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState(false);

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const employments = useAppSelector(selectEmployments);

  // ***** Event Handlers *****
  function handleBackClick(): void {
    return;
  }

  function handleNextClick(): void {
    setShowErrors(true);
    if (!isComplete()) return;
    const nextScreen = getNextScreen();
    dispatch(setDisplay(nextScreen));
  }

  // ***** Helpers ******
  function isComplete(): boolean {
    let isComplete = true;
    employments.forEach((employment) => {
      if (!isEmploymentComplete(employment)) isComplete = false;
    });
    if (getTotalEmploymentYears() < MIN_EMPLOYMENT_YEAR) isComplete = false;
    return isComplete;
  }

  function getNextScreen(): EDisplays {
    return EDisplays.ASSETS_AND_LIABILITIES;
  }

  function handleEmploymentClick(index: number): void {
    dispatch(setEmploymentIndex(index));
    dispatch(setModal(EModals.WORK_HISTORY));
  }

  return (
    <div data-testid={baseId}>
      <Header />
      <ProgressBar />
      <div className='flex '>
        <Button onClick={handleBackClick} type={EButtonType.DISABLED} square>
          Back
        </Button>
        <Button onClick={handleNextClick} square>
          Next
        </Button>
      </div>
      <div className='px-4'>
        <DisplayHeading
          title='Work Details'
          infoText='We need to know your last three (3) years of employment history.'
        />
        <div className=''>
          {employments.map((employment, index) => {
            return (
              <div
                key={index}
                className={`flex flex-col border border-gray-500 bg-white rounded-md p-4 cursor-pointer mt-4 ${
                  showErrors && !isEmploymentComplete(employment) && 'bg-error'
                }`}
                onClick={(): void => handleEmploymentClick(index)}
              >
                <p>
                  {employment.employerName ?? 'Incomplete Employment History'}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
