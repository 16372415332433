import { useState } from 'react';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button } from '@/components/Button';
import { ModalHeader } from '@/components/ModalHeader';
import {
  selectEmploymentIndex,
  selectEmployments,
  setEmploymentIndex,
} from '@/context/borrower';
import { EDisplays, setDisplay, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { EmployerName } from '@/UserInput/EmployerName';
import { EmployerPhone } from '@/UserInput/EmployerPhone';
import { EmploymentMonths } from '@/UserInput/EmploymentMonths';
import { EmploymentYears } from '@/UserInput/EmploymentYears';
import { Occupation } from '@/UserInput/Occupation';
import { hasMinimumEmploymentYearsBeenMet } from '@/utils/hasMinimumEmploymentYearsBeenMet';
import { isEmploymentComplete } from '@/utils/isEmploymentComplete';

export const testId = 'WorkHistory';

export function WorkHistory(): JSX.Element {
  // ***** Constants *****
  const baseId = 'modal-ContactDetails';
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState<boolean>(false);
  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const employments = useAppSelector(selectEmployments);
  const employmentIndex = useAppSelector(selectEmploymentIndex);

  // ***** Handlers *****
  function handleClick(): void {
    setShowErrors(true);
    if (!isEmploymentComplete(employments[employmentIndex])) return;
    setShowErrors(false);
    if (hasMinimumEmploymentYearsBeenMet()) {
      dispatch(setEmploymentIndex(employmentIndex + 1));
      return;
    } else {
      createOrUpdateBorrower();
      dispatch(setModal(null));
      dispatch(setDisplay(EDisplays.ASSETS_AND_LIABILITIES));
    }
  }

  // ***** Render *****
  return (
    <div data-testid={testId} className='flex flex-col justify-between  py-2'>
      <ModalHeader
        title={
          employmentIndex === 0 ? 'Current Employment' : 'Previous Employment'
        }
        baseId={baseId}
      />
      <p className='text-xs'>We need three (3) years of employment history</p>
      <Occupation baseId={baseId} showErrors={showErrors} />
      <EmployerName baseId={baseId} showErrors={showErrors} />
      <EmployerPhone baseId={baseId} showErrors={showErrors} />
      <div className='flex space-x-4'>
        <EmploymentYears baseId={baseId} showErrors={showErrors} />
        <EmploymentMonths baseId={baseId} showErrors={showErrors} />
      </div>
      <div className='py-2 mx-16'>
        <Button onClick={handleClick} id={`${baseId}-button-close`}>
          {hasMinimumEmploymentYearsBeenMet() ? 'Add Previous' : 'Close'}
        </Button>
      </div>
    </div>
  );
}
