import { ICreditCard } from '@/context/borrower';

export function isCreditCardComplete(creditCard: ICreditCard): boolean {
  if (
    !creditCard.creditCardLimit &&
    !creditCard.lenderName &&
    !creditCard.creditCardBalance
  ) {
    return true;
  }
  if (
    creditCard.creditCardLimit === undefined &&
    creditCard.lenderName === undefined &&
    creditCard.creditCardBalance === undefined
  ) {
    return true;
  }

  if (
    creditCard.creditCardLimit &&
    creditCard.lenderName &&
    creditCard.creditCardBalance
  ) {
    return true;
  }
  return false;
}
