import { Button } from '@/components/Button';
import { RemoteImage } from '@/components/RemoteImage';
import { setModal } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';

export const testId = 'WhatNext';

export function WhatNext(): JSX.Element {
  // ***** Redux State *****
  const dispatch = useAppDispatch();

  // ***** Handlers *****
  function handleClick(): void {
    dispatch(setModal(null));
  }

  // ***** Render *****
  return (
    <div
      data-testid={testId}
      className='flex flex-col justify-between  py-2 px-8'
    >
      <RemoteImage fileName='confetti.svg' className='w-full h-40' />
      <h3 className='text-center font-bold text-2xl pb-4'>What&apos;s Next?</h3>
      <div className='flex w-full pb-4'>
        <div className='relative'>
          <div className='mt-10 h-64 w-2 bg-black' />
          <div className='h-6 w-6 absolute rounded-full bg-black top-8 -left-2' />
          <div className='h-6 w-6 absolute rounded-full bg-black top-36 -left-2' />
          <div className='h-6 w-6 absolute rounded-full bg-black bottom-12 -left-2' />
        </div>
        <div className='text-lg pl-6'>
          <p>
            To grant formal approval, the <br />
            lender will need additional <br />
            information and documents to
            <br />
            verify your financial information.
            <br />
            <br />
            The next few screens will request more details about your
            employment, assets and liabilities.
            <br />
            <br />
            When all the mandatory supporting documentation has been uploaded,
            you&apos;ll be good to go.
          </p>
        </div>
      </div>
      <Button onClick={handleClick}>
        <p>Proceed With Application</p>
      </Button>
    </div>
  );
}
