import { Input } from '@/components/Input';
import { selectGeneral, setGeneral } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IGeneralLiving {
  baseId: string;
  showErrors: boolean;
}

export function GeneralLiving({
  baseId,
  showErrors,
}: IGeneralLiving): JSX.Element {
  // ***** Redux *****
  const generalLiving = useAppSelector(selectGeneral);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    // MAXIMUM
    const MAXIMUM = 99999;
    const intValue = getNumber(value, MAXIMUM);
    if (intValue === generalLiving) return;
    dispatch(setGeneral(intValue));
  }

  // ***** Render *****
  return (
    <div data-testid='GeneralLiving'>
      <Input
        title='General Living'
        placeholder='Required'
        showErrors={showErrors && !generalLiving}
        onChange={handleChange}
        id={`${baseId}-input-generalLiving`}
              value={generalLiving?.toLocaleString() ?? ''}
        prefix='$'
      />
    </div>
  );
}
