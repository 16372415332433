import { RootState } from '@/context/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EUserLoginOption, IUser } from './UserTypes';

// Define the initial state using that type
const initialState: IUser = {
  isLoggedIn: false,
  mobileOrEmail: EUserLoginOption.MOBILE,
};

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },

    setMobileOrEmail: (state, action: PayloadAction<EUserLoginOption>) => {
      state.mobileOrEmail = action.payload;
    },
  },
});

export const { setIsLoggedIn } = userSlice.actions;
export const { setMobileOrEmail } = userSlice.actions;

export function selectUser(state: RootState): IUser {
  return state.userReducer;
}
export function selectIsLoggedIn(state: RootState): boolean {
  return state.userReducer.isLoggedIn;
}
export function selectMobileOrEmail(state: RootState): EUserLoginOption | null {
  return state.userReducer.mobileOrEmail;
}

export const userReducer = userSlice.reducer;
