import { useState } from 'react';

import { Button, EButtonType } from '@/components/Button';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import {
  selectVehicleBuildYear,
  selectVehicleKilometres,
} from '@/context/borrower';
import {
  EDisplays,
  selectHideSellerScreen,
  setDisplay,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { BuildYearManual } from '@/UserInput/BuildYearManual';
import { Kilometers } from '@/UserInput/Kilometers';
import { MakeManual } from '@/UserInput/MakeManual';
import { ModelManual } from '@/UserInput/ModelManual';
import { VariantManual } from '@/UserInput/VariantManual';

export const testId = 'ManualVehicle';

export function ManualVehicle(): JSX.Element {
  // ***** CONSTANTS *****
  const baseId = 'screen-manualVehicle';
  const [showErrors, setShowErrors] = useState<boolean>(false);

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const kilometers = useAppSelector(selectVehicleKilometres);
  const buildYear = useAppSelector(selectVehicleBuildYear);
  const hideSellerScreen = useAppSelector(selectHideSellerScreen);

  // ***** Event Handlers *****
  function handleBackClick(): void {
    const backScreen = getBackScreen();
    dispatch(setDisplay(backScreen));
  }

  function handleNextClick(): void {
    setShowErrors(true);
    if (!isComplete()) return;
    const nextScreen = getNextScreen();
    dispatch(setDisplay(nextScreen));
  }

  function isComplete(): boolean {
    if (!buildYear || buildYear.toString()?.length !== 4) return false;
    if (kilometers === undefined) return false;
    return true;
  }

  function getNextScreen(): EDisplays {
    if (!hideSellerScreen) {
      return EDisplays.SELLER;
    }

    return EDisplays.BUSINESS;
  }

  function getBackScreen(): EDisplays {
    return EDisplays.LOAN;
  }

  return (
    <div data-testid={testId}>
      <Header />
      <ProgressBar />
      <div className='flex'>
        <Button
          onClick={handleBackClick}
          type={EButtonType.SECONDARY_OUTLINE}
          square
        >
          Back
        </Button>
        <Button onClick={handleNextClick} square>
          Next
        </Button>
      </div>
      <div className='px-4'>
        <DisplayHeading
          title='Select Vehicle'
          infoText='Please enter the vehicle details below to get an accurate quote'
        />
        <Kilometers baseId={baseId} showErrors={showErrors} />
        <BuildYearManual baseId={baseId} showErrors={showErrors} />
        <MakeManual baseId={baseId} showErrors={false} />
        <ModelManual baseId={baseId} showErrors={false} />
        <VariantManual baseId={baseId} showErrors={false} />
      </div>
    </div>
  );
}
