import { DateInput } from '@/components/DateInput';
import { selectBirthdate, setBirthdate } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'BirthDate';

export const BirthDate = ({ showErrors, baseId }: IProps): JSX.Element => {
  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const birthDate = useAppSelector(selectBirthdate);

  // *****Functions*****

  const handlOnChange = (date: Date): void => {
    dispatch(setBirthdate(date));
  };

  // *****Render*****
  return (
    <DateInput
      title='Date of Birth'
      placeholder='Date of Birth'
      onChange={handlOnChange}
      value={birthDate}
      showErrors={showErrors}
    />
  );
};
