import { EDisplays } from '@/context/display';
import { store } from '@/context/store';
import { EPartners } from '@/partners/partnerTypes';

export function getInitialDisplayScreen(partnerId: EPartners): EDisplays {
  if (partnerId === EPartners.SOLAR) return EDisplays.SOLAR_LOAN_DETAILS;

  const state = store.getState();
  const price = state.borrowerReducer.loan.purchasePrice;
  const isVehicleKnown = state.borrowerReducer.vehicle.isVehicleKnown;
  if (!price || !isVehicleKnown) {
    return EDisplays.LOAN;
  }

  return EDisplays.BASIC;
}
