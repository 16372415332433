import { Button } from '@/components/Button';
import { ContactBox } from '@/components/ContactBox';
import { Header } from '@/components/Header';
import { RemoteImage } from '@/components/RemoteImage';
import { EDisplays, setDisplay } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';

export const baseId = 'ServicingFailure';

export function ServicingFailure(): JSX.Element {
  // ***** Redux State *****
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleReduceLoanAmount(): void {
    dispatch(setDisplay(EDisplays.LOAN));
  }

  function handleEditIncome(): void {
    dispatch(setDisplay(EDisplays.INCOME));
  }

  return (
    <div data-testid={baseId}>
      <Header />
      <div className='flex-center pt-4 pb-10 relative'>
        <div className='bg-primary h-40 w-40 rounded-full' />
        <RemoteImage fileName='coins.svg' className='absolute' />
      </div>
      <div className='text-center flex flex-col space-y-2'>
        <h3 className='uppercase text-xl text-primary font-bold '>
          Check your income
          <br />
          and expenses
        </h3>
        <p className='text-secondary '>
          It looks like your income might not be high <br /> enough for the
          amount you&apos;d like to borrow
        </p>
        <p className='text-sm'>
          Check your income and expenses to make sure <br />
          they&apos;re correct or consider reducing the amount <br />
          you&apos;d like to borrow.
        </p>
      </div>
      <div className='flex space-x-2 mx-4 pt-4'>
        <Button onClick={handleReduceLoanAmount}>Reduce Loan</Button>
        <Button onClick={handleEditIncome}>Edit Answers</Button>
      </div>
      <ContactBox />
    </div>
  );
}
