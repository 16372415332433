import { postUrl } from '@/apiCalls/fetch';
import { store } from '@/context/store';
import {
  ELocalStorageVariables,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@/utils/localStorage';

import {
  EVerificationResponseStatus,
  IVerifyCodeReturn,
} from '../verificationCommonTypes';

import {
  IVerifySmsApiResponse,
  IVerifySmsCodeBody,
} from './verifySmsCodeTypes';

export async function verifySmsCode(
  mobilePhone: string,
  code: string
): Promise<IVerifyCodeReturn> {
  // ***** Redux State *****
  const state = store.getState();
  const borrower = state.borrowerReducer;
  const firstName = state.borrowerReducer.personalDetails.firstName ?? '';

  // ***** Request *****
  const url = '/api/verify';
  const body: IVerifySmsCodeBody = { mobilePhone, code, borrower, firstName };
  const response = await postUrl<IVerifySmsApiResponse>(url, body);

  // ***** Use Response *****
  if (
    response.token &&
    response.status === EVerificationResponseStatus.SUCCESSFUL
  ) {
    setLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN, response.token);
  } else {
    removeLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);
    removeLocalStorageItem(ELocalStorageVariables.FIRST_NAME);
  }

  // ***** Return *****
  return {
    status: response.status,
  };
}
