import { useEffect, useState } from 'react';

import { createOrUpdateBorrower, getBorrower } from '@/apiCalls/borrower';
import {
  EVerificationResponseStatus,
  IVerifyCodeReturn,
  sendEmailCode,
  sendSmsCode,
  verifyEmailCode,
  verifySmsCode,
} from '@/apiCalls/verification';
import { Button, EButtonType } from '@/components/Button';
import { Input } from '@/components/Input';
import { selectEmail, selectMobile } from '@/context/borrower';
import {
  EDisplays,
  EModals,
  selectDisplay,
  selectModal,
  selectPartnerName,
  setDisplay,
  setModal,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import {
  EUserLoginOption,
  selectMobileOrEmail,
  setIsLoggedIn,
} from '@/context/user';
import { SOLAR_DEALER_NAME } from '@/partners/solar';
import { isEmail } from '@/utils/isEmail';
import { isMobile } from '@/utils/isMobile';

export interface IProps {
  baseId: string;
  showErrors: boolean;
  disabled?: boolean;
}

export const testId = 'LoginCode';

// Todo: Separate the login code and the login button into separate components
export function LoginCode({
  baseId,
  showErrors,
  disabled = false,
}: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectEmail);
  const dispatch = useAppDispatch();
  const loginMethod = useAppSelector(selectMobileOrEmail);
  const mobileNumber = useAppSelector(selectMobile);
  const email = useAppSelector(selectEmail);
  const display = useAppSelector(selectDisplay);
  const modal = useAppSelector(selectModal);
  const partnerNameDisplay = useAppSelector(selectPartnerName);
  const solar = partnerNameDisplay === SOLAR_DEALER_NAME;

  // ***** Local State *****
  const [code, setCode] = useState<string>('');
  const [invalidCode, setInvalidCode] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [checking, setChecking] = useState<boolean>(false);

  useEffect(() => {
    if (code?.length !== 6) return;
    // todo: dispatch check code
    checkLoginCode();
  }, [code]);

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    setInvalidCode(false);
    setError(false);
    if (typeof value === 'boolean') return;
    if (value === currentState) return;
    if (value?.length > 6) return;
    setCode(value);
  }

  async function handleClick(): Promise<void> {
    let code: EVerificationResponseStatus | void = undefined;

    if (loginMethod === EUserLoginOption.MOBILE) {
      code = await loginViaMobile();
    } else {
      code = await loginViaEmail();
    }
    useCode(code);
  }

  function login(): void {
    dispatch(setModal(null));
    dispatch(setIsLoggedIn(true));

    if (modal === EModals.LOGIN) {
      getBorrower();
    } else {
      createOrUpdateBorrower();
    }

    const nextScreen = getNextScreen();
    if (!nextScreen) return;
    dispatch(setDisplay(nextScreen));
  }

  function getNextScreen(): EDisplays | void {
    // Solar Your Details
    if (solar && display === EDisplays.SOLAR_YOUR_DETAILS) {
      return EDisplays.SOLAR_IDENTIFICATOIN;
    }

    // Solar
    if (solar) {
      return EDisplays.SOLAR_LOAN_DETAILS;
    }

    // Normal Identification
    if (display === EDisplays.SERVICING_SUCCESS) {
      return EDisplays.IDENTIFICATION;
    }

    // Normal return void
  }

  // ***** Helpers ******
  async function loginViaMobile(): Promise<EVerificationResponseStatus | void> {
    if (!isMobile(mobileNumber)) return;
    if (mobileNumber === null) return;
    if (mobileNumber === undefined) return;
    setSending(true);

    const response = await sendSmsCode(mobileNumber);
    setSending(false);
    setSent(true);

    return response;
  }

  async function loginViaEmail(): Promise<EVerificationResponseStatus | void> {
    if (!isEmail(email)) return;
    if (email === null) return;
    if (email === undefined) return;
    setSending(true);

    const response = await sendEmailCode(email);
    setSending(false);
    setSent(true);

    return response;
  }

  // Same functionality for both loginViaMobile and loginViaEmail
  function useCode(code: EVerificationResponseStatus | void): void {
    if (code === EVerificationResponseStatus.SUCCESSFUL) {
      setSent(true);
      return;
    }

    if (code === EVerificationResponseStatus.INCORRECT_VERIFICATION_CODE) {
      setInvalidCode(true);
      return;
    }

    setError(true);
    return;
  }

  async function checkLoginCode(): Promise<void> {
    if (code?.length !== 6) return;

    let response: void | IVerifyCodeReturn = undefined;
    if (loginMethod === EUserLoginOption.MOBILE) {
      response = await checkLoginViaMobile();
    } else {
      response = await checkLoginViaEmail();
    }

    useVerificationCode(response?.status);
  }

  // Same functionality for both checkLoginViaMobile and checkLoginViaEmail
  function useVerificationCode(code: EVerificationResponseStatus | void): void {
    if (code === EVerificationResponseStatus.SUCCESSFUL) {
      login();
    }

    if (code === EVerificationResponseStatus.INCORRECT_VERIFICATION_CODE) {
      setInvalidCode(true);
      return;
    }

    setError(true);
    return;
  }

  async function checkLoginViaEmail(): Promise<IVerifyCodeReturn | void> {
    if (!isEmail(email)) return;
    if (email === null) return;
    if (email === undefined) return;

    setChecking(true);

    const response = await verifyEmailCode(email, code);
    setChecking(false);

    return response;
  }

  async function checkLoginViaMobile(): Promise<IVerifyCodeReturn | void> {
    if (!isMobile(mobileNumber)) return;
    if (mobileNumber === null) return;
    if (mobileNumber === undefined) return;

    setChecking(true);

    const response = await verifySmsCode(mobileNumber, code);
    setChecking(false);

    return response;
  }

  function isReadyToSendCode(): boolean {
    // Is Disabled
    if (disabled) return false;
    // Is Not Sending
    if (sending) return false;
    // Is Not Sent
    if (sent) return false;

    // Mobile Valid
    if (loginMethod === EUserLoginOption.MOBILE) {
      if (mobileNumber === null) return false;
      return isMobile(mobileNumber);
    }

    // Email Valid
    if (loginMethod === EUserLoginOption.EMAIL) {
      if (email === null) return false;
      return isEmail(email);
    }

    // No Login Method
    return false;
  }

  // ***** Render *****
  return (
    <div data-testid={testId} className='flex flex-col'>
      <div className='flex space-x-2'>
        <div className='py-2 mx-auto w-full max-w-md'>
          <h1 className='fieldHeading'>CLICK TO SEND CODE</h1>
          <div className='flex'>
            <Button
              onClick={handleClick}
              type={
                isReadyToSendCode() ? EButtonType.PRIMARY : EButtonType.DISABLED
              }
            >
              <p> {sent ? 'CODE SENT' : 'SEND CODE'}</p>
            </Button>
          </div>
        </div>
        <Input
          title='6 Digit Code'
          placeholder='XXXXXX'
          showErrors={showErrors}
          onChange={handleChange}
          id={`${baseId}-input-email`}
          value={code}
          disabled={!sent && !disabled}
        />
      </div>
      {invalidCode && <p className='text-red-500 text-center'>Invalid Code</p>}
      {sending && <p className=' text-center'>Sending...</p>}
      {error && (
        <p className='text-red-500 text-center'>Error, please try again</p>
      )}
      {checking && <p className='text-center'>Checking ...</p>}
    </div>
  );
}
