import { Button, EButtonType } from '@/components/Button';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import { ELoanUsage, EVehicleAssetType, selectLoanUsage, selectVehicleAssetType } from '@/context/borrower';
import { EDisplays, setDisplay } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { SellerType } from '@/UserInput/SellerType';

export const testId = 'Seller';

export function Seller(): JSX.Element {
  // ***** CONSTANTS *****
  const baseId = 'screen-sellerDetails';

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const loanUsage = useAppSelector(selectLoanUsage);
  const vehicleAssetType = useAppSelector(selectVehicleAssetType);

  // ***** Event Handlers *****
  function handleBackClick(): void {
    const backScreen = getBackScreen();
    dispatch(setDisplay(backScreen));
  }

  function handleNextClick(): void {
    const nextScreen = getNextScreen();
    dispatch(setDisplay(nextScreen));
  }

  function getNextScreen(): EDisplays {
    if (loanUsage === ELoanUsage.BUSINESS) return EDisplays.BUSINESS;
    return EDisplays.BASIC;
  }

  function getBackScreen(): EDisplays {
      if (loanUsage === ELoanUsage.BUSINESS && vehicleAssetType !== EVehicleAssetType.CAR_VAN_OR_UTE) {
          return EDisplays.MANUAL_VEHICLE;
      }
    return EDisplays.VEHICLE;
  }

  return (
    <div data-testid={testId}>
      <Header />
      <ProgressBar />
      <div className='flex'>
        <Button
          onClick={handleBackClick}
          type={EButtonType.SECONDARY_OUTLINE}
          square
        >
          Back
        </Button>
        <Button onClick={handleNextClick} square>
          Next
        </Button>
      </div>
      <div className='px-4'>
        <DisplayHeading
          title="Seller's Details"
          infoText='To confirm the quote, we will need some information on the seller'
        />
        <SellerType baseId={baseId} showErrors={false} />
      </div>
    </div>
  );
}
