import { ContactBox } from '@/components/ContactBox';
import { RemoteImage } from '@/components/RemoteImage';
import { selectFirstName } from '@/context/borrower';
import { useAppSelector } from '@/context/storeHooks';

export const testId = 'ApplicationInProgress';

export function ApplicationInProgress(): JSX.Element {
  // ***** Redux State *****
  const firstName = useAppSelector(selectFirstName);

  // ***** Render *****
  return (
    <div
      data-testid={testId}
      className='flex flex-col justify-between  py-2 px-8'
    >
      <div className='flex-center pt-4 pb-10 relative'>
        <div className='bg-primary h-40 w-40 rounded-full' />
        <RemoteImage fileName='document.svg' className='absolute h-36 w-36' />
      </div>
      <h3 className='text-center font-bold text-3xl pb-4 text-primary'>
        Hey, {firstName}
      </h3>
      <p className='pb-4 font-semi-bold text-lg text-center'>
        We&apos;ve got your application and it&apos;s in progress with the
        lender
      </p>
      <ContactBox />
    </div>
  );
}
