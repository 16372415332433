import { setVehicleStockNumber } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialVehicleStockNumber(
  stockNumberParam: IParameter
): void {
  if (!stockNumberParam) {
    return;
  }

  const stateStockNumber =
    store.getState()?.borrowerReducer?.vehicle?.stockNumber;
  if (stateStockNumber !== stockNumberParam) {
    store.dispatch(setVehicleStockNumber(stockNumberParam));
  }
}
