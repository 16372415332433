import { EPartners } from '@/partners/partnerTypes';

import { EState } from './commonTypes';

export interface IVehicle {
  vin?: string | undefined;
  nvic?: string | undefined;
  buildYear?: number | undefined;
  make?: string | undefined;
  model?: string | undefined;
  variant?: string | undefined;
  description?: string | undefined;
  isVehicleKnown: boolean;
  kilometers?: number | undefined;
  registrationNumber?: string | undefined;
  registrationState?: EState | undefined;
  isRegistrationKnown: boolean;
  assetType: EVehicleAssetType;
  sourcePrice?: number | undefined;
  url?: string | undefined;
  stockNumber?: string | undefined;
  id?: string | undefined;
  glassValuation?: number | undefined;
  source?: EPartners | undefined;
  condition?: ECondition | undefined;
  sourceYardId?: string | undefined;
}

export enum ECondition {
  NEW = 'NEW',
  USED = 'USED',
  DEMO = 'DEMO',
}

export enum EVehicleAssetType {
  CAR_VAN_OR_UTE = 'Car, Van or Ute',
  TRUCK = 'Truck',
  PRIME_MOVER = 'Prime Mover',
}

export const initialVehicle: IVehicle = {
  isVehicleKnown: false,
  isRegistrationKnown: false,
  assetType: EVehicleAssetType.CAR_VAN_OR_UTE,
};
