import { PayloadAction } from '@reduxjs/toolkit';

import { EDisplays, EModals, IDisplaySlice } from '../types';

import { glassGuideReducers } from './glassGuideReducers';
import { partnerReducers } from './partnerReducers';

export interface DisplayReducers {
  [key: string]: (state: IDisplaySlice, action: PayloadAction<any>) => void;
}

export const displayReducers: DisplayReducers = {
  setDisplay: (state, action: PayloadAction<EDisplays>) => {
    state.display = action.payload;
  },
  setModal: (state, action: PayloadAction<EModals | null>) => {
    state.modal = action.payload;
  },
  setIsLoaded: (state, action: PayloadAction<boolean>) => {
    state.isLoaded = action.payload;
  },
  ...partnerReducers,
  ...glassGuideReducers,
};
