import axios from 'axios';

import { API_URL } from '@/utils/constants';
import {
  ELocalStorageVariables,
  getLocalStorageItem,
} from '@/utils/localStorage';

function getBackendUrl(url: string): string {
  const backendUrl = `${API_URL}${url}`;
  return backendUrl;
}

export function getUrl<T>(url: string): Promise<T> {
  const token = getLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);

  const request = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const backendUrl = getBackendUrl(url);
  return request.get(backendUrl);
}

export async function postUrl<T>(url: string, body: unknown): Promise<T> {
  const token = getLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);

  const request = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const backendUrl = getBackendUrl(url);

  const response = await request.post<T>(backendUrl, body);

  return response.data;
}

export function deleteUrl(url: string): Promise<Response> {
  const token = getLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);

  const request = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const backendUrl = getBackendUrl(url);
  return request.delete(backendUrl);
}
