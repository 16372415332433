import {
  EState,
  setAddressByIndex,
  setPersonalDetails,
} from '@/context/borrower';
import { setIsOcrLoading } from '@/context/display';
import { store } from '@/context/store';

import { postUrl } from '../fetch';

import { IOcrLookupRequest, IOcrLookupResponse } from './ocrLookupTypes';

export async function ocrLookup(): Promise<void> {
  console.log('ocrLookup()');
  // ***** Redux State *****
  const state = store.getState();
  const driverLicenseFrontArray =
    state.borrowerReducer.supportingDocs?.app_drivers_licence_front;

  if (!driverLicenseFrontArray) {
    return;
  }

  const driverLicenseFront = driverLicenseFrontArray[0].url;

  if (!driverLicenseFront) {
    return;
  }

  // ***** The Request *****
  const url = '/api/ocr/driverlicense';

  const body: IOcrLookupRequest = {
    type: 'url',
    frontUrl: driverLicenseFront,
  };

  const response = await postUrl<IOcrLookupResponse>(url, body);
  store.dispatch(setIsOcrLoading(true));

  // ***** Use the Response *****

  const personalDetails = state.borrowerReducer.personalDetails;
  store.dispatch(
    setPersonalDetails({
      ...personalDetails,
      firstName: response.firstName
        ? response.firstName
        : personalDetails.firstName,
      middleName: response.middleName
        ? response.middleName
        : personalDetails.middleName,
      lastName: response.lastName
        ? response.lastName
        : personalDetails.lastName,
      licenseNumber: response.licenseNumber
        ? response.licenseNumber
        : personalDetails.licenseNumber,
      licenseExpiry: response.licenseDateOfExpiry
        ? response.licenseDateOfExpiry
        : personalDetails.licenseExpiry,
      birthdate: response.dateOfBirth
        ? response.dateOfBirth
        : personalDetails.birthdate,
    })
  );

  const address = state.borrowerReducer.address.address[0];
  store.dispatch(
    setAddressByIndex({
      index: 0,
      address: {
        ...address,
        unitNumber: response.addressUnitNumber
          ? response.addressUnitNumber
          : address.unitNumber,
        streetNumber: response.addressStreetNumber
          ? response.addressStreetNumber
          : address.streetNumber,
        streetName: response.addressStreetName
          ? response.addressStreetName
          : address.streetName,
        suburb: response.addressSuburb
          ? response.addressSuburb
          : address.suburb,
        state: response.addressState
          ? (response.addressState as EState) // todo: Map this response to EState properly
          : address.state,
        postcode: response.addressPostcode
          ? response.addressPostcode
          : address.postcode,
      },
    })
  );

  // Note: response.licenseClass and response.licenseType is not saved

  store.dispatch(setIsOcrLoading(false));
}
