import { useState } from 'react';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button, EButtonType } from '@/components/Button';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import {
  selectCreditCards,
  selectHomeandContents,
  selectMotorVehicles,
  selectOther,
  selectPersonalLoans,
  selectPlantandEquipment,
  selectRealEstates,
  selectSavings,
  selectSelectedLender,
  selectSuperandShares,
} from '@/context/borrower';
// eslint-disable-next-line no-restricted-imports
import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import {
  isAllCreditCardsComplete,
  isAllMotorVehicleAssetsComplete,
  isAllPersonalLoansComplete,
  isAllRealEstatesComplete,
} from '@/utils/checkAssetsAndLiabilities';
import { CheckCircleIcon, PlusCircleIcon } from '@heroicons/react/20/solid';

function AssetsAndLiablities(): JSX.Element {
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState(false);

  // ***** CONSTANTS *****
  const baseId = 'screen-basic';

  // ***** Redux State *****
  const dispatch = useAppDispatch();

  const realEstates = useAppSelector(selectRealEstates);
  const motorVehicles = useAppSelector(selectMotorVehicles);
  const creditCards = useAppSelector(selectCreditCards);
  const personalLoans = useAppSelector(selectPersonalLoans);

  // Savings and more section
  const plantAndEqu = useAppSelector(selectPlantandEquipment);
  const homeAndContent = useAppSelector(selectHomeandContents);
  const savings = useAppSelector(selectSavings);
  const superAndShares = useAppSelector(selectSuperandShares);
  const others = useAppSelector(selectOther);
  const selectedLender = useAppSelector(selectSelectedLender);

  const tickIconSavingsOrMore =
    plantAndEqu || homeAndContent || savings || superAndShares || others
      ? true
      : false;

  const savingsAndMoreArray = [
    plantAndEqu,
    homeAndContent,
    savings,
    superAndShares,
    others,
  ];

  const errorCss = 'bg-amber-300 border-primary';

  // ***** Event Handlers *****
  function handleBackClick(): void {
    dispatch(setDisplay(EDisplays.EMPLOYMENT));
  }

  function handleNextClick(): void {
    setShowErrors(true);
    if (!isComplete()) return;
    createOrUpdateBorrower();
    dispatch(setDisplay(getNextScreen()));
  }

  // ***** Helpers *****
  function isComplete(): boolean {
    if (!isAllRealEstatesComplete(true)) return false;
    if (!isAllPersonalLoansComplete(true)) return false;
    if (!isAllMotorVehicleAssetsComplete(true)) return false;
    if (!isAllCreditCardsComplete(true)) return false;

    return true;
  }

  function getNextScreen(): EDisplays {
    return EDisplays.SUPPORTING_DOCS;
  }

  const handleOnClickRealEstate = (): void => {
    dispatch(setModal(EModals.REAL_ESTATES));
  };
  const handleOnClickMotorVehicles = (): void => {
    dispatch(setModal(EModals.MOTOR_VEHICLES_ASSETS));
  };
  const handleOnClickSavings = (): void => {
    dispatch(setModal(EModals.SAVINGS_OR_MORE));
  };
  const handleOnClickCreditCard = (): void => {
    dispatch(setModal(EModals.CREDIT_CARDS));
  };
  const handleOnClickPersonalLoans = (): void => {
    dispatch(setModal(EModals.PERSONAL_LOANS));
  };

  const assetsAndLiabilities = [
    {
      name: 'Real Estate',
      item: realEstates,
      onClick: handleOnClickRealEstate,
      tickIcon: isAllRealEstatesComplete(),
      isEmpty: realEstates?.length === 0,
    },
    {
      name: 'Motor Vehicle',
      item: motorVehicles,
      onClick: handleOnClickMotorVehicles,
      tickIcon: isAllMotorVehicleAssetsComplete(),
      isEmpty: motorVehicles?.length === 0,
    },
    {
      name: 'Savings and More',
      item: savingsAndMoreArray,
      onClick: handleOnClickSavings,
      tickIcon: tickIconSavingsOrMore,
      isEmpty: true,
    },
    {
      name: 'Credit Cards',
      item: creditCards,
      onClick: handleOnClickCreditCard,
      tickIcon: isAllCreditCardsComplete(),
      isEmpty: creditCards?.length === 0,
    },
    {
      name: 'Personal Loans',
      item: personalLoans,
      onClick: handleOnClickPersonalLoans,
      tickIcon: isAllPersonalLoansComplete(),
      isEmpty: personalLoans?.length === 0,
    },
  ];
  if (!selectedLender) {
    return <div />;
  }

  return (
    <div data-testid={baseId} className='px-4'>
      <Header />
      <ProgressBar />

      <div className='flex '>
        <Button
          onClick={handleBackClick}
          type={EButtonType.SECONDARY_OUTLINE}
          square
        >
          Back
        </Button>
        <Button onClick={handleNextClick} square>
          Next
        </Button>
      </div>

      <div>
        <DisplayHeading
          title='Assets and Liabilities'
          infoText='Please give information about your assets and liabilities'
        />

        <div className=' border rounded-lg m-5 bg-white hover:cursor-pointer'>
          {assetsAndLiabilities.map((item, index) => (
            <div
              className={`flex justify-between align-center  p-4 border
               ${!item.tickIcon && !item.isEmpty && errorCss}`}
              onClick={item.onClick}
              key={index}
            >
              <div className=''>{item.name}</div>
              <div className={`flex-center `}>
                {item.tickIcon ? (
                  <CheckCircleIcon className='h-5 w-5 text-success' />
                ) : (
                  <PlusCircleIcon className='h-5 w-5 text-primary' />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export { AssetsAndLiablities };
