export interface IAssetsNavigationButtonsProps {
  handleDeleteClick: () => void;
  handleNextClick: () => void;
  handlePreviousClick: () => void;
  hasPrevious: boolean;
  hasNext: boolean;
}

export function AssetsNavigationButtons({
  handleDeleteClick,
  handleNextClick,
  handlePreviousClick,
  hasPrevious,
  hasNext,
}: IAssetsNavigationButtonsProps): JSX.Element {
  return (
    <div className='flex justify-around'>
      <button
        className='text-secondary'
        onClick={handlePreviousClick}
        disabled={hasPrevious === false}
      >
        <p className={hasPrevious === false ? 'text-gray-500' : ''}>Previous</p>
      </button>
      <button className='text-secondary' onClick={handleNextClick}>
        {hasNext ? 'Next' : 'Add Another'}
      </button>
      <button className='text-primary' onClick={handleDeleteClick}>
        <p>Delete</p>
      </button>
    </div>
  );
}
