import { CheckBox } from '@/components/CheckBox';
import { selectSellerName } from '@/context/borrower';
import {
  selectAgreedToTermsAndConditions,
  selectTermsAndConditionsLink,
  setAgreedToTermsAndConditions,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { SOLAR_DEALER_NAME } from '@/partners/solar';

export function TermsAndConditions(): JSX.Element {
  // ***** Redux State *****
  const sellerName = useAppSelector(selectSellerName);
  const solar = sellerName === SOLAR_DEALER_NAME;
  const agreedToTermsAndConditions = useAppSelector(
    selectAgreedToTermsAndConditions
  );
  const termsLink = useAppSelector(selectTermsAndConditionsLink);
  const dispatch = useAppDispatch();

  // ***** Handlers *****
  function handleCheckBoxClick(): void {
    dispatch(setAgreedToTermsAndConditions(!agreedToTermsAndConditions));
  }

  function handleTermsAndConditionsClick(): void {
    window.open(termsLink);
  }

  return (
    <div className='flex space-x-2 py-2'>
      <CheckBox
        onClick={handleCheckBoxClick}
        checked={agreedToTermsAndConditions}
      />

      <div className='uppercase text-xs'>
        I have read & agreed to the{' '}
        <span
          className={`${
            solar ? 'text-blue-500' : 'text-secondary'
          } underline hover:cursor-pointer hover:text-secondaryDark `}
        >
          <span onClick={handleTermsAndConditionsClick}>
            Terms & Conditions
          </span>
        </span>
      </div>
    </div>
  );
}
