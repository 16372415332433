import { useState } from 'react';

import { Combobox, IComboboxOption } from '@/components/Combobox';
import {
  ETrusteeType,
  selectTrusteeType,
  setTrusteeType,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'TrusteeType';

export function TrusteeType({ baseId, showErrors }: IProps): JSX.Element {
  // Initial options
  const options: IComboboxOption[] = [
    { id: 1, value: ETrusteeType.COMPANY },
    { id: 2, value: ETrusteeType.INDIVIDUAL },
  ];

  const currentState = useAppSelector(selectTrusteeType);
  const dispatch = useAppDispatch();

  // *****State*****
  const [query, setQuery] = useState<undefined | string>(currentState ?? ''); // Keep track of search

  // *****Functions*****
  // Handle the onChange event for the input.
  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setQuery(value);
    if (typeof value === 'boolean') return;
    if (value === currentState) return;
    if (value?.length > 3) return;
  }

  // When the user selects an option
  function handleSelect(option: IComboboxOption): void {
    // logic for selecting an option
    if (option.value === currentState) return;
    dispatch(setTrusteeType(option.value as ETrusteeType));
  }

  // *****Render*****
  return (
    <div data-testid={testId} className='w-full'>
      <Combobox
        options={options}
        placeholder='Trustee Type'
        heading='Trustee Type'
        onChange={handleChange}
        query={query ?? ''}
        onSelect={handleSelect}
        showErrors={showErrors && !currentState}
      />
    </div>
  );
}
