import { useState } from 'react';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button, EButtonType } from '@/components/Button';
import { DisplayHeading } from '@/components/DisplayHeading';
import { DocumentUpload } from '@/components/DocumentUpload';
import { Header } from '@/components/Header';
import { SolarNavigationButtons } from '@/components/SolarNavigationButtons';
import { SolarProgressBar } from '@/components/SolarProgressBar';
import { ESupportingDocCodes, selectSupportingDocs } from '@/context/borrower';
import { EDisplays, selectShowOcrDisplay, setDisplay } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export const baseId = 'screen-SolarIdentification';

export function SolarIdentification(): JSX.Element {
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState(false);

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const supportingDocs = useAppSelector(selectSupportingDocs);
  const frontLicense = supportingDocs?.app_drivers_licence_front
    ? supportingDocs?.app_drivers_licence_front
    : null;
  const backLicense = supportingDocs?.app_drivers_licence_back
    ? supportingDocs?.app_drivers_licence_back
    : null;
  const showOcr = useAppSelector(selectShowOcrDisplay);

  // ***** Event Handlers *****
  function handleBackClick(): void {
    const backScreen = getBackScreen();
    dispatch(setDisplay(backScreen));
  }

  function handleNextClick(): void {
    setShowErrors(true);
    if (!isComplete()) return;
    createOrUpdateBorrower();
    const nextScreen = getNextScreen();
    dispatch(setDisplay(nextScreen));
  }

  function handleSkipClick(): void {
    const nextScreen = getNextScreen();
    createOrUpdateBorrower();
    dispatch(setDisplay(nextScreen));
  }

  // ***** Helpers ******
  function isComplete(): boolean {
    return true;
  }

  function getNextScreen(): EDisplays {
    if (showOcr) {
      return EDisplays.SOLAR_OCR_SUMMARY;
    }

    return EDisplays.SOLAR_OCR_SUMMARY;
  }

  function getBackScreen(): EDisplays {
    return EDisplays.SOLAR_YOUR_DETAILS;
  }

  // ***** Render *****
  return (
    <>
      <div data-testid={baseId} className='px-4 h-screen-92'>
        <Header />
        <SolarProgressBar />

        <div className='px-4'>
          <DisplayHeading
            title='Identification'
            infoText='We are required to get some identification from you. You can choose to skip this for now, but we will need it before you can complete your application.'
          />
          <p className='uppercase text-center text-xs font-bold'>
            Upload your driver&apos;s license
          </p>
          <DocumentUpload
            baseId={baseId}
            showErrors={showErrors}
            code={ESupportingDocCodes.DRIVERS_LICENSE_FRONT}
            document={frontLicense}
            value="Driver's License Front"
          />
          <DocumentUpload
            baseId={baseId}
            showErrors={showErrors}
            code={ESupportingDocCodes.DRIVERS_LICENSE_BACK}
            document={backLicense}
            value="Driver's License Back"
          />
          <p className='my-7 text-center text-sm text-blue-500'>
            Once uploaded, we will pre-fill your Full Name, Birthday, Address
            and Drivers License Number
          </p>
          <div className='mt-4 flex justify-center align-middle '>
            <Button
              onClick={handleSkipClick}
              type={EButtonType.ROUNDED_FULL_SECONDARY_OUTLINE}
            >
              <p>I&apos;ll do it later</p>
            </Button>
          </div>
        </div>
      </div>

      <SolarNavigationButtons
        handleBackClick={handleBackClick}
        handleNextClick={handleNextClick}
      />
    </>
  );
}
