import {
  EApplicationAssetType,
  ELoanType,
  ELoanUsage,
  EMoneyType,
  ESellerType,
} from '@/context/borrower';

import { DEFAULT_PARTNER } from './defaultPartner';
import { EPartners, IPartnerParams } from './partnerTypes';

export const SOLAR_DEALER_NAME = 'Solar';

export const SOLAR_PARTNER: IPartnerParams = {
  ...DEFAULT_PARTNER,
  id: EPartners.SOLAR,
  loanUsage: ELoanUsage.BUSINESS,
  // logo: '/logos/carbids1.svg',
  logo: 'solar.svg',
  whiteLabel: true,
  dealerName: SOLAR_DEALER_NAME,
  primaryColor: '#FFCA45',
  secondaryColor: '#447cf9',
  dealerEmail: 'info@commercialsolarfinance.com',
  dealerPhone: '(03) 7009 0767',
  type: ESellerType.SOLAR,
  termsAndConditionsLink: 'https://www.vroom.finance/privacysolarfinance',
  initialLoanType: ELoanType.LEASE_RENTAL,
  initialLoanAssetType: EApplicationAssetType.SOLAR_PANELS,
  financeTypeCommission: {
    defaultValue: 200,
    defaultValueType: EMoneyType.DOLLAR,
  },
};

// logo: '/frontend/src/assets/solar.svg',
