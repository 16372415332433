/* eslint-disable no-restricted-imports */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  selectSellerName,
  selectVehicleBuildYear,
  selectVehicleMake,
  selectVehicleModel,
  selectVehicleVariant,
} from '@/context/borrower';
import {
  setGlassOptionsForVehicle,
  setVehicleMakeApiResults,
  setVehicleModelApiResults,
  setVehicleVariantApiResults,
} from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';
import { CHERY_SELLER_NAME } from '@/partners/chery';
import {
  getVehicleGlassOptions,
  getVehicleMakeOptions,
  getVehicleModelOptions,
  getVehicleVariantOptions,
} from '@/services/AssetService';
import { BuildYear } from '@/UserInput/BuildYear';
import { VehicleMake } from '@/UserInput/VehicleMake';
import { VehicleModel } from '@/UserInput/VehicleModel';
import { VehicleVariant } from '@/UserInput/VehicleVariant';

import { DisplayGlassOptions } from '../DisplayVehicleResults/DisplayGlassOptions/DisplayGlassOptions';

export const testId = 'VehicleDetailsRegoUnKnown';

export interface IVehicleDetailsRegoUnknownProps {
  showErrors: boolean;
}

export function VehicleDetailsRegoUnKnown({
  showErrors,
}: IVehicleDetailsRegoUnknownProps): JSX.Element {
  // *** Local State ***

  const [loadingGlassOptions, setLoadingGlassOptions] = useState(false);

  // ** Redux State ***
  const baseId = 'screen-VehicleDetailsRegoUnknown';
  const dispatch = useDispatch();
  const buildYear = useAppSelector(selectVehicleBuildYear);
  const make = useAppSelector(selectVehicleMake);
  const model = useAppSelector(selectVehicleModel);
  const variant = useAppSelector(selectVehicleVariant);
  const sellerName = useAppSelector(selectSellerName);

  useEffect(() => {
    // make API call and update state with returned data
    if (buildYear) {
      // make API call for vehicle make options
      const apiCall = async (): Promise<void> => {
        await getVehicleMakeOptions(buildYear).then((response) => {
          const castedOptionsForMake = response.map((value, index) => ({
            id: index + 1,
            value: (value as { name: string }).name,
          }));
          dispatch(setVehicleMakeApiResults(castedOptionsForMake));
        });
      };
      apiCall();
    }
  }, [buildYear]);
  useEffect(() => {
    if (make) {
      // make API call for vehicle model options
      const apiCall = async (): Promise<void> => {
        await getVehicleModelOptions(buildYear, make).then((response) => {
          const castedOptionsForModel = response.map((value, index) => ({
            id: index + 1,
            value: (value as { name: string }).name,
          }));
          dispatch(setVehicleModelApiResults(castedOptionsForModel));
        });
      };
      apiCall();
    }
  }, [make]);
  useEffect(() => {
    if (model) {
      // make API call for vehicle variant options
      const apiCall = async (): Promise<void> => {
        await getVehicleVariantOptions(buildYear, make, model).then(
          (response) => {
            const castedOptionsForVariant = response.map((value, index) => ({
              id: index + 1,
              value: value,
            }));
            dispatch(setVehicleVariantApiResults(castedOptionsForVariant));
          }
        );
      };
      apiCall();
    }
  }, [model]);
  useEffect(() => {
    if (variant) {
      // make API call for vehicle description options
      const apiCall = async (): Promise<void> => {
        setLoadingGlassOptions(true);
        await getVehicleGlassOptions(buildYear, make, model, variant).then(
          (response) => {
            if (response === undefined) return;
            dispatch(setGlassOptionsForVehicle(response));
            setLoadingGlassOptions(false);
          }
        );
      };
      apiCall();
    }
  }, [variant]);

  return (
    <div>
      <BuildYear baseId={baseId} showErrors={showErrors}></BuildYear>

      <div className='pt-2'>
        <VehicleMake baseId={baseId} showErrors={showErrors}></VehicleMake>
      </div>
      <div className='pt-2'>
        <VehicleModel baseId={baseId} showErrors={showErrors}></VehicleModel>
      </div>
      <div className='pt-2'>
        <VehicleVariant
          baseId={baseId}
          showErrors={showErrors}
        ></VehicleVariant>
      </div>

      {sellerName !== CHERY_SELLER_NAME && (
        <DisplayGlassOptions loadingGlassOptions={loadingGlassOptions} />
      )}
    </div>
  );
}
