export enum EMatchingEngineLenders {
    PEPPER = 'PEPPER',
    LATITUDE_FINANCE = 'LATITUDE_FINANCE',
    PLENTI = 'PLENTI',
    WISR_CAR_LOAN = 'WISR_CAR_LOAN',
    BRANDED_FINANCIAL_SERVICES = 'BRANDED_FINANCIAL_SERVICES',
    NISSAN_FINANCE = 'NISSAN_FINANCE',
    LIBERTY = 'LIBERTY',
    ANGLE = 'ANGLE',
    AUTOPAY_EXTERNAL = 'AUTOPAY_EXTERNAL',
    VOLKSWAGEN_FINANCIAL_SERVICES = 'VOLKSWAGEN_FINANCIAL_SERVICES',
    FLEXI = 'FLEXI',
    FINANCE_ONE = 'FINANCE_1',
    METRO = 'METRO'
}

export const ALL_MATCHING_ENGINE_LENDERS: EMatchingEngineLenders[] = [
    EMatchingEngineLenders.PEPPER,
    EMatchingEngineLenders.LATITUDE_FINANCE,
    EMatchingEngineLenders.PLENTI,
    EMatchingEngineLenders.WISR_CAR_LOAN,
    EMatchingEngineLenders.BRANDED_FINANCIAL_SERVICES,
    EMatchingEngineLenders.NISSAN_FINANCE,
    EMatchingEngineLenders.LIBERTY,
    EMatchingEngineLenders.ANGLE,
    EMatchingEngineLenders.AUTOPAY_EXTERNAL,
    EMatchingEngineLenders.VOLKSWAGEN_FINANCIAL_SERVICES,
    EMatchingEngineLenders.FLEXI,
    EMatchingEngineLenders.FINANCE_ONE,
    EMatchingEngineLenders.METRO,
];

export const DEFAULT_MATCHING_ENGINE_LENDERS: EMatchingEngineLenders[] = [
    EMatchingEngineLenders.PEPPER,
    EMatchingEngineLenders.LATITUDE_FINANCE,
    EMatchingEngineLenders.PLENTI,
    EMatchingEngineLenders.WISR_CAR_LOAN,
    EMatchingEngineLenders.AUTOPAY_EXTERNAL,
    EMatchingEngineLenders.VOLKSWAGEN_FINANCIAL_SERVICES,
];
