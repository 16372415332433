import { Fragment } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

const people: IListboxOption[] = [
  {
    value: 'Wade Cooper',
    id: 1,
  },
  {
    value: 'Arlene Mccoy',
    id: 2,
  },
  { value: 'Devon Webb', id: 3 },
  { value: 'Tom Cook', id: 4 },
  { value: 'Tanya Fox', id: 5 },
  { value: 'Hellen Schmidt', id: 6 },
];

export interface IListboxOptions {
  options: IListboxOption[];
  selected: number;
  handleChange: (setSelected: string) => void;
}

export interface IListboxOption {
  value: string;
  id: number;
}

export function Linkbox({
  options,
  selected,
  handleChange,
}: IListboxOptions): JSX.Element {
  function findActive(): IListboxOption | undefined {
    return options.find((option) => option.id === selected);
  }

  const active = findActive();

  if (!active) return <div />;

  return (
    <div className='w-full'>
      <Listbox
        value={selected}
        onChange={(value): void => {
          return handleChange(value.toLocaleString());
        }}
      >
        <div className='relative mt-1 w-full'>
          <Listbox.Button className='relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
            <span className='block truncate'>{active.value}</span>
            <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
              <ChevronUpDownIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20'>
              {options.map((option) => (
                <Listbox.Option
                  key={option.id}
                  className={`relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active.id === option.id
                      ? 'bg-amber-100 text-amber-900'
                      : 'text-gray-900'
                  }`}
                  value={option.value}
                >
                  {({ selected }): JSX.Element => (
                    <>
                      <span
                        className={`block truncate hover:cursor-pointer ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {option.value}
                      </span>
                      {selected ? (
                        <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600'>
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
