import { ELoanUsage, setProgressPercent } from '@/context/borrower';
import { EDisplays } from '@/context/display';
import { store } from '@/context/store';
import { SOLAR_DEALER_NAME } from '@/partners/solar';

export function getProgressPercent(): number {
  // 1. Get Display From State
  const state = store.getState();
  const display = state.displayReducer.display;
  const hideSellerScreen = state.displayReducer.hideSellerScreen;
  const supportingDocs = state.borrowerReducer.supportingDocs;
  const hideVehicleScreen = state.displayReducer.hideVehicleScreen;
  const isBusinessLoan =
    state.borrowerReducer.loan.loanUsage === ELoanUsage.BUSINESS;
  const isSolar = state.borrowerReducer.seller.name === SOLAR_DEALER_NAME;
  const currentProgressPercent =
    state.borrowerReducer.application.progressPercent;

  let newProgressPercent = 0;

  // 2. Get Progress Percent
  // General Rules
  // loan your asset lender suporting
  // ***** SOLAR FLOW *****
  if (isSolar) {
    switch (display) {
      case EDisplays.SOLAR_LOAN_DETAILS:
        newProgressPercent = 25;
        break;
      case EDisplays.SOLAR_YOUR_DETAILS:
      case EDisplays.SOLAR_OCR_SUMMARY:
      case EDisplays.SOLAR_IDENTIFICATOIN:
        newProgressPercent = 50;
        break;
      case EDisplays.SOLAR_ASSETS_AND_LIABILITIES:
        newProgressPercent = 75;
        break;
      case EDisplays.SOLAR_LENDER_SPECIFIC_QUESTIONS:
        newProgressPercent = 99;
        break;
      case EDisplays.SOLAR_SUPPORTING_DOCS:
        newProgressPercent = 99;
        break;

      default:
        newProgressPercent = 20;
        break;
    }
  } else {
    switch (display) {
      // ***** GENERAL FLOW *****
      // Loan -> Basic: 0-35%
      case EDisplays.LOAN:
        if (hideVehicleScreen && hideSellerScreen) {
          newProgressPercent = 20;
        } else if (hideVehicleScreen || hideSellerScreen) {
          newProgressPercent = 15;
        }
        newProgressPercent = 5;
        break;
      case EDisplays.MANUAL_VEHICLE:
      case EDisplays.VEHICLE:
        // Mutually exclusive displays, same progress
        // The next two screens are variable, so the progress here is variable
        if (hideSellerScreen && isBusinessLoan) newProgressPercent = 25;
        if (hideSellerScreen) newProgressPercent = 20;
        if (isBusinessLoan) newProgressPercent = 10;
        newProgressPercent = 13; // shouldn't ocur
        break;
      case EDisplays.SELLER:
        newProgressPercent = 20;
        break;
      case EDisplays.BUSINESS:
        newProgressPercent = 30;
        break;
      // Basic -> Preapproval: 35-70%
      case EDisplays.BASIC:
        newProgressPercent = 35;
        break;

      case EDisplays.INCOME:
        newProgressPercent = 45;
        break;
      case EDisplays.EXPENSE:
      case EDisplays.SERVICING_SUCCESS:
        // Same progress
        newProgressPercent = 55;
        break;
      case EDisplays.IDENTIFICATION:
        newProgressPercent = 65;
        break;
      case EDisplays.OCR_SUMMARY:
        newProgressPercent = 70;
        break;

      // Preapproval -> Complete: 70-95%
      case EDisplays.EMPLOYMENT:
        newProgressPercent = 75;
        break;
      case EDisplays.ASSETS_AND_LIABILITIES:
        newProgressPercent = 85;
        break;
      case EDisplays.SUPPORTING_DOCS:
        newProgressPercent = 95;
        break;

      // Default
      default:
        newProgressPercent = 35;
        break;
    }
  }

  // Update highestProgressPercent if currentProgressPercent is greater
  if (!currentProgressPercent || currentProgressPercent < newProgressPercent) {
    store.dispatch(setProgressPercent(newProgressPercent));
  }

  return newProgressPercent;
}
