import { postUrl } from '@/apiCalls/fetch';
import { setBorrower } from '@/context/borrower';
import { EDisplays, EModals, setModal } from '@/context/display';
import { store } from '@/context/store';
import {
  ELocalStorageVariables,
  getLocalStorageItem,
  setLocalStorageItem,
} from '@/utils/localStorage';

import {
  IGetBorrowerPostBody,
  IGetBorrowerReturn,
} from './createOrUpdateBorrowerTypes';

export async function createOrUpdateBorrower(): Promise<void> {
  console.log('createOrUpdateBorrower()');
  // ***** Redux *****
  const state = store.getState();
  const borrower = state.borrowerReducer;
  const isLoggedIn = state.userReducer.isLoggedIn;
  const token = getLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);
  const vehicle = state.borrowerReducer.vehicle;
  const seller = state.borrowerReducer.seller;
  const display = state.displayReducer.display;

  if (!token) {
    console.log('No token found in local storage');
    return;
  }
  if (!isLoggedIn) return;

  // ***** Request *****
  const url = '/api/borrower/createOrUpdate';

  const body: IGetBorrowerPostBody = {
    borrower,
  };

  //   const body = {};
  const response = await postUrl<IGetBorrowerReturn>(url, body);
  // ***** Update Redux *****
  console.log('response', response);
  const returnedBorrower = response.borrower;
  const returnedFirstName = response.borrower.personalDetails?.firstName; // To save to local storage

  if (returnedFirstName) {
    setLocalStorageItem(ELocalStorageVariables.FIRST_NAME, returnedFirstName);
  }

  // BE does not save these values
  returnedBorrower.address.addressIndex = 0;
  returnedBorrower.employments.currentEmploymentIndex = 0;
  returnedBorrower.personalLoans.personalLoanActiveIndex = 0;
  returnedBorrower.creditCards.creditCardActiveIndex = 0;
  returnedBorrower.motorVehicles.motorVehicleActiveIndex = 0;
  returnedBorrower.realEstates.realEstateActiveIndex = 0;

  if (returnedBorrower.personalLoans.personalLoans === undefined) {
    returnedBorrower.personalLoans.personalLoans = [];
  }

  if (returnedBorrower.creditCards.creditCards === undefined) {
    returnedBorrower.creditCards.creditCards = [];
  }
  if (borrower.personalLoans.personalLoans === undefined) {
    borrower.personalLoans.personalLoans = [];
  }

  if (borrower.creditCards.creditCards === undefined) {
    borrower.creditCards.creditCards = [];
  }

  if (returnedFirstName) {
    setLocalStorageItem(ELocalStorageVariables.FIRST_NAME, returnedFirstName);
  }

  if (returnedBorrower.creditCards.creditCards === undefined) {
    returnedBorrower.creditCards.creditCards = [];
  }

  // BE Would otherwise overwrite FE vehicle / seller set by FE params which is incorrect
  returnedBorrower.seller = seller;
  returnedBorrower.vehicle = vehicle;

  console.log('vehicle', vehicle);

  store.dispatch(setBorrower(returnedBorrower));

  // When a application is submitted for the first time, they are on the supporting doc screen and call this function to save isSubmitted to the DB, we don't want to force the modal to change because they should see the Congratulations modal instead.
  if (
    returnedBorrower.application.isSubmitted &&
    display !== EDisplays.SUPPORTING_DOCS &&
    display !== EDisplays.SOLAR_SUPPORTING_DOCS
  ) {
    store.dispatch(setModal(EModals.APPLICATION_IN_PROGRESS));
  }

  return;
}
