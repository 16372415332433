import { ELoanUsage } from '@/context/borrower';

import { DEFAULT_PARTNER } from './defaultPartner';
import { EPartners, IPartnerParams } from './partnerTypes';

export const CARNET_WESTERN_SYDNEY_PARTNER: IPartnerParams = {
  ...DEFAULT_PARTNER,
  id: EPartners.CARNET_WESTERN_SYDNEY,
  loanUsage: ELoanUsage.PERSONAL,
  dealerName: 'Carnet Western Sydney',
};
