import { useState } from 'react';

import { Button, EButtonType } from '@/components/Button';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import {
  selectIsVehicleRegistrationKnown,
  selectSellerName,
  selectVehicleDescription,
  selectVehicleVariant,
} from '@/context/borrower';
import {
  EDisplays,
  selectHideSellerScreen,
  setDisplay,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { CHERY_SELLER_NAME } from '@/partners/chery';
import { RegoKnown } from '@/UserInput/RegoKnown';

import { VehicleDetailsRegoKnown } from './VehicleDetailsRegoKnown';
import { VehicleDetailsRegoUnKnown } from './VehicleDetailsRegoUnknown';

export const testId = 'Vehicle';

export function Vehicle(): JSX.Element {
  // ***** CONSTANTS *****
  const baseId = 'screen-VehicleDetails';
  const isRegistrationKnown = useAppSelector(selectIsVehicleRegistrationKnown);
  const [showErrors, setShowErrors] = useState(false);
  const vehicleDescription = useAppSelector(selectVehicleDescription);
  const sellerName = useAppSelector(selectSellerName);
  const vehicleVariant = useAppSelector(selectVehicleVariant);

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const hideSellerScreen = useAppSelector(selectHideSellerScreen);

  // ***** Event Handlers *****
  function handleNextClick(): void {
    setShowErrors(true);
    if (!isComplete()) return;
    setShowErrors(false);
    const nextScreen = getNextScreen();
    dispatch(setDisplay(nextScreen));
  }

  function handleBackClick(): void {
    dispatch(setDisplay(EDisplays.LOAN));
  }

  // ***** Helpers ******
  function isComplete(): boolean {
    // Chery Hack
    if (vehicleVariant && sellerName === CHERY_SELLER_NAME) return true;

    if (!vehicleDescription) return false;
    return true;
  }

  function getNextScreen(): EDisplays {
    if (hideSellerScreen) {
      return EDisplays.BASIC;
    }
    return EDisplays.SELLER;
    // return EDisplays.INDICATIVELY_APPROVED;
  }

  return (
    <div data-testid={testId}>
      <Header />
      <ProgressBar />
      <div className='flex '>
        <Button
          onClick={handleBackClick}
          type={EButtonType.SECONDARY_OUTLINE}
          square
        >
          Back
        </Button>
        <Button onClick={handleNextClick} square>
          Next
        </Button>
      </div>
      <div className='px-4'>
        <DisplayHeading
          title='Vehicle Details'
          infoText='Select your vehicle below to get an accurate quote'
        />
        {sellerName !== CHERY_SELLER_NAME && (
          <RegoKnown showErrors={false} baseId={baseId} />
        )}
        {isRegistrationKnown ? (
          <VehicleDetailsRegoKnown showErrors={showErrors} />
        ) : (
          <VehicleDetailsRegoUnKnown showErrors={showErrors} />
        )}
      </div>
    </div>
  );
}
