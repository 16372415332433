import {
  lookupInitialVehicle,
  setInitialVehicleBuildYear,
  setInitialVehicleCondition,
  setInitialVehicleIsKnown,
  setInitialVehicleKilometers,
  setInitialVehicleMake,
  setInitialVehicleModel,
  setInitialVehicleNvic,
  setInitialVehiclePrice,
  setInitialVehicleSource,
  setInitialVehicleSourceYardId,
  setInitialVehicleStockNumber,
  setInitialVehicleVariant,
  setInitialVehicleVin,
  setVehicleUrlFromSite,
} from './helpers';
import { ISetInitialVehicle } from './ISetInitialVehicle';

export async function setInitialVehicle({
  condition,
  stockNumber,
  vehiclePrice,
  vehicleKilometers,
  nvic,
  vin,
  sourceYardId,
  buildYear,
  make,
  model,
  variant,
  partnerId,
}: ISetInitialVehicle): Promise<void> {
  setVehicleUrlFromSite();

  setInitialVehicleCondition(condition);
  setInitialVehicleStockNumber(stockNumber);
  setInitialVehiclePrice(vehiclePrice);
  setInitialVehicleKilometers(vehicleKilometers);
  setInitialVehicleNvic(nvic);
  setInitialVehicleVin(vin);
  setInitialVehicleSource(partnerId);
  // Auto Play specific parameters
  setInitialVehicleSourceYardId(sourceYardId);
  // ! Order matters
  // Year > Make > Model > etc.
  // Changing the year resets the make and model etc
  setInitialVehicleBuildYear(buildYear);
  setInitialVehicleMake(make);
  setInitialVehicleModel(model);
  setInitialVehicleVariant(variant);

  // Awaited for a reason
  // Last for a reason
  await lookupInitialVehicle();

  setInitialVehicleIsKnown(buildYear, make, model, variant, nvic, vin);
}
