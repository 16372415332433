import { useSelector } from 'react-redux';

import { SummarySection } from '@/components/SummarySection';
import { EAddressOwnershipType, selectAddressByIndex } from '@/context/borrower';
import { EModals, setModal } from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch } from '@/context/storeHooks';
import { getStringFromState } from '@/utils/getStringFromState';

export const testId = 'AddressInformation';

export interface IProps {
  showErrors: boolean;
  baseId: string;
}

export function AddressInformation({
  showErrors,
  baseId,
}: IProps): JSX.Element {
  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const address = useSelector((state: RootState) =>
    selectAddressByIndex(state, 0)
  );
  const { ownershipType, streetNumber, streetName, suburb, state, postcode } =
    address;

  // ***** Event Handlers *****
  function handleClick(): void {
    dispatch(setModal(EModals.ADDRESS_INFORMATION));
  }

  // ***** Helpers *****
  function isComplete(): boolean {
    if (ownershipType === null || ownershipType === undefined) return false;
    if (streetNumber === null || streetNumber === undefined) return false;
    if (streetName === null || streetName === undefined) return false;
    if (suburb === null || suburb === undefined) return false;
    if (state === null || state === undefined) return false;
    if (postcode === null || postcode === undefined) return false;

    return true;
  }

  function fullText(): string {
    return `I am ${(ownershipType === EAddressOwnershipType.OWNER) ? 'the ' : ''}${getStringFromState(
      ownershipType
    )} at ${getStringFromState(streetNumber)} ${getStringFromState(
      streetName
    )}, ${getStringFromState(suburb)}, ${getStringFromState(
      state
    )} ${getStringFromState(postcode)}`;
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <SummarySection
        onClick={handleClick}
        id={`${baseId}-button-addressInformationEdit`}
        text={fullText()}
        heading='Address Information'
        showErrors={showErrors && !isComplete()}
      />
    </div>
  );
}
