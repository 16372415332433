import { useState } from 'react';
import { useSelector } from 'react-redux';

import { AssetsNavigationButtons } from '@/components/AssetsNavigationButtons';
import { Button } from '@/components/Button';
import { ModalHeader } from '@/components/ModalHeader';
import {
  deleteMotorVehicle,
  selectMotorVehicleActiveIndex,
  selectMotorVehiclebyIndex,
  selectMotorVehicles,
  setMotorVehicleActiveIndex,
} from '@/context/borrower';
import { setModal } from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { MotorVehicleAssetValue } from '@/UserInput/MotorVehicleAssetValue';
import { isMotorVehicleAssetComplete } from '@/utils/checkAssetsAndLiabilities';

export const testId = 'MotorVehicleAsset';

export const MotorVehiclesAsset = (): JSX.Element => {
  // ***** Constants *****
  const baseId = 'modal-MotorVehicles';
  const modaltitle = 'Motor Vehicle';

  // ***** Redux *****

  const dispatch = useAppDispatch();
  const activeMotorVehicleIndex = useAppSelector(selectMotorVehicleActiveIndex);
  const titleNumber = activeMotorVehicleIndex + 1;
  const motorVehicle = useSelector((state: RootState) =>
    selectMotorVehiclebyIndex(state, activeMotorVehicleIndex)
  );
  const motorVehicles = useSelector(selectMotorVehicles);
  const hasPreviousMotorVehicle =
    motorVehicles?.length > 0 && activeMotorVehicleIndex !== 0;
  const hasNextMotorVehicle =
    motorVehicles?.length - 1 > activeMotorVehicleIndex;

  // ***** Local State *****
  const [showErrors, setShowErrors] = useState<boolean>(false);

  // ***** Handlers *****
  function handleSaveClick(): void {
    dispatch(setModal(null));
  }

  function handleNextClick(): void {
    if (!isMotorVehicleAssetComplete(motorVehicle)) return;
    dispatch(setMotorVehicleActiveIndex(activeMotorVehicleIndex + 1));
  }

  function handleDeleteClick(): void {
    // Delete Current Real Estate
    dispatch(deleteMotorVehicle());
    // Change Active Index
    if (
      motorVehicles?.length >= 1 &&
      motorVehicles?.length > activeMotorVehicleIndex - 1
    ) {
      dispatch(setMotorVehicleActiveIndex(activeMotorVehicleIndex - 1));
    }
  }

  function handlePreviousClick(): void {
    if (
      motorVehicles?.length >= 1 &&
      motorVehicles?.length > activeMotorVehicleIndex - 1
    ) {
      dispatch(setMotorVehicleActiveIndex(activeMotorVehicleIndex - 1));
    }
  }

  // ***** Render *****
  return (
    <div data-testid={testId} className='flex flex-col justify-between  py-2'>
      <ModalHeader title={modaltitle + '-' + titleNumber} baseId={baseId} />

      <AssetsNavigationButtons
        handleDeleteClick={handleDeleteClick}
        handlePreviousClick={handlePreviousClick}
        handleNextClick={handleNextClick}
        hasPrevious={hasPreviousMotorVehicle}
        hasNext={hasNextMotorVehicle}
      />

      <MotorVehicleAssetValue
        baseId={baseId}
        showErrors={showErrors}
        setShowErrors={setShowErrors}
      />

      <div className='py-2 mx-16'>
        <Button onClick={handleSaveClick} id={`${baseId}-button-close`}>
          Save
        </Button>
      </div>
    </div>
  );
};
