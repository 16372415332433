import { EApplicationAssetType, setLoanAssetType } from '@/context/borrower';
import { store } from '@/context/store';

export function setInitialLoanAssetType(
  partnerLoanType: EApplicationAssetType
): void {
  const currentState = store.getState().borrowerReducer.loan.assetType;
  if (currentState !== partnerLoanType) {
    store.dispatch(setLoanAssetType(partnerLoanType));
  }
}
