import { useState } from 'react';

import { Button, EButtonType } from '@/components/Button';
import {
  selectBalloon,
  selectDeposit,
  selectLoanTerm,
  selectSelectedLender,
  selectTradeIn,
  setSelectedLender,
} from '@/context/borrower';
import {
  EDisplays,
  selectShowTotalAmountPayable,
  setDisplay,
} from '@/context/display';
import {
  ELenderRepaymentFrequency,
  ILender,
  selectLenderRepaymentFrequency,
  selectLenders,
} from '@/context/lender';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { calculateTotalAmountPayable } from '@/utils/calculateTotalAmountPayable';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';

export interface IProps {
  lender: ILender;
  lowest?: boolean;
}

export const testId = 'LenderCard';

export function LenderCard({ lender, lowest }: IProps): JSX.Element {
  // ***** Redux *****
  const dispatch = useAppDispatch();
  const repaymentFrequency = useAppSelector(selectLenderRepaymentFrequency);
  const deposit = useAppSelector(selectDeposit);
  const tradeIn = useAppSelector(selectTradeIn);
  const balloon = useAppSelector(selectBalloon);
  const lenders = useAppSelector(selectLenders);
  const selectedLender = useAppSelector(selectSelectedLender);
  const loanTerm = useAppSelector(selectLoanTerm);

  const isSelected =
    selectedLender && selectedLender?.lenderName === lender.lenderName;

  const showTotalAmountPayable = useAppSelector(selectShowTotalAmountPayable);
  const totalAmountPayable = calculateTotalAmountPayable(
    loanTerm,
    lender.monthlyRepaymentAmount
  );

  // ***** Local State *****
  const [isExpanded, setIsExpanded] = useState(false);

  // ***** Event Handlers *****

  // ***** Helpers *****
  const isOnlyLender = lenders && lenders?.length === 1;

  function toggleExpanded(): void {
    setIsExpanded((expanded) => !expanded);
  }

  // ***** Event Handlers *****

  function handleEditLoanStructureClick(): void {
    dispatch(setDisplay(EDisplays.LOAN));
  }

  function handleCardClick(): void {
    dispatch(setSelectedLender(lender));
  }

  // ***** Render *****
  return (
    <div
      data-testid={testId}
      onClick={handleCardClick}
      className={`px-4 shadow-2xl rounded-2xl pt-4 pb-2 flex flex-col space-y-4 hover:cursor-pointer bg-white ${
        isSelected ? 'border-primary border-2' : ''
      }`}
    >
      {/* Always Visible */}
      <div className='grid grid-cols-4 relative'>
        <div className='w-20 h-[40px] flex flex-center justify-center absolute top-5'>
          <img
            src={`https://matching-engine.driveiq.com.au/wp-content/uploads/2022/08/${lender.matchingEngineLenderId}.svg`}
            className=''
          />
        </div>
        <div className='w-20 h-1' />
        <div className='flex flex-col  justify-between'>
          <h3 className='font-bold text-xs'>
            {repaymentFrequency === ELenderRepaymentFrequency.MONTHLY &&
              'Monthly Repayments'}
            {repaymentFrequency === ELenderRepaymentFrequency.WEEKLY &&
              'Weekly Repayments'}
            {repaymentFrequency === ELenderRepaymentFrequency.FORTNIGHTLY &&
              'Fortnightly Repayments'}
          </h3>
          <h4 className='text-secondary font-bold'>
            $
            {repaymentFrequency === ELenderRepaymentFrequency.MONTHLY &&
              lender.monthlyRepaymentAmount}
            {repaymentFrequency === ELenderRepaymentFrequency.FORTNIGHTLY &&
              lender.fortnightlyRepaymentAmount}
            {repaymentFrequency === ELenderRepaymentFrequency.WEEKLY &&
              lender.weeklyRepaymentAmount}
          </h4>
        </div>
        <div className='flex flex-col  justify-between'>
          <h3 className='font-bold text-xs'>Advertised Rate</h3>
          <h4 className='text-secondary font-bold'>
            {lender.advertisedRateInPercent}%
          </h4>
        </div>
        <div className='flex flex-col space-y-2  justify-between'>
          <h3 className='font-bold text-xs'>Comparison Rate</h3>
          <h4 className='text-secondary font-bold'>
            {lender.comparisonRateInPercent}%
          </h4>
        </div>
      </div>
      {/* Sometimes Visible */}
      {isExpanded && (
        <>
          <div className='grid grid-cols-4'>
            <div />
            <div className='flex flex-col justify-between'>
              <h3 className='font-bold text-xs'>
                Upfront <br />
                Fee
              </h3>
              <h4 className='text-secondary font-bold'>
                ${lender.upfrontFeeAmount}
              </h4>
            </div>
            <div className='flex flex-col space-y-2  justify-between'>
              <h3 className='font-bold text-xs z'>
                Ongoing <br />
                Fee
              </h3>
              <h4 className='text-secondary font-bold'>
                ${lender.monthlyOngoingFeeAmount}
              </h4>
            </div>
            <div className='flex flex-col space-y-2 justify-between'>
              <h3 className='font-bold text-xs'>Turnaround Time (Days)</h3>
              <h4 className='text-secondary font-bold'>
                {lender.turnaroundTimeInDays}
              </h4>
            </div>
          </div>
          <div className='grid grid-cols-4'>
            <div />
            <div className='flex flex-col space-y-2  justify-between'>
              <h3 className='font-bold text-xs'>Deposit</h3>
              <h4 className='text-secondary font-bold'>${deposit ?? 0}</h4>
            </div>
            <div className='flex flex-col space-y-2  justify-between'>
              <h3 className='font-bold text-xs'>Trade In</h3>
              <h4 className='text-secondary font-bold'>${tradeIn ?? 0}</h4>
            </div>
            <div className='flex flex-col space-y-2  justify-between'>
              <h3 className='font-bold text-xs'>Balloon</h3>
              <h4 className='text-secondary font-bold'>{balloon ?? 0}%</h4>
            </div>
          </div>
          {/* Total Amount Payable */}
          {showTotalAmountPayable ? (
            <div>
              <p className='text-sm text-center'>
                Total Amount Payable over{' '}
                <span className='font-bold'>{loanTerm}</span> years is $
                <span className='font-bold'>
                  {totalAmountPayable.toLocaleString()}.
                </span>
              </p>
            </div>
          ) : null}
          <div className={`${isOnlyLender ? 'px-16' : 'px-4 flex space-x-4'}`}>
            <Button
              onClick={handleEditLoanStructureClick}
              type={isOnlyLender ? EButtonType.PRIMARY : EButtonType.SECONDARY}
            >
              <p className='font-bold text-sm'>Edit Loan Structure</p>
            </Button>
            {/* {!isOnlyLender && (
              <Button onClick={handleSelectLenderClick}>
                <p className='font-bold text-sm'>Select Lender</p>
              </Button>
            )} */}
          </div>
        </>
      )}
      {/* Expanded Toggle */}
      <div className='flex text-sm pt-2'>
        {lowest && (
          <div className='w-full flex space-x-2 items-center'>
            <div className='h-4 w-4 p-[2px] text-white bg-green-500 rounded-full'>
              <CheckIcon />
            </div>
            <p className={`text-secondary`}>Lowest Repayment</p>
          </div>
        )}
        <div
          className='w-full justify-end flex px-2 items-center group cursor-pointer'
          onClick={toggleExpanded}
        >
          <p className='group-hover:font-bold transform delay-75 ease-in-out'>
            {isExpanded ? 'Less' : 'More'} details
          </p>
          <div className='h-5 w-5 group-hover:scale-125 transform delay-75 ease-in-out'>
            {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </div>
        </div>
      </div>
    </div>
  );
}
