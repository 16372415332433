import { Input } from '@/components/Input';
import {
  selectVehicleBuildYear,
  setVehicleBuildYear,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { CURRENT_YEAR } from '@/utils/constants';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'BuildYearManual';

export function BuildYearManual({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectVehicleBuildYear);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    // MAXIMUM
    const MAXIMUM = CURRENT_YEAR;
    const intValue = getNumber(value, MAXIMUM);
    if (intValue === currentState) return;
    dispatch(setVehicleBuildYear(intValue));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Build Year'
        placeholder='The year of manufacture'
        showErrors={showErrors && currentState?.toString()?.length !== 4}
        onChange={handleChange}
        id={`${baseId}-input-vehicleBuildYear`}
        value={currentState?.toString() ?? ''}
      />
    </div>
  );
}
