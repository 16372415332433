import { PayloadAction } from '@reduxjs/toolkit';

import { ETrusteeType, IBusiness } from '../types';

export const businessReducers = {
  setBusiness: (state, action: PayloadAction<IBusiness>): void => {
    state.business = action.payload;
  },
  setIndustryType: (state, action: PayloadAction<string | undefined>): void => {
    state.business.industry = action.payload;
  },
  setBusinessName: (state, action: PayloadAction<string | undefined>): void => {
    state.business.name = action.payload;
  },
  setAbn: (state, action: PayloadAction<string | undefined>): void => {
    state.business.abn = action.payload;
  },
  setAcn: (state, action: PayloadAction<string | undefined>): void => {
    state.business.acn = action.payload;
  },
  setAbnActiveFrom: (
    state,
    action: PayloadAction<string | undefined>
  ): void => {
    state.business.abnStatusEffectiveFrom = action.payload;
  },
  setGstFrom: (state, action: PayloadAction<string | undefined>): void => {
    state.business.gst = action.payload;
  },
  setEntityType: (state, action: PayloadAction<string | undefined>): void => {
    state.business.entityTypeName = action.payload;
  },
  setNumberOfDirectors: (
    state,
    action: PayloadAction<number | undefined>
  ): void => {
    state.business.numberOfDirectors = action.payload;
  },
  setTrusteeName: (state, action: PayloadAction<string | undefined>): void => {
    state.business.trusteeName = action.payload;
  },
  setTrusteeType: (
    state,
    action: PayloadAction<ETrusteeType | undefined>
  ): void => {
    state.business.trusteeType = action.payload;
  },
};
