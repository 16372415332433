import { Input } from '@/components/Input';
import { selectLicenseNumber, setLicenseNumber } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'LicenseNumber';

export function LicenseNumber({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectLicenseNumber);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    if (typeof value === 'boolean') return;
    if (value === currentState) return;
    if (value?.length > 10) return;
    dispatch(setLicenseNumber(value));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='License Number'
        placeholder='Enter the license number'
        showErrors={showErrors}
        onChange={handleChange}
        id={`${baseId}-input-licenseNumber`}
        value={currentState?.toLocaleString() ?? ''}
      />
    </div>
  );
}
