import { setModal } from '@/context/display';
import { store } from '@/context/store';
import { EPartners } from '@/partners/partnerTypes';

import { getInitialModalScreen } from '../getInitialModalScreen';

export function setInitialModal(partnerId: EPartners): void {
  const initialModal = getInitialModalScreen(partnerId);

  const currentModal = store.getState().displayReducer.modal;

  if (currentModal === initialModal) return;
  store.dispatch(setModal(initialModal));
}
