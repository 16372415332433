import { ELoanUsage } from '@/context/borrower';

import { DEFAULT_PARTNER } from './defaultPartner';
import {
  DEFAULT_MATCHING_ENGINE_LENDERS,
  EMatchingEngineLenders,
} from './matchingEngineEnums';
import { EPartners, IPartnerParams } from './partnerTypes';

export const LDV_PARTNER: IPartnerParams = {
  ...DEFAULT_PARTNER,
  id: EPartners.LDV,
  loanUsage: ELoanUsage.PERSONAL,
  dealerName: 'LDV',
  primaryColor: '#bc3218',
  secondaryColor: '#010101',
  tertiaryColor: '#010101',
  quaternaryColor: '#e5e5e5',
  hideSellerScreen: true,
  hideSelectVehicleScreen: true,
  waterfallLenders: [
    [EMatchingEngineLenders.NISSAN_FINANCE],
    [
      EMatchingEngineLenders.BRANDED_FINANCIAL_SERVICES,
      EMatchingEngineLenders.PEPPER,
      EMatchingEngineLenders.LIBERTY,
      EMatchingEngineLenders.ANGLE,
    ],
    DEFAULT_MATCHING_ENGINE_LENDERS,
  ],
};
