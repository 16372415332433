export interface IScores {
  onePointOneScore?: number | undefined;
  comprehensiveScore?: number | undefined;
  oneScore?: number | undefined;
  consumerEnquiriesCount?: number | undefined;
  commercialEnquiriesCount?: number | undefined;
  creditHistory?: ECreditHistory | undefined;
  equifaxEnquiryId?: string | undefined;
  equifaxLastRequested?: Date | undefined;
  equifaxStatus?: number | undefined;
  previousComparableCredit?: boolean | undefined;
  creditScore?: number | undefined;
  creditScorePercentage?: number | undefined;
    eligibilityScorePercentage?: number | undefined;
    accuracyScorePercentage?: number | undefined;
}

export enum ECreditHistory {
  FIRST_TIME = 'FIRST_TIME',
  CLEAR_CREDIT = 'CLEAR_CREDIT',
  PAID_DEFAULTS = 'PAID_DEFAULTS',
  UNPAID_DEFAULTS = 'UNPAID_DEFAULTS',
  PREV_COMP_CREDIT = 'PREV_COMP_CREDIT',
  EX_BANKRUPT = 'EX_BANKRUPT',
  BANKRUPT = 'BANKRUPT',
}

export const initialScores: IScores = {};
