import { useSelector } from 'react-redux';

import { Input } from '@/components/Input';
import {
  ICreditCard,
  selectCreditCardActiveIndex,
  selectCreditCardByIndex,
  setCreditCardByIndex,
} from '@/context/borrower';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
  baseId: string;
  showErrors: boolean;
  setShowErrors: (value: boolean) => void;
}

export const testId = 'CreditCardBalance';

export function CreditCardBalance({
  baseId,
  showErrors,
  setShowErrors,
}: IProps): JSX.Element {
  // ***** Redux *****
  const activeCardIndex = useAppSelector(selectCreditCardActiveIndex);
  const creditCard = useSelector((state: RootState) =>
    selectCreditCardByIndex(state, activeCardIndex)
  );
  const creditCardBalance = creditCard?.creditCardBalance;
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    setShowErrors(false);
    if (typeof value !== 'string') return;
    const MAXIMUM = 999999;
    let intValue: number | undefined = getNumber(value, MAXIMUM);
    if (intValue === 0) intValue = undefined;
    if (intValue && intValue > 9999999) return;
    if (creditCard === null) return;
    const newCreditCard: ICreditCard = {
      ...creditCard,
      creditCardBalance: intValue,
    };

    if (intValue && isNaN(intValue)) {
      newCreditCard.creditCardBalance = undefined;
    }
    dispatch(
      setCreditCardByIndex({
        index: activeCardIndex,
        creditCard: newCreditCard,
      })
    );
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Balance'
        placeholder='$2000'
        showErrors={showErrors && !creditCardBalance}
        onChange={handleChange}
        id={`${baseId}-input-creditCardBalance`}
        value={creditCardBalance?.toLocaleString() ?? ''}
        prefix='$'
      />
    </div>
  );
}
