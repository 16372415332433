import { Button } from '@/components/Button';
import { RemoteImage } from '@/components/RemoteImage';
import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';

export const testId = 'ApplicationSubmitted';

export function ApplicationSubmitted(): JSX.Element {
  // ***** Redux State *****
  const dispatch = useAppDispatch();

  // ***** Handlers *****
  function handleClick(): void {
    dispatch(setDisplay(EDisplays.BASIC));
    dispatch(setModal(EModals.APPLICATION_IN_PROGRESS));
  }

  // ***** Render *****
  return (
    <div
      data-testid={testId}
      className='flex flex-col justify-between  py-2 px-8'
    >
      <RemoteImage fileName='confetti.svg' className='w-full h-40' />
      <h3 className='text-center font-bold text-4xl pb-4'>
        Congratulations
        <br />
        Your application
        <br />
        has been sent!
      </h3>
      <p className='pb-4 font-semi-bold text-lg text-center'>
        The lender will be in touch <br />
        with you soon.
      </p>
      <Button onClick={handleClick}>
        <p>Close</p>
      </Button>
    </div>
  );
}
