export enum EParamaters {
  BUILD_YEAR = 'buildYear',
  MAKE = 'make',
  MODEL = 'model',
  VARIANT = 'variant',
  DEALER_NAME = 'dealerName',
  DEALER_ACCOUNT_NUMBER = 'dealerAccountNumber',
  ABN = 'abn',
  EMAIL = 'email',
  PHONE = 'phone',
  LEAD_EMAIL = 'leadEmail',
  NVIC = 'nvic',
  VIN = 'vin',
  CONDITION = 'condition',
  STOCK_NUMBER = 'stockNumber',
  AMOUNT = 'amount',
  KILOMETERS = 'kilometers',
  SOURCE_YARD_ID = 'sourceYardId',
  LOAN_USAGE = 'loanUsage',
  SHOW_WELCOME_SCREEN = 'showWelcomeScreen',
  PRIMARY_COLOR = 'primaryColor',
  SECONDARY_COLOR = 'secondaryColor',
  TERTIARY_COLOR = 'tertiaryColor',
  QUATERNARY_COLOR = 'quaternaryColor',
}

export type IParameter = string | null;
