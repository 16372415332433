import { IComboboxOption } from '@/components/Combobox';
import { IVehicle } from '@/context/borrower';
import { RootState } from '@/context/store';

export const selectVehicleBuildYearApiResults = (
  state: RootState
): IComboboxOption[] | undefined =>
  state.displayReducer.glassGuideLookUpVehicleResults
    .vehicleBuildYearApiResults;
export const selectVehicleMakeApiResults = (
  state: RootState
): IComboboxOption[] | undefined =>
  state.displayReducer.glassGuideLookUpVehicleResults.vehicleMakeApiResults;
export const selectVehicleModelApiResults = (
  state: RootState
): IComboboxOption[] | undefined =>
  state.displayReducer.glassGuideLookUpVehicleResults.vehicleModelApiResults;
export const selectVehicleVariantApiResults = (
  state: RootState
): IComboboxOption[] | undefined =>
  state.displayReducer.glassGuideLookUpVehicleResults.vehicleVariantApiResults;
export const selectGlassOptionsForVehicle = (
  state: RootState
): IVehicle[] | undefined =>
  state.displayReducer.glassGuideLookUpVehicleResults.glassOptionsForVehicle;
