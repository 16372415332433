import { Button, EButtonType } from '@/components/Button';
import { selectIsVehicleKnown } from '@/context/borrower';
import {
  EDisplays,
  EModals,
  selectDisplay,
  selectPartnerName,
  setDisplay,
  setModal,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { SOLAR_DEALER_NAME } from '@/partners/solar';
import { LoginCode } from '@/UserInput/LoginCode';
import { MobileNumber } from '@/UserInput/MobileNumber';
import { setParametersFromParams } from '@/utils/setParametersFromParams';

export const testId = 'Login';

export function Login(): JSX.Element {
  // ***** Constants *****
  const baseId = 'modal-login';

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const isVehicleKnown = useAppSelector(selectIsVehicleKnown);
  const display = useAppSelector(selectDisplay);
  const partnerNameDisplay = useAppSelector(selectPartnerName);

  const solar = partnerNameDisplay === SOLAR_DEALER_NAME;

  // ***** Handlers *****
  function handleCloseClick(): void {
    dispatch(setModal(null));
    const nextScreen = getNextScreen();
    if (!nextScreen) return;
    setParametersFromParams();
    dispatch(setDisplay(nextScreen));
  }

  function handleSignUpClick(): void {
    dispatch(setModal(EModals.REGISTER));
  }

  // ***** Helpers *****
  function getNextScreen(): EDisplays | undefined {
    if (display === EDisplays.SERVICING_SUCCESS) {
      return;
    }

    if (isVehicleKnown) {
      return EDisplays.BASIC;
    }

    if (solar) return EDisplays.SOLAR_LOAN_DETAILS;
    return EDisplays.LOAN;
  }

  // ***** Render *****
  return (
    <div
      data-testid={testId}
      className='flex flex-col justify-between  py-2'
    >
      {/* Header Text */}
      <div className='text-center'>
        <h3 className='font-bold text-lg'>Welcome Back!</h3>
        <p className='font-bold text-xs text-center text-secondary'>
          To login in, simply add your mobile or email and we&apos;ll send you a
          one-time code.
        </p>
      </div>
      {/* <MobileOrEmail baseId={baseId} showErrors={false} /> // Removed the option to choose */}
      <MobileNumber baseId={baseId} showErrors={false} />
      <LoginCode baseId={baseId} showErrors={false} />
      {/* Text */}
      <p className='font-bold text-xs text-center py-2'>
        Don&apos;t have an account? Click here to{' '}
        <span
          onClick={handleSignUpClick}
          className='underline hover:cursor-pointer'
        >
          Sign Up
        </span>
      </p>
      <div className='flex-center mx-16'>
        <Button onClick={handleCloseClick} type={EButtonType.SECONDARY_OUTLINE}>
          Close
        </Button>
      </div>
    </div>
  );
}
