import { Header } from '@/components/Header';
import { RemoteImage } from '@/components/RemoteImage';
import Ring from '@/components/Ring';

export const baseId = 'screen-YourScores';

export function YourScores({
  creditScore,
  creditScorePercentage,
  eligibilityPercentage,
  accuracyPercentage,
}: any): JSX.Element {
  return (
    <section data-testid={baseId}>
      <Header />

      <div className='px-4'>
        {/* Scores */}
        <>
          <p className='font-bold text-primary text-lg text-center py-4'>
            YOUR SCORES
          </p>
          <div className='h-1/2 w-full'>
            <div className='relative w-full'>
              {/* Inner Ring */}
              <div className='w-full flex-center h-full absolute bottom-1 right-1'>
                <Ring
                  width='100%'
                  height='100%'
                  path='M 44.6527 70.2904 C 40.7006 68.9843 37.2287 66.4802 34.7375 63.0975 C 31.7755 59.0755 30.4107 54.0978 30.9072 49.1274 C 31.4037 44.1571 33.7264 39.5476 37.4256 36.1911 C 41.1247 32.8345 45.9375 30.9694 50.9325 30.9568 C 55.9275 30.9441 60.7497 32.7848 64.4659 36.1226 C 68.182 39.4604 70.5278 44.058 71.0497 49.0258 C 71.5713 53.9936 70.2317 58.9783 67.2899 63.015 C 64.8161 66.4103 61.357 68.9321 57.4117 70.2581'
                  progress={creditScorePercentage ?? 45}
                  progressColor='#066BFC'
                  strokeWidth={10}
                />
              </div>
              {/* Middle Ring */}
              <div className='w-full flex-center h-full absolute bottom-1 right-1'>
                <Ring
                  width='100%'
                  height='100%'
                  path='M 43.2808 80.6699 C 36.8548 78.9733 31.1272 75.22 26.9994 69.9434 C 22.2676 63.8946 19.9845 56.2873 20.6037 48.6326 C 21.2229 40.9781 24.699 33.8365 30.3415 28.6269 C 35.9839 23.4174 43.3797 20.5212 51.0592 20.5138 C 58.7388 20.5063 66.1403 23.3882 71.7928 28.5868 C 77.4452 33.7854 80.9353 40.9202 81.5692 48.5737 C 82.2034 56.2271 79.9349 63.839 75.2148 69.8967 C 71.0973 75.1811 65.377 78.9457 58.9543 80.6548'
                  progress={eligibilityPercentage ?? 55}
                  progressColor='#8C2DBB'
                  strokeWidth={10}
                />
              </div>
              {/* Outer Ring */}
              <div className='w-full flex-center h-full absolute bottom-1 right-1'>
                <Ring
                  width='100%'
                  height='100%'
                  path='M 42.5059 91.426 C 33.4532 89.485 25.2872 84.5278 19.3777 77.3179 C 12.7766 69.2641 9.4849 59.0017 10.1699 48.6109 C 10.8548 38.22 15.4651 28.4785 23.0661 21.3608 C 30.6672 14.2432 40.6903 10.2821 51.1036 10.2805 C 61.5169 10.2789 71.5414 14.237 79.1446 21.3523 C 86.7478 28.4676 91.3612 38.2078 92.0491 48.5983 C 92.7373 58.9888 89.4487 69.2524 82.8502 77.308 C 76.9429 84.52 68.7784 89.4796 59.7262 91.4236'
                  progress={accuracyPercentage ?? 66}
                  progressColor='#EB018D'
                  strokeWidth={10}
                />
              </div>
              {/* Label Ring */}
              <div className='relative w-full flex-center'>
                <RemoteImage fileName='yourscores.svg' />
              </div>

              {/* Inner Credit Score */}
              <div className='absolute w-full flex-center bottom-[134px] flex flex-col'>
                <p className='font-bold'>
                  CREDIT
                  <br />
                  SCORE
                </p>
                <p className='text-lg font-bold'>{creditScore ?? '0'}</p>
              </div>
            </div>
          </div>
        </>
        {/* Explanation box */}
        <div className='w-full bg-white shadow-lg rounded-xl p-4'>
          <div className='flex flex-col space-y-2'>
            {/* Blue Circle Credit Score */}
            <div className='flex justify-items-stretch'>
              <svg className='h-10 w-10 pt-1'>
                <circle cx='8' cy='8' r='8' fill='#066BFC' />
              </svg>
              <div className='flex flex-col'>
                <p className='font-bold'>CREDIT SCORE</p>
                <p className='text-sm'>
                  How likely you are to get a great Interest Rate based on your
                  credit score.
                </p>
              </div>
            </div>
            {/* Purple Circle Credit Score */}
            <div className='flex justify-items-stretch'>
              <svg className='h-10 w-10 pt-1'>
                <circle cx='8' cy='8' r='8' fill='#8C2DBB' />
              </svg>
              <div className='flex flex-col'>
                <p className='font-bold'>ELIGIBILITY</p>
                <p className='text-sm'>
                  The likelihood of getting approved based on the application
                  data you provided.
                </p>
              </div>
            </div>
            {/* Red Circle Credit Score */}
            <div className='flex justify-items-stretch'>
              <svg className='h-10 w-10 pt-1'>
                <circle cx='8' cy='8' r='8' fill='#EB018D' />
              </svg>
              <div className='flex flex-col'>
                <p className='font-bold'>ACCURACY</p>
                <p className='text-sm'>
                  Our confidence level based on the information we were able to
                  find out about you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
