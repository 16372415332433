import { Input } from '@/components/Input';
import { selectBalloon, setBalloon } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'Balloon';

export function Balloon({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectBalloon);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    // MAXIMUM
    const MAXIMUM = 999999;
    const intValue = getNumber(value, MAXIMUM);
    if (intValue === currentState) return;
    dispatch(setBalloon(intValue));
  }

  // ***** Render *****
  return (
    <div data-testid={testId} className='w-full'>
      <Input
        title='Balloon'
        placeholder='Optional'
        showErrors={showErrors}
        onChange={handleChange}
        id={`${baseId}-input-balloon`}
        value={currentState?.toLocaleString() ?? ''}
        prefix='$'
      />
    </div>
  );
}
