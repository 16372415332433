import { Button, EButtonType } from '@/components/Button';
import { selectSellerName } from '@/context/borrower';
import {
  EDisplays,
  EModals,
  selectAgreedToTermsAndConditions,
  setDisplay,
  setModal,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { SOLAR_DEALER_NAME } from '@/partners/solar';
import { FirstName } from '@/UserInput/FirstName';
import { LoginCode } from '@/UserInput/LoginCode';
import { MobileNumber } from '@/UserInput/MobileNumber';
import { TermsAndConditions } from '@/UserInput/TermsAndConditions';

export const testId = 'Register';

export function Register(): JSX.Element {
  // ***** Constants *****
  const baseId = 'modal-login';

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const sellerName = useAppSelector(selectSellerName);
  const solar = sellerName === SOLAR_DEALER_NAME;
  const agreedToTermsAndConditions = useAppSelector(
    selectAgreedToTermsAndConditions
  );

  // ***** Handlers *****
  function handleCloseClick(): void {
    dispatch(setModal(null));
  }

  function handleLoginClick(): void {
    dispatch(setModal(EModals.LOGIN));
  }

  function handleBackClickSolar(): void {
    dispatch(setDisplay(EDisplays.SOLAR_YOUR_DETAILS));
    dispatch(setModal(null));
  }

  function handleNextClickSolar(): void {
    // ! check if the code entered is the code provided
    dispatch(setDisplay(EDisplays.IDENTIFICATION));
    dispatch(setModal(null));
  }

  // ***** Render *****
  return (
    <div data-testid={testId} className='flex flex-col justify-between  py-2'>
      {/* Header Text */}
      <div className='text-center'>
        {solar && (
          <h3 className='font-bold text-lg text-primary'>Apply Now!</h3>
        )}
        {!solar && (
          <h3 className='font-bold text-lg'>Let&apos;s Go Driving!</h3>
        )}

        <p
          className={`font-bold text-xs text-center ${
            solar ? 'text-blue-500' : 'text-secondary'
          }`}
        >
          We will send you a one time password. <br />
          No spam, promise.
        </p>
      </div>
      {/* <MobileOrEmail baseId={baseId} showErrors={false} /> // Removed the option to choose */}
      <MobileNumber baseId={baseId} showErrors={false} />
      <FirstName baseId={baseId} showErrors={false} />

      <TermsAndConditions />
      <LoginCode
        baseId={baseId}
        showErrors={false}
        disabled={!agreedToTermsAndConditions}
      />

      <>
        <p className='font-bold text-xs text-center py-2'>
          Already have an account? Click here to{' '}
          <span
            onClick={handleLoginClick}
            className='underline hover:cursor-pointer'
          >
            Login
          </span>
        </p>

        {!solar && (
          <div className='flex-center mx-16'>
            <Button
              onClick={handleCloseClick}
              type={EButtonType.SECONDARY_OUTLINE}
            >
              Close
            </Button>
          </div>
        )}
      </>

      {/* buttons */}
      {solar && (
        <div className='flex justify-between mt-4'>
          <Button
            onClick={handleBackClickSolar}
            type={EButtonType.ROUNDED_FULL_SECONDARY_OUTLINE}
          >
            Back
          </Button>
          <Button
            onClick={handleNextClickSolar}
            type={EButtonType.ROUNDED_FULL_PRIMARY}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
}
