import { IPartnerParams } from '@/partners/partnerTypes';
import { EParamaters } from '@/utils/setParametersFromParams';

import { ISetInitialLoan } from './ISetInitialLoan';

export function getInitialLoanData(
  partner: IPartnerParams,
  params: URLSearchParams
): ISetInitialLoan {
  return {
    partnerLoanUsage: partner.loanUsage,
    parameterLoanUsage: params.get(EParamaters.LOAN_USAGE),
    partnerLoanType: partner.initialLoanType,
    partnerLoanAssetType: partner.initialLoanAssetType,
  };
}
