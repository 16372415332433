import { useSelector } from 'react-redux';

import { CheckBox } from '@/components/CheckBox';
import { Input } from '@/components/Input';
import {
  selectRealEstateActiveIndex,
  selectRealEstatebyIndex,
  setRealEstateByIndex,
} from '@/context/borrower';
// eslint-disable-next-line no-restricted-imports
import { IRealEstate } from '@/context/borrower/types/realEstateAssetTypes';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

import { RealEstateLoanBalance } from '../RealEstateLoanBalance';
import { RealEstateLoanLenderName } from '../RealEstateLoanLenderName';
import { RealEstateMonthlyRepayment } from '../RealEstateMonthlyRepayment';

export interface IProps {
  baseId: string;
  showErrors: boolean;
  setShowErrors: (value: boolean) => void;
}

export const testId = 'RealEstateAssetValue';

export function RealEstateAssetValue({
  baseId,
  showErrors,
  setShowErrors,
}: IProps): JSX.Element {
  // ***** Redux *****
  const dispatch = useAppDispatch();
  const activeRealEstateIndex = useAppSelector(selectRealEstateActiveIndex);

  const realEstate = useSelector((state: RootState) =>
    selectRealEstatebyIndex(state, activeRealEstateIndex)
  );
  const realEstateAssetValue = realEstate?.assetValue;
  const agreeLoan = realEstate?.isLoanPresent ?? false;
  const isInvestmentProperty = realEstate?.isInvestmentProperty ?? false;

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    setShowErrors(false);
    if (typeof value !== 'string') return;
    const MAXIMUM = 99999999;
    let intValue: number | undefined = getNumber(value, MAXIMUM);
    if (intValue === 0) intValue = undefined;
    if (intValue && intValue > MAXIMUM) return;
    if (realEstate === null) return;

    if (intValue === undefined) {
      const newRealEstate: IRealEstate = {};
      dispatch(
        setRealEstateByIndex({
          index: activeRealEstateIndex,
          realEstate: newRealEstate,
        })
      );
      return;
    }
    const newRealEstate: IRealEstate = {
      ...realEstate,
      assetValue: intValue,
    };

    dispatch(
      setRealEstateByIndex({
        index: activeRealEstateIndex,
        realEstate: newRealEstate,
      })
    );
  }

  function handleLoanCheckBoxClick(): void {
    if (realEstateAssetValue === undefined) {
      setShowErrors(true);
      return;
    }

    const newRealEstate: IRealEstate = {
      ...realEstate,
      isLoanPresent: !agreeLoan,
    };
    if (newRealEstate.isLoanPresent === false) {
      newRealEstate.lenderName = undefined;
      newRealEstate.monthlyRepayment = undefined;
      newRealEstate.loanBalance = undefined;
      newRealEstate.isInvestmentProperty = undefined;
    }

    dispatch(
      setRealEstateByIndex({
        index: activeRealEstateIndex,
        realEstate: newRealEstate,
      })
    );
  }

  const handleInvestmentProperty = (): void => {
    const newRealEstate: IRealEstate = {
      ...realEstate,
      isInvestmentProperty: !isInvestmentProperty,
    };
    dispatch(
      setRealEstateByIndex({
        index: activeRealEstateIndex,
        realEstate: newRealEstate,
      })
    );
  };

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Asset value'
        placeholder='$2000'
        showErrors={showErrors && !realEstateAssetValue}
        onChange={handleChange}
        id={`${baseId}-input-creditCardLimit`}
        value={realEstateAssetValue?.toLocaleString() ?? ''}
        prefix='$'
      />
      <div className='flex space-x-2 py-2'>
        <CheckBox onClick={handleLoanCheckBoxClick} checked={agreeLoan} />
        <p className='uppercase text-xs'>I have a loan for this </p>
      </div>

      {agreeLoan ? (
        <>
          {' '}
          <div className='flex space-x-2 py-2'>
            <CheckBox
              onClick={handleInvestmentProperty}
              checked={isInvestmentProperty}
            />
            <p className='uppercase text-xs'>This is an investment property </p>
          </div>
          <RealEstateLoanLenderName
            baseId={baseId}
            showErrors={showErrors}
            setShowErrors={setShowErrors}
          />
          <RealEstateMonthlyRepayment
            baseId={baseId}
            showErrors={showErrors}
            setShowErrors={setShowErrors}
          />
          <RealEstateLoanBalance
            baseId={baseId}
            showErrors={showErrors}
            setShowErrors={setShowErrors}
          />{' '}
        </>
      ) : null}
    </div>
  );
}
