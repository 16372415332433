import {
  EAddressOwnershipType,
  EApplicationAssetSaleType,
  EBorrowerCitizenshipStatus,
  ECreditHistory,
  EEmploymentStatus,
  EQuickQuoteFinanceType,
} from '@/context/borrower';
import { ELenderRepaymentFrequency } from '@/context/lender';
import { EPartners } from '@/partners/partnerTypes';

export interface IQuickQuoteRequest {
  partner?: EPartners;
  residencyStatus?: EBorrowerCitizenshipStatus;
  employmentStatus?: EEmploymentStatus;
  vedaEquifaxScore?: number;
  comprehensiveEquifaxScore?: number;
  brokerFee?: number;
  assetType?: EQuickQuoteAssetType;
  financeType?: EQuickQuoteFinanceType;
  fixedTerm?: number;
  amount: number;
  glassGuideValuation?: number;
  deposit?: number;
  buildYear?: number;
  assetBalloon?: number;
  repaymentsPeriod?: ELenderRepaymentFrequency;
  ownershipType?: EAddressOwnershipType;
  creditHistory?: ECreditHistory;
  saleType?: EApplicationAssetSaleType;
  financeFees?: number;
  commissionAmount?: number;
  tradeInQuote?: number;
  amountPaidInBankAccountMonthly: number;
  waterfallLenders: string[][];
}

export enum EQuickQuoteAssetType {
  MOTOR_VEHICLES_SEC = 'MOTOR_VEHICLES_SEC',
  SOLAR_PANELS = 'SOLAR_PANELS',
}
