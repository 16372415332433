import { postUrl } from '@/apiCalls/fetch';

import { EVerificationResponseStatus } from '../verificationCommonTypes';

import { ISendSmsResponse } from './sendSmsCodeTypes';

export async function sendSmsCode(
  mobilePhone: string
): Promise<EVerificationResponseStatus> {
  const url = '/api/verify/requestVerification';

  const body = { mobilePhone };

  const response = await postUrl<ISendSmsResponse>(url, body);

  return response.status;
}
