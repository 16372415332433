import { Input } from '@/components/Input';
import {
  ESellerType,
  selectSellerName,
  selectSellerType,
  setSellerName,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'DealershipName';

export function DealershipName({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const sellerType = useAppSelector(selectSellerType);
  const currentState = useAppSelector(selectSellerName);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    if (typeof value === 'boolean') return;
    if (value === currentState) return;
    if (value?.length > 100) return;
    dispatch(setSellerName(value));
  }

  function getTitleName(): string {
    const string = 'Name of ';
    let title = sellerType === ESellerType.DEALERSHIP ? 'Dealership' : 'Seller';
    if (sellerType === ESellerType.SOLAR) title = 'Vendor';

    return string + title;
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title={getTitleName()}
        placeholder='Optional'
        showErrors={showErrors}
        onChange={handleChange}
        id={`${baseId}-input-dealershipName`}
        value={currentState?.toLocaleString() ?? ''}
        disabled={sellerType === ESellerType.SOLAR ? true : false}
      />
    </div>
  );
}
