import { EModals } from '@/context/display';
import { EPartners } from '@/partners/partnerTypes';
import {
  ELocalStorageVariables,
  getLocalStorageItem,
} from '@/utils/localStorage';

export function getInitialModalScreen(partnerId: EPartners): EModals | null {
  const authToken = getLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);
  const firstName = getLocalStorageItem(ELocalStorageVariables.FIRST_NAME);

  if (partnerId === EPartners.SOLAR && !authToken && !firstName) return null;
  if (partnerId === EPartners.SOLAR && authToken && firstName)
    return EModals.SOLAR_RETURN_USER;

  if (authToken && firstName) {
    return EModals.RETURN_USER;
  }

  return EModals.WELCOME;
}
