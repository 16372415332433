import { setModal } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';
import { XCircleIcon } from '@heroicons/react/24/outline';

export const testId = 'ModalHeader';

export interface IProps {
  baseId: string;
  title: string;
  performCheck?: boolean;
}

export function ModalHeader({
  title,
  baseId,
  performCheck,
}: IProps): JSX.Element {
  // ***** Constants *****
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleClick(): void {
    dispatch(setModal(null));
  }

  // ***** Render *****
  return (
    <div data-testid={testId} className='flex justify-between pb-2'>
      <p className='capitalize font-bold'>{title}</p>
      <div className='h-6 w-6 cursor-pointer hover:scale-125 transform delay-75 ease-in-out'>
        <XCircleIcon onClick={handleClick} id={`${baseId}-button-xButton`} />
      </div>
    </div>
  );
}
