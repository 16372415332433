import { IPartnerParams } from '@/partners/partnerTypes';
import { EParamaters } from '@/utils/setParametersFromParams';

import { ISetInitialSeller } from './ISetInitialSeller';

export function getInitialSellerData(
  partner: IPartnerParams,
  params: URLSearchParams
): ISetInitialSeller {
  return {
    partnerName: partner.dealerName,
    paramaterPartnerName: params.get(EParamaters.DEALER_NAME),
    partnerAccountNumber: partner.dealerAccountNumber,
    parameterAccountNumber: params.get(EParamaters.DEALER_ACCOUNT_NUMBER),
    partnerAbn: partner.dealerAbn,
    parameterAbn: params.get(EParamaters.ABN),
    partnerEmail: partner.dealerEmail,
    parameterEmail: params.get(EParamaters.EMAIL),
    partnerPhone: partner.dealerPhone,
    parameterPhone: params.get(EParamaters.PHONE),
    partnerLeadEmail: partner.leadEmail,
    parameterLeadEmail: params.get(EParamaters.LEAD_EMAIL),
  };
}
