import { useEffect, useState } from 'react';

import { Button, EButtonType } from '@/components/Button';
import { DisplayHeading } from '@/components/DisplayHeading';
import {
  ELoanType,
  selectLoanType,
  setSelectedLender,
} from '@/context/borrower';
import { EDisplays, selectDisplay, setDisplay } from '@/context/display';
import {
  ELenderRepaymentFrequency,
  ILender,
  selectLenderRepaymentFrequency,
  selectLenders,
} from '@/context/lender';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { IMAGE_URL } from '@/utils/constants';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';

export interface IProps {
  lender: ILender;
}

export const testId = 'SolarLenderCard';

export function SolarLenderCard({ lender }: IProps): JSX.Element {
  // ***** Redux *****
  const lenderLoanTerm = lender.loanTerm;
  const dispatch = useAppDispatch();
  const repaymentFrequency = useAppSelector(selectLenderRepaymentFrequency);
  const lenders = useAppSelector(selectLenders);
  const currentDisplay = useAppSelector(selectDisplay);
  const loanType = useAppSelector(selectLoanType);

  // ***** Local State *****
  const [isExpanded, setIsExpanded] = useState(false);
  const [firstLenderExpanded, setFirstLenderExpanded] = useState(false);

  // ***** Event Handlers *****

  // ***** Helpers *****
  const isOnlyLender = lenders && lenders?.length === 1;
  const firstLender = lenders?.[0];
  const result = isExpanded || firstLenderExpanded;
  const firstLenderTrue = firstLender === lender;

  function toggleExpanded(): void {
    setIsExpanded((expanded) => !expanded);
  }

  function toggleExpandedFirstLender(): void {
    setFirstLenderExpanded((expanded) => !expanded);
  }

  // ***** Event Handlers *****

  function handleEditLoanClick(): void {
    dispatch(setDisplay(EDisplays.SOLAR_LOAN_DETAILS));
  }

  function handleCheckEligibilityCheck(): void {
    dispatch(setDisplay(EDisplays.SOLAR_YOUR_DETAILS));
    dispatch(setSelectedLender(lender));
  }

  useEffect(() => {
    if (currentDisplay === 'SOLAR_LENDERS_LIST') {
      if (firstLender === lender) {
        setFirstLenderExpanded(true);
      }
    }
  }, []);

  // ***** Render *****
  return (
    <div
      data-testid={testId}
      className={`px-4 shadow-md rounded-2xl border py-1 flex flex-col space-y-4 hover:cursor-pointer bg-white '
      }`}
    >
      <DisplayHeading
        title=''
        infoText='Disclaimer: The information provided by the finance calculator is indicative only and is not a formal or binding quote. It is of a general nature which is based on the accuracy of information you have provided and does not take into account your personal needs and financial circumstances. Terms, conditions, fees and charges apply. Finance is available to approved applicants of the selected lender'
      />
      {/* Always Visible */}
      <div className='grid grid-cols-3 '>
        <img src={`${IMAGE_URL}/solar.svg`} />
        <div className='flex flex-col space-y-2  justify-between text-sm'>
          <h2 className='font-bold'>
            {repaymentFrequency === ELenderRepaymentFrequency.MONTHLY &&
              'Monthly Repayments'}
            {repaymentFrequency === ELenderRepaymentFrequency.WEEKLY &&
              'Weekly Repayments'}
            {repaymentFrequency === ELenderRepaymentFrequency.FORTNIGHTLY &&
              'Fortnightly Repayments'}
          </h2>
          <h4 className='text-secondary font-bold text-lg'>
            $
            {repaymentFrequency === ELenderRepaymentFrequency.MONTHLY &&
              lender.monthlyRepaymentAmount}
            {repaymentFrequency === ELenderRepaymentFrequency.FORTNIGHTLY &&
              lender.fortnightlyRepaymentAmount}
            {repaymentFrequency === ELenderRepaymentFrequency.WEEKLY &&
              lender.weeklyRepaymentAmount}
          </h4>
        </div>
        <div className='flex flex-col space-y-2  justify-between text-sm'>
          {loanType === ELoanType.LEASE_RENTAL ? (
            <>
              <p className='font-bold pt-[52px]'>Excl. GST</p>
            </>
          ) : null}
        </div>
      </div>
      {/* Sometimes Visible */}

      {result && (
        <>
          <div className='grid grid-cols-4'>
            <div />
          </div>

          <div className='grid grid-cols-4'>
            <div />
          </div>
          <div
            className={`${
              isOnlyLender ? 'px-16' : 'px-4 flex space-x-4'
            } flex justify-around`}
          >
            <Button
              onClick={handleEditLoanClick}
              type={
                isOnlyLender
                  ? EButtonType.LONGER_ROUNDED_FULL_PRIMARY
                  : EButtonType.LONGER_ROUNDED_FULL_SECONDARY_OUTLINE
              }
            >
              <p className='text-sm text-black'>Edit Loan Details</p>
            </Button>
            {!isOnlyLender && (
              <Button
                onClick={handleCheckEligibilityCheck}
                type={
                  currentDisplay !== 'SOLAR_LENDERS_LIST'
                    ? EButtonType.LONGER_ROUNDED_DISABLED
                    : EButtonType.LONGER_ROUNDED_FULL_PRIMARY
                }
              >
                <p className='font-bold text-sm'>Check Eligibility</p>
              </Button>
            )}
          </div>
        </>
      )}
      {/* Expanded Toggle */}
      <div className='flex text-sm pt-2'>
        <div className='w-full flex space-x-2 items-center'>
          <div className='h-4 w-4 p-[2px] text-white bg-green-500 rounded-full'>
            <CheckIcon />
          </div>
          <p className='text-gray-500'>{lenderLoanTerm} year term</p>
        </div>
        <div
          className='w-full justify-end flex px-2 items-center group cursor-pointer'
          onClick={firstLenderTrue ? toggleExpandedFirstLender : toggleExpanded}
        >
          <p className='group-hover:font-bold transform delay-75 ease-in-out'>
            {result ? 'Less' : 'More'} details
          </p>
          <div className='h-5 w-5 group-hover:scale-125 transform delay-75 ease-in-out'>
            {result ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </div>
        </div>
      </div>
    </div>
  );
}
