import {
  ELocalStorageVariables,
  setLocalStorageItem,
} from '@/utils/localStorage';

import { getUrl } from '../fetch';

import {
  IGetLoginViaCode,
  IGetLoginViaCodeResponse,
} from './getLoginViaCodeTypes';

export async function getLoginViaCode(
  code: string | undefined
): Promise<IGetLoginViaCode> {
  const url = `/api/urlShortener/login/${code}`;

  const response = await getUrl<IGetLoginViaCodeResponse>(url);

  setLocalStorageItem(ELocalStorageVariables.FIRST_NAME, response.data.name);
  setLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN, response.data.token);

  console.log('getLoginViaCode()');
  return response.data;
}
