export interface IRealEstate {
  assetValue?: number | undefined;
  isLoanPresent?: boolean | undefined;
  lenderName?: string | undefined;

  loanBalance?: number | undefined;
  monthlyRepayment?: number | undefined;
  isInvestmentProperty?: boolean | undefined;
}

// const state = store.getState();
// const mortgageOrRent = state.borrowerReducer.expenses.mortgageRent;

export const initialRealEstateDetails: IRealEstate = {};
export interface IRealEstateAsset {
  realEstates: IRealEstate[];
  realEstateActiveIndex: number;
}

export const mockRealEstate: IRealEstate = {
  assetValue: 100000,
  isLoanPresent: true,
  lenderName: 'Bank of America',
  monthlyRepayment: 10000,
  loanBalance: 50000,
  isInvestmentProperty: true,
};
