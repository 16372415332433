import { useState } from 'react';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button, EButtonType } from '@/components/Button';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import {
  ELoanUsage,
  EVehicleAssetType,
  selectIsVehicleKnown,
  selectLoanUsage,
  selectPurchasePrice,
  selectVehicleAssetType,
  selectVehicleVariant,
} from '@/context/borrower';
import {
  EDisplays,
  EModals,
  selectHideVehicleScreen,
  selectIsAssetKnownViaParams,
  setDisplay,
  setModal,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { Balloon } from '@/UserInput/Balloon';
import { Deposit } from '@/UserInput/Deposit';
import { LoanTerm } from '@/UserInput/LoanTerm';
import { LoanUsage } from '@/UserInput/LoanUsage';
import { PurchasePrice } from '@/UserInput/PurchasePrice';
import { TradeIn } from '@/UserInput/TradeIn';
import { VehicleKnown } from '@/UserInput/VehicleKnown';

export const testId = 'Loan';

export function Loan(): JSX.Element {
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState(false);

  // ***** CONSTANTS *****
  const baseId = 'screen-loanDetails';

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const purchasePrice = useAppSelector(selectPurchasePrice);
  const isVehicleKnown = useAppSelector(selectIsVehicleKnown);
  const loanUsage = useAppSelector(selectLoanUsage);
  const hideVehicleScreen = useAppSelector(selectHideVehicleScreen);
  const variant = useAppSelector(selectVehicleVariant);
  const vehicleAssetType = useAppSelector(selectVehicleAssetType);
  const isKnownViaParams = useAppSelector(selectIsAssetKnownViaParams);

  // ***** Event Handlers *****
  function handleNextClick(): void {
    setShowErrors(true);
    if (!isComplete()) return;
    createOrUpdateBorrower();
    const nextScreen = getNextScreen();
    dispatch(setDisplay(nextScreen));
  }

  function handleBackClick(): void {
    dispatch(setModal(EModals.WELCOME));
  }

  // ***** Helpers ******
  function isComplete(): boolean {
    if (purchasePrice === undefined) return false;
    if (purchasePrice === 0) return false;
    if (purchasePrice === null) return false;

    return true;
  }

  function getNextScreen(): EDisplays {
    if (!isVehicleKnown) {
      if (loanUsage === ELoanUsage.BUSINESS) {
        return EDisplays.BUSINESS;
      }
      return EDisplays.BASIC;
    }

    if (hideVehicleScreen && variant) {
      // Assumption that if hideVehicleScreen is true, then hideSellerScreen is truz
      return EDisplays.BASIC;
    }

    if (loanUsage === ELoanUsage.BUSINESS) {
      if (vehicleAssetType === EVehicleAssetType.CAR_VAN_OR_UTE) {
        return EDisplays.VEHICLE;
      }
      return EDisplays.MANUAL_VEHICLE;
    }

    return EDisplays.VEHICLE;
  }

  return (
    <div data-testid={testId} id='screen-loanDetails-screen'>
      <Header />
      <ProgressBar />
      <div className='flex '>
        <Button
          onClick={handleBackClick}
          type={EButtonType.SECONDARY_OUTLINE}
          square
        >
          Back
        </Button>
        <Button onClick={handleNextClick} square>
          Next
        </Button>
      </div>
      <div className='px-4'>
        <DisplayHeading
          title='Loan Details'
          infoText='Choose your loan structure from the options below'
        />
        <LoanUsage showErrors={false} baseId={baseId} />
        {!(isKnownViaParams && hideVehicleScreen) && (
          <VehicleKnown showErrors={false} baseId={baseId} />
        )}
        <div className='flex w-full space-x-5'>
          <PurchasePrice showErrors={showErrors} baseId={baseId} />
          <TradeIn showErrors={false} baseId={baseId} />
        </div>
        <div className='flex w-full space-x-5'>
          <Deposit showErrors={false} baseId={baseId} />
          <Balloon showErrors={false} baseId={baseId} />
        </div>
        <LoanTerm showErrors={false} baseId={baseId} />
      </div>
    </div>
  );
}
