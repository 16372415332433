import { Input } from '@/components/Input';
import { selectAnnualRevenue, setAnnualRevenue } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'AnnualRevenue';

export function AnnualRevenue({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const annualRevenue = useAppSelector(selectAnnualRevenue);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    // MAXIMUM
    const MAXIMUM = 999999999;
    const intValue = getNumber(value, MAXIMUM);
    if (intValue === annualRevenue) return;
    dispatch(setAnnualRevenue(intValue));
    if (intValue !== 0) return;
    dispatch(setAnnualRevenue(undefined));
  }

  // ***** Helpers *****
  function determineShowErrors(): boolean {
    if (!showErrors) return false;
    if (annualRevenue === undefined) return true;
    if (annualRevenue === null) return true;
    if (annualRevenue.toString()?.length !== 7) return true;
    return false;
  }
  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='What is the annual revenue of your business?'
        placeholder='$20000'
        showErrors={determineShowErrors()}
        onChange={handleChange}
        id={`${baseId}-input-annualRevenue`}
        value={annualRevenue?.toLocaleString() ?? ''}
        prefix='$'
      />
    </div>
  );
}
