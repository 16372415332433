import { Input } from '@/components/Input';
import { selectEmail, setEmail } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'Email';

export function Email({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectEmail);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    if (typeof value === 'boolean') return;
    if (value === currentState) return;
    if (value?.length > 50) return;
    dispatch(setEmail(value));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Email'
        placeholder='bruce@wayne.com'
        showErrors={showErrors && !currentState}
        onChange={handleChange}
        id={`${baseId}-input-email`}
        value={currentState?.toLocaleString() ?? ''}
      />
    </div>
  );
}
