export enum EButtonType {
  PRIMARY = 'PRIMARY',
  PRIMARY_INVERTED = 'PRIMARY_INVERTED',
  SUCCESS = 'SUCCESS',
  DISABLED = 'DISABLED',
  OUTLINE = 'OUTLINE',
  SECONDARY = 'SECONDARY',
  SECONDARY_OUTLINE = 'SECONDARY_OUTLINE',
}

export interface ICheckBox {
  onClick: () => void;
  checked: boolean;
}

export function CheckBox({ onClick, checked }: ICheckBox): JSX.Element {
  return (
    <div className='flex items-center'>
      <input
        id='default-checkbox'
        type='checkbox'
        value=''
        checked={checked}
        onChange={onClick}
        className='w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
      />
    </div>
  );
}
