import { getProgressPercent } from '@/utils/getProgressPercent';

export function ProgressBar(): JSX.Element {
  const percent = getProgressPercent();

  return (
    <div className={`w-full  bg-gray-200 dark:bg-gray-200`}>
      <div
        className={` bg-primary h-2
          `}
        style={{ width: `${percent}%` }}
      ></div>
    </div>
  );
}
