import { Input } from '@/components/Input';
import { selectMobile, setMobile } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'MobileNumber';

export function MobileNumber({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectMobile);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    if (typeof value === 'boolean') return;
    if (value === currentState) return;
    if (value === '') {
      dispatch(setMobile(value));
      return;
    }
    if (value?.length > 10) return;
    const lastDigit = value[value?.length - 1];
    if (isNaN(Number(lastDigit))) {
      return;
    }
    dispatch(setMobile(value));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Mobile Number'
        placeholder='04...'
        showErrors={showErrors}
        onChange={handleChange}
        id={`${baseId}-input-mobileNumber`}
        value={currentState?.toLocaleString() ?? ''}
      />
    </div>
  );
}
