import { SummarySection } from '@/components/SummarySection';
import {
  selectBirthdate,
  selectGender,
  selectLicenseExpiry,
  selectLicenseNumber,
  selectLicenseState,
  selectResidencyStatus,
} from '@/context/borrower';
import { EModals, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { formatDatetoString } from '@/utils/formatDatetoString';
import { getStringFromState } from '@/utils/getStringFromState';

export const testId = 'PersonalInformation';

export interface IProps {
  showErrors: boolean;
  baseId: string;
}

export function PersonalInformation({
  showErrors,
  baseId,
}: IProps): JSX.Element {
  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const gender = useAppSelector(selectGender);
  const birthdate = useAppSelector(selectBirthdate);
  const licenseNumber = useAppSelector(selectLicenseNumber);
  const licenseState = useAppSelector(selectLicenseState);
  const licenseExpiry = useAppSelector(selectLicenseExpiry);
  const residencyStatus = useAppSelector(selectResidencyStatus);

  // ***** Event Handlers *****
  function handleClick(): void {
    dispatch(setModal(EModals.PERSONAL_INFORMATION));
  }

  // ***** Helpers *****
  function isComplete(): boolean {
    if (gender === null || gender === undefined) return false;
    if (birthdate === null || birthdate === undefined) return false;
    if (licenseNumber === null || licenseNumber === undefined) return false;
    if (licenseState === null || licenseState === undefined) return false;
    if (licenseExpiry === null || licenseExpiry === undefined) return false;
    if (residencyStatus === null || residencyStatus === undefined) return false;

    return true;
  }

  function fullText(): string {
    return `I am ${getStringFromState(
      gender
    )} and my birthdate is at ${formatDatetoString(
      birthdate
    )}. I am a ${getStringFromState(residencyStatus).replace(
      '_',
      ' '
    )} and my license number is ${getStringFromState(
      licenseNumber
    )}. My license is valid in ${getStringFromState(
      licenseState
    )} and expires on the ${formatDatetoString(licenseExpiry)}.`;
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <SummarySection
        onClick={handleClick}
        id={`${baseId}-button-personalInformationEdit`}
        text={fullText()}
        heading='Personal Information'
        showErrors={showErrors && !isComplete()}
      />
    </div>
  );
}
